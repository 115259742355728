import useEffectOnce from "../../common/hooks/use-effect-once";
import ConsentManager from "../consent-manager";

const UseInitCMP = () => {
    const CM = new ConsentManager({
        cdid: process.env.REACT_APP_CMP_ID!,
    })

    useEffectOnce(() => CM.initCMP())
}

export default UseInitCMP
