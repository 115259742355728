import ApiService from "../../../axios/api.service";
import NotificationService from "../../common/services/notification.service";
import {UserBasicInfoPutRequest} from "../models/interfaces/user-basic-info-put-request.interface";
import {UserAdditionalInfoPutRequest} from "../models/interfaces/user-additional-info-put-request.interface";

class UserApiService extends ApiService {

    public async changeFanOnBoarding(): Promise<void> {
        return await this.put<void>(`users/fan-on-boarding`, {})
            .catch((error) => {
                NotificationService.error('Failed to end fan onboarding.');
                throw error;
            });
    }

    public async changeCcOnBoarding(): Promise<void> {
        return await this.put<void>(`users/cc-on-boarding`, {}, {
            errorMessage: 'Failed to end content creator onboarding.'
        })
            .catch((error) => {
                throw error;
            });
    }

    public async updateRegistrationStep(step: number): Promise<void> {
        return await this.put<void>(`users/update-registration-step`, {step}, {
            errorMessage: 'Failed to update registration step.'
        })
            .catch((error) => {
                throw error;
            });
    }

    public async updateBasicUserInfo(data: UserBasicInfoPutRequest): Promise<void> {
        return await this.put<void>(`users/complete-basic-info`, data, {
            errorMessage: 'Failed to complete basic user info.'
        })
            .catch((error) => {
                throw error;
            });
    }

    public async updateAdditionalUserInfo(data: UserAdditionalInfoPutRequest): Promise<void> {
        return await this.put<void>(`users/next-complete-basic-info`, data, {
            errorMessage: 'Failed to complete additional user info.'
        })
            .catch((error) => {
                throw error;
            });
    }

    public async getFreeCoinsForFirstDownloadApp(coins: number): Promise<void> {
        return await this.post<void>(`users/add-coins-for-download-app`, {}, {
            errorMessage: 'Failed to add free yCredits for your first top-up.',
            successMessage: `You have successfully received ${coins} yCredits!`,
        })
            .catch((error) => {
                throw error;
            });
    }
}

export default new UserApiService();
