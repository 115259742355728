import {StreamEmoji, useMessageContext} from "stream-chat-react";
import {useDragToScroll} from "../../common/hooks/use-drag-to-scroll";
import {BaseSyntheticEvent, useRef} from "react";
import customReactionOptions from "./custom-reaction-options";

interface CustomReactionsMessageProps{
    onSelect?: () => void;
}

const CustomReactionsMessage = (props: CustomReactionsMessageProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const {message, handleReaction, reactionDetailsSort, sortReactions} = useMessageContext();

    const {...dragScrollHandlers} = useDragToScroll(containerRef, {
        orientation: "x",
        hideScrollbar: true,
        enabled: true,
    });

    const handleReactionClick = async (reaction: string, event: BaseSyntheticEvent) => {
        await handleReaction(reaction, event);
        props.onSelect && props.onSelect();
    };

    const isReactionAdded = (reactionType: string): boolean => {
        return (
            message.own_reactions?.some((reaction) => reaction.type === reactionType) ?? false
        );
    };

    return (
        <div
            {...dragScrollHandlers}
            ref={containerRef}
            className='flex items-center justify-between p-3 h-[56px] min-h-[56px] [&>div]:cursor-pointer max-w-full w-full overflow-x-auto'>
            {customReactionOptions.map((emoji, index) => {
                const reactionAdded = isReactionAdded(emoji.type);

                return (
                    <div
                        onClick={(e) => handleReactionClick(emoji.type, e)}
                        key={index}
                        className={`rounded-full hover:bg-gray-light [&>div]:p-3 p-2 ${
                            reactionAdded ? "bg-gray-light" : ""
                        }`}
                    >
                        {emoji.Component ? (
                            <div className='custom-font-emoji text-2xl max-h-6 max-w-6 flex items-center justify-center'>
                                <emoji.Component/>
                            </div>
                        ) : (
                            <StreamEmoji type={emoji.type as any}/>
                        )}
                    </div>
                );
            })}
        </div>
    )
}

export default CustomReactionsMessage
