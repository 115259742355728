import { useMessageInputContext } from "stream-chat-react";
import { ReactComponent as SendIcon} from "@assets/icons/send.svg";

interface ButtonProps {
    sendMessage: () => void;
}


const CustomSendButton = (props: ButtonProps) => {
    return(
            <button
                onClick={props.sendMessage}
                onTouchEnd={props.sendMessage}
                className=""
            >
                <div className="w-[40px] h-[40px] bg-primary text-white rounded-2xl flex justify-center">
                    <SendIcon className="self-center w-[17.28px] h-[16px] text-white"/>
                </div>
            </button>
    )
}
export default CustomSendButton;