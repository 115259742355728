import GoogleTagManager from "../../google-analytics/google-tag-manager";
import { AnalyticsEventType } from "../models/enums/event-type-enum";
import { AddPaymentInfoEvent } from "../models/interfaces/events/add-payment-info-event.interface";
import { BeginCheckoutEvent } from "../models/interfaces/events/begin-checkout-event.interface";
import { PurchaseEvent } from "../models/interfaces/events/purchase-event.interface";
import { RegisterEvent } from "../models/interfaces/events/register-event.interface";
import { ViewItemEvent } from "../models/interfaces/events/view-item-event.interface";
import { ViewItemListEvent } from "../models/interfaces/events/view-item-list-event.interface";
import { ViewPromotionEvent } from "../models/interfaces/events/view-promotion-event.interface";
import { FormSentEvent } from "../models/interfaces/events/form-sent-event.interface"; // Newsletter
import { ViewCreatorEvent } from "../models/interfaces/events/view-creator-event.interface";
import { UserEvent } from "../models/interfaces/events/user-event.interface";

class AnalyticsService {
    public sendRegisterEvent(data: RegisterEvent) {
        GoogleTagManager.sendToGTM(AnalyticsEventType.REGISTER, data);
    }

    public sendViewItemEvent(data: ViewItemEvent) {
        GoogleTagManager.sendToGTM(AnalyticsEventType.VIEW_ITEM, data);
    }

    public sendViewItemListEvent(data: ViewItemListEvent) {
        GoogleTagManager.sendToGTM(AnalyticsEventType.VIEW_ITEM_LIST, data);
    }

    public sendViewPromotionEvent(data: ViewPromotionEvent) {
        GoogleTagManager.sendToGTM(AnalyticsEventType.VIEW_PROMOTION, data);
    }

    public sendSelectedPromotionEvent(data: ViewPromotionEvent) {
        GoogleTagManager.sendToGTM(AnalyticsEventType.SELECT_PROMOTION, data);
    }

    public sendBeginCheckoutEvent(data: BeginCheckoutEvent) {
        GoogleTagManager.sendToGTM(AnalyticsEventType.BEGIN_CHECKOUT, data);
    }

    public sendAddPaymentInfoEvent(data: AddPaymentInfoEvent) {
        GoogleTagManager.sendToGTM(AnalyticsEventType.ADD_PAYMENT_INFO, data);
    }

    public sendPurchaseEvent(data: PurchaseEvent) {
        GoogleTagManager.sendToGTM(AnalyticsEventType.PURCHASE, data);
    }

    // public sendNewsletterSentEvent(data: FormSentEvent) {
    //     GoogleTagManager.sendToGTM(AnalyticsEventType.NEWSLETTER_SENT, data);
    // }

    public sendViewCreatorEvent(data: ViewCreatorEvent) {
        GoogleTagManager.sendToGTM(AnalyticsEventType.VIEW_CREATOR, data);
    }

    public sendUserData(data: UserEvent) {
        GoogleTagManager.sendToGTM(null, data);
    }
}

export default new AnalyticsService();
