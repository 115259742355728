import {ReactComponent as MobilePushIcon} from "@assets/icons/mobile-push.svg";
import {ReactComponent as CrossIcon} from "@assets/icons/cross.svg";
import AppButton from "../../common/components/app-button";
import {usePwaInstallFlow} from "../../common/hooks/use-pwa-install-flow";
import {useEffect, useState} from "react";
import Coin from "./ui/coin";
import useAuth from "../../auth/hooks/use-auth";
import {useUserApi} from "../hooks/use-user-api";

const PromptInstallPwa = () => {
    const [showPrompt, setShowPrompt] = useState(true);
    const {installPWA, isInstalled} = usePwaInstallFlow();
    const {updateAuthenticationField, currentUser} = useAuth();
    const {getFreeCoinsForFirstDownloadApp} = useUserApi();
    const hasReceivedFreeCredits = currentUser?.gotFreeCoinsMobileApp

    const handleClosePrompt = () => setShowPrompt(false);

    const handleInstallPwa = async () => {
        try {
            await installPWA();
            // await getFreeCoinsForFirstDownloadApp(20);
            const loggedUser = {...currentUser!}
            loggedUser.gotFreeCoinsMobileApp = true;
            updateAuthenticationField("user", loggedUser)
        } catch (error: any) {
            throw error;
        }
    }

    useEffect(() => {
    }, [isInstalled]);

    if (isInstalled || !showPrompt) return null;

    return (
        <div className='absolute bottom-[15px] left-1/2 -translate-x-1/2 w-full px-5 overflow-clip py-1'>
            <div
                className='max-w-[800px] w-full bg-gray-light flex flex-wrap justify-between gap-3 flex-grow rounded-2xl
                p-3 shadow-md fade-slide-up opacity-0'>
                <header className='flex gap-2 items-center'>
                    <MobilePushIcon className='min-w-5 w-5 h-5 text-black inline-block'/>
                    <div className='flex flex-col justify-center'>
                        <h3 className='font-semibold text-base leading-5'>
                            Get better experience
                        </h3>
                        <span className='inline-block text-gray text-sm leading-5'>
                            Download the app now for a smoother experience!
                        </span>
                        {/*{hasReceivedFreeCredits ? (*/}
                        {/*    <span className='inline-block text-gray text-sm leading-5'>*/}
                        {/*        You’ve already used your first-time bonus. Enjoy the app!*/}
                        {/*    </span>*/}
                        {/*) : (*/}
                        {/*    <div className='max-h-[20px]'>*/}
                        {/*        <span className='inline-block text-gray text-sm leading-5'>Get extra 20</span>*/}
                        {/*        <span className='inline-block text-gray text-[0.95rem] leading-5 relative top-[3px] mx-[2px]'>*/}
                        {/*            <Coin className='!w-4 !h-4'/>*/}
                        {/*        </span>*/}
                        {/*        <span className='inline-block text-gray text-sm leading-5'>for first top-up</span>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>
                </header>
                <div className='flex justify-end items-center space-x-3 w-full sm:w-fit'>
                    <AppButton
                        onClick={handleInstallPwa}
                        label='Download mobile app'
                        className='w-full md:w-fit bg-primary-pastel text-primary !rounded-2xl font-semibold text-sm py-[9px] transition
                     hover:text-white hover:bg-primary focus:text-white focus:bg-primary'/>
                    <AppButton
                        onClick={handleClosePrompt}
                        label=''
                        className='!p-[7px] bg-white !rounded-full w-8 h-8 text-special-gray hover:text-primary focus:text-primary transition'>
                        <CrossIcon className='w-2 h-2 min-w-2'/>
                    </AppButton>
                </div>
            </div>
        </div>
    )
}

export default PromptInstallPwa
