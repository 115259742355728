import React, {createContext, ReactNode, useCallback, useState} from "react";
import {ModalContextType} from "../models/interfaces/modal-context-type.interface";
import {ModalIds} from "../models/enums/modal-ids.enum";

export const ModalContext = createContext<ModalContextType | null>(null);

export const ModalProvider = ({children}: { children: ReactNode }) => {
    const [openModals, setOpenModals] = useState<{ [key: string]: boolean }>({});
    const [modalData, setModalData] = useState<{ [key: string]: any }>({});
    const [modalCallbacks, setModalCallbacks] = useState<{ [key: string]: () => void }>({});

    const openModal = useCallback((id: ModalIds, data?: any) => {
        setOpenModals((prev) => ({...prev, [id]: true}));
        if (data !== undefined) {
            setModalData((prev) => ({...prev, [id]: data}));
        }
    }, []);

    const closeModal = useCallback((id: ModalIds) => {
        if (modalCallbacks[id]) modalCallbacks[id]()
        setOpenModals((prev) => ({...prev, [id]: false}));
        setModalData((prev) => ({...prev, [id]: null}));

        setModalCallbacks((prev) => {
            const newCallbacks = {...prev};
            delete newCallbacks[id];
            return newCallbacks;
        });
    }, [modalCallbacks]);

    const isOpenModal = useCallback((id: ModalIds) => {
        return openModals[id];
    }, [openModals]);

    const getModalData = useCallback((id: ModalIds) => {
        return modalData[id] ?? null;
    }, [modalData]);

    const registerModals = useCallback((ids: ModalIds[]) => {
        setOpenModals((prev) => {
            const modals = {...prev};
            ids.forEach((id) => {
                if (!(id in modals)) {
                    modals[id] = false;
                }
            });
            return modals;
        });
    }, []);

    const setCloseCallback = useCallback((id: ModalIds, callback: () => void) => {
        setModalCallbacks((prev) => ({...prev, [id]: callback}))
    }, [])

    return (
        <ModalContext.Provider
            value={{isOpenModal, openModal, closeModal, getModalData, registerModals, setCloseCallback}}>
            {children}
        </ModalContext.Provider>
    );
};
