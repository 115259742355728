import React, {cloneElement, useMemo, useRef, useState} from "react";
import useClickOutside from "../hooks/use-click-outside";
import ReactDOM from "react-dom";
import {useDynamicPositionFixed} from "../hooks/use-dynamic-position-fixed";

interface PopoverProps {
    trigger: React.ReactElement;
    children: React.ReactNode;
    preferredPositionY?: "top" | "bottom";
    preferredPositionX?: "left" | "right";
    popoverClassName?: string;
}

const Popover = (props: PopoverProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const popoverRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLElement>(null);

    const {
        trigger,
        children,
        preferredPositionY = "bottom",
        preferredPositionX = "right",
        popoverClassName,
    } = props;

    const hidePopover = () => setIsVisible(false);
    const togglePopover = () => setIsVisible((prev) => !prev);

    const position = useDynamicPositionFixed(isVisible, triggerRef, popoverRef, {
        preferredPositionY,
        preferredPositionX,
        lockToPreferred: true,
        offset: 8,
        shift: 40
    })

    const positionLeft = useMemo(() => {
        if (preferredPositionX === "left") {
            return position.left - (triggerRef.current?.offsetWidth ?? 0)
        } else {
            return position.left + (triggerRef.current?.offsetWidth ?? 0)
        }
    }, [position])

    useClickOutside(true, hidePopover, [popoverRef, triggerRef]);

    const popoverContent = () => {
        return ReactDOM.createPortal(
            <div
                ref={popoverRef}
                className={`absolute z-10 p-4 flex flex-col gap-4 text-sm bg-white text-black rounded-2xl shadow-popover ${popoverClassName}`}
                style={{top: position.top, left: positionLeft}}
            >
                {children}
            </div>,
            document.body
        );
    }

    const triggerWithRef = cloneElement(trigger, {
        ref: triggerRef,
        onClick: togglePopover,
        isActive: isVisible,
    });

    return (
        <>
            {triggerWithRef}
            {isVisible && popoverContent()}
        </>
    );
};

export default Popover;
