import {GroupStyle, isDateSeparatorMessage, Message, MessageRenderer, ChannelHeader} from "stream-chat-react";
import DateSeparator from "./ui/date-seperator";
import {Fragment} from "react";
import UnreadMessagesSeparator from "./ui/unread-messages-separator";
import CustomMessage from "./custom-message";

const customRenderMessages: MessageRenderer = (options) => {
    const {
        messages,
        customClasses,
        channelUnreadUiState,
        messageGroupStyles,
        lastReceivedMessageId: lastReceivedId,
        sharedMessageProps: messageProps,
        readData,
    } = options;

    const renderedMessages = [];
    let firstMessage;
    for (let index = 0; index < messages.length; index++) {
        const message = messages[index];
        if (isDateSeparatorMessage(message)) {
            renderedMessages.push(
                <li key={`${message.date.toISOString()}-i`}>
                    <DateSeparator date={message.date}/>
                </li>,
            );
        } else if (message.customType === "channel.intro") {
            renderedMessages.push(
                <li key='intro'>
                    INTRO
                    <ChannelHeader/>
                    {/*<HeaderComponent />*/}
                </li>,
            );
        } else if (message.type === 'system') {
            renderedMessages.push(
                <li data-message-id={message.id} key={message.id || (message.created_at as string)}>
                    SYSTEM
                    {/*<MessageSystem message={message} />*/}
                </li>,
            );
        } else {
            if (!firstMessage) {
                firstMessage = message;
            }
            const groupStyles: GroupStyle = messageGroupStyles[message.id] || '';
            const messageClass = customClasses?.message || `str-chat__li str-chat__li--${groupStyles}`;

            const createdAtTimestamp = message.created_at && new Date(message.created_at).getTime();
            const lastReadTimestamp = channelUnreadUiState?.last_read.getTime();
            const isFirstMessage = firstMessage?.id && firstMessage.id === message.id;
            const isNewestMessage = index === messages.length - 1;

            const isLastReadMessage =
                channelUnreadUiState?.last_read_message_id === message.id ||
                (!channelUnreadUiState?.unread_messages && createdAtTimestamp === lastReadTimestamp);

            const isFirstUnreadMessage =
                channelUnreadUiState?.first_unread_message_id === message.id ||
                (!!channelUnreadUiState?.unread_messages &&
                    !!createdAtTimestamp &&
                    !!lastReadTimestamp &&
                    createdAtTimestamp > lastReadTimestamp &&
                    isFirstMessage);

            const showUnreadSeparatorAbove =
                !channelUnreadUiState?.last_read_message_id && isFirstUnreadMessage;

            const showUnreadSeparatorBelow =
                isLastReadMessage &&
                !isNewestMessage &&
                (channelUnreadUiState?.first_unread_message_id || !!channelUnreadUiState?.unread_messages);

            renderedMessages.push(
                <Fragment key={message.id || (message.created_at as string)}>
                    {showUnreadSeparatorAbove && UnreadMessagesSeparator && (
                        <li className='str-chat__li str-chat__unread-messages-separator-wrapper'>
                            <UnreadMessagesSeparator unreadCount={channelUnreadUiState?.unread_messages}/>
                        </li>
                    )}
                    <li className={messageClass} data-message-id={message.id} data-testid={messageClass}>
                        <Message
                            groupStyles={[groupStyles]}
                            lastReceivedId={lastReceivedId}
                            message={message}
                            readBy={readData[message.id] || []}
                            {...messageProps}
                            Message={CustomMessage}
                        />
                    </li>
                    {showUnreadSeparatorBelow && UnreadMessagesSeparator && (
                        <li className='str-chat__li str-chat__unread-messages-separator-wrapper'>
                            <UnreadMessagesSeparator unreadCount={channelUnreadUiState?.unread_messages}/>
                        </li>
                    )}
                </Fragment>,
            );
        }
    }

    return renderedMessages;
};

export default customRenderMessages
