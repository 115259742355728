import React, {createContext, useState} from "react";
import {PaymentMethodType} from "../../core/models/enums/payment-method-type.enum";

interface CardDetails {
    cardNumber: string;
    expiryDate: string;
    cvv: string;
    cardHolderName: string;
}

export interface PaymentMethodContextType {
    selectedMethod: PaymentMethodType | null;
    cardDetails: CardDetails | null;
    setPaymentMethod: (method: PaymentMethodType | null) => void;
    updateCardDetails: (details: CardDetails) => void;
    clearSession: () => void;
}

export const PaymentMethodContext = createContext<PaymentMethodContextType | undefined>(undefined);

export const PaymentMethodProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [selectedMethod, setSelectedMethod] = useState<PaymentMethodType | null>(null);
    const [cardDetails, setCardDetails] = useState<CardDetails | null>(null);

    const setPaymentMethod = (method: PaymentMethodType | null) => {
        setSelectedMethod(method);
    };

    const updateCardDetails = (details: CardDetails) => {
        setCardDetails(details);
    };

    const clearSession = () => {
        setCardDetails(null);
        setSelectedMethod(null);
    }

    return (
        <PaymentMethodContext.Provider
            value={{
                selectedMethod,
                cardDetails,
                setPaymentMethod,
                updateCardDetails,
                clearSession,
            }}
        >
            {children}
        </PaymentMethodContext.Provider>
    );
};
