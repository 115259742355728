import {ReactComponent as WarningIcon} from "@assets/icons/warning.svg";
import {classNames} from "../../../../utils/class-names";

interface MessageErrorIconProps{
    className?: string,
}

const MessageErrorIcon = ({className}: MessageErrorIconProps) => {
    return (
        <div className={classNames(
            'bg-red-500 text-white content-center w-4 h-4 min-w-4 p-1 rounded-full',
            className
        )}>
            <WarningIcon className="w-full h-full" />
        </div>
    )
}

export default MessageErrorIcon
