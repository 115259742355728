import React from 'react';
import ForgotPasswordForm from "../../components/forgot-password-form";
import useAuth from "../../hooks/use-auth";
import NotificationService from "../../../common/services/notification.service";

interface ForgotPasswordViewProps {
    onSuccess?: () => void
}

const ForgotPasswordView: React.FC<ForgotPasswordViewProps> = (props: ForgotPasswordViewProps) => {
    const {onSuccess} = props;
    const {getTokenToResetPassword} = useAuth();

    const submitSendTokenHandler = async (emailOrUsername: string): Promise<void> => {
        await getTokenToResetPassword(emailOrUsername)
            .then(() => {
                NotificationService.success(
                    "The code to reset the password sent to your email. Check your email and go through the instructions.",
                    "",
                    {duration: 10000}
                )
                onSuccess && onSuccess();
            })
    }

    return (
        <div className='max-h-full flex flex-col h-full md:h-fit'>
            <p className="mb-6 text-special-gray text-sm md:text-base leading-[1.05rem] md:leading-6 font-medium">
                Forgot your password? No worries! Our password recovery
                feature makes it simple to regain access to your account
                in just a few easy steps.
            </p>
            <ForgotPasswordForm onSubmit={submitSendTokenHandler}/>
        </div>
    );
};

export default ForgotPasswordView;
