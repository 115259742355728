import AppButton from "../../../common/components/app-button";
import {MouseEvent} from "react"
import {ReactComponent as MoreIcon} from "@assets/icons/more.svg";
import {ReactComponent as CrossIcon} from "@assets/icons/cross.svg";
import {UniversalClickHandler} from "../../../common/models/types/universal-click-handler.type";
import {classNames} from "../../../../utils/class-names";

interface ButtonMessageOptionsProps {
    onClick: UniversalClickHandler<void, MouseEvent<HTMLButtonElement>>;
    isVisibleOptions: boolean;
    isMyMessage: boolean;
}


const ButtonMessageOptions = ({isMyMessage,onClick, isVisibleOptions}: ButtonMessageOptionsProps) => {
    return (
        <div className={classNames(
            'w-full md:w-fit flex relative justify-end',
            !isMyMessage && "md:top-[8px]"
        )}>
            <AppButton onClick={onClick}
                       className='flex-grow bg-white h-10 w-10 min-w-10 max-w-10 rounded-2xl flex justify-center items-center border-none shadow-messages'>
                {isVisibleOptions ? (
                    <CrossIcon className='w-[8px] max-h-fit h-fit'/>
                ):(
                    <MoreIcon className='w-[3px] max-h-fit h-fit'/>
                )}
            </AppButton>
        </div>
    )
}

export default ButtonMessageOptions
