import {DateHelper} from "../../../utils/date-helper";

export class TimestampHelper {
    private static daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    /**
     * Returns a formatted timestamp based on the difference between the given date and the current time.
     *
     * Rules:
     * - Less than 60 seconds → "Just now"
     * - Less than 1 hour → "{count} min. ago"
     * - Less than 1 day → "{count} hr. ago"
     * - 1–2 days ago → "Yesterday, {HH:mm}"
     * - More than 2 days ago → Full date in the format "DD.MM.YYYY, HH:mm"
     *
     * @param date - The date to format - string | Date.
     * @returns A formatted timestamp string.
     *
     * Example:
     * ```typescript
     * const now = new Date();
     * const fiveMinutesAgo = new Date(now.getTime() - 5 * 60 * 1000);
     * console.log(TimestampHelper.getFormattedTimestamp(fiveMinutesAgo));
     * // Output: "5 min. ago"
     * ```
     */
    static getFormattedTimestamp(date?: string | Date): string {
        if (!date) return "";
        let parsedDate: Date;

        if (typeof date === 'string') {
            parsedDate = new Date(date);
            if (isNaN(parsedDate.getTime())) {
                throw new Error('Invalid date string provided');
            }
        } else {
            parsedDate = date;
        }

        const now = new Date();
        const seconds = Math.floor((now.getTime() - parsedDate.getTime()) / 1000);
        const daysDifference = this.calculateDaysDifference(now, parsedDate);

        const rules = [
            {condition: () => seconds < 60, format: "Just now"},
            {condition: () => seconds < 3600, format: "{count} min. ago", divisor: 60},
            {condition: () => seconds < 86400, format: "{count} h. ago", divisor: 3600},
            {condition: () => daysDifference === 1, format: "Yesterday, {HH:mm}"},
            {condition: () => daysDifference < 7, format: "{DayOfWeek}, {HH:mm}"},
            {condition: () => true, format: "{DD.MM.YYYY} at {HH:mm}"},
        ];

        for (const rule of rules) {
            if (rule.condition()) {
                const count = rule.divisor ? Math.floor(seconds / rule.divisor) : undefined;
                return this.applyPattern(rule.format, parsedDate, count);
            }
        }

        return "";
    }


    /**
     * Replaces placeholders in a pattern string with dynamic values such as `{count}` or `{HH:mm}`.
     *
     * @param pattern - The pattern string (e.g., "{count} min. ago").
     * @param date - The date used to extract dynamic time values.
     * @param count - The number of units (e.g., minutes, hours) to substitute into the pattern.
     * @returns A formatted string with placeholders replaced by actual values.
     *
     * Example:
     * ```typescript
     * const pattern = "{count} min. ago";
     * const date = new Date();
     * console.log(TimestampHelper.applyPattern(pattern, date, 5));
     * // Output: "5 min. ago"
     * ```
     */
    private static applyPattern(pattern: string, date: Date, count?: number): string {
        const dayOfWeek = this.daysOfWeek[date.getDay()];
        return pattern
            .replace("{count}", count?.toString() ?? "")
            .replace("{HH:mm}", DateHelper.formatDate(date, "HH:mm"))
            .replace("{DayOfWeek}", dayOfWeek)
            .replace("{DD.MM.YYYY}", DateHelper.formatDate(date, "DD.MM.YYYY"));
    }

    /**
     * Calculates the difference in days between two dates.
     */
    private static calculateDaysDifference(current: Date, past: Date): number {
        const currentMidnight = new Date(current.getFullYear(), current.getMonth(), current.getDate());
        const pastMidnight = new Date(past.getFullYear(), past.getMonth(), past.getDate());
        return Math.floor((currentMidnight.getTime() - pastMidnight.getTime()) / (1000 * 60 * 60 * 24));
    }
}
