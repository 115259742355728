import React, {useState} from "react";
import ReactPlayer, {ReactPlayerProps} from "react-player";
import {ReactComponent as PlayIcon} from "@assets/icons/play.svg";

const Media = (props: ReactPlayerProps): React.JSX.Element => {
    const [showNativeControls, setShowNativeControls] = useState(false);
    const [playing, setPlaying] = useState(false);

    const startFirstTimeHandler = () => {
        if (!showNativeControls) {
            setShowNativeControls(true);
            setPlaying(true);
        }
    };

    const handlePause = () => {
        setPlaying(false);
        setShowNativeControls(false);
    };

    return (
        <div className='str-chat__player-wrapper relative react-player'>
            <ReactPlayer
                {...props}
                controls={showNativeControls}
                playing={playing}
                // onPause={handlePause}
            />
            {!showNativeControls && (
                <div
                    onClick={startFirstTimeHandler}
                    className='cursor-pointer flex items-center justify-center absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white hover:text-primary focuse:text-primary rounded-full w-10 h-10'>
                    <PlayIcon className="h-[0.625rem] w-fit"/>
                </div>
            )}
        </div>
    )
}

export default Media
