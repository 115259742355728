import {DEFAULT_DOMAIN} from "../constants/default-domain";
import {DEFAULT_SCRIPT_NAME} from "../constants/default-script-name";
import {CustomEnvironmentParams} from "../models/interfaces/custom-environment-params.interface";
import {DEFAULT_DATA_CMP_PURPOSE} from "../constants/default-data-cmp-purpose";
import {DEFAULT_GOOGLE_VENDORS} from "../constants/default-data-cmp-vendor";
import {ConfigGTM} from "../models/interfaces/config-gtm.interface";

/**
 * Function to get the GTM script
 * @param customDomain - Custom domain for gtm
 * @param customScriptName - Custom script file name for gtm
 * @param environment - The parameters to use a custom environment
 * @param id - The id of the container
 * @param dataCmpVendor - A custom attribute (`data-cmp-vendor`) to specify the consent management vendor.
 *                        It is added as an attribute to the dynamically created script tag.
 * @param dataCmpPurpose - A custom attribute (`data-cmp-purpose`) specifying the purpose(s) for which the script is being used.
 *                         This can be used for compliance with regulations like GDPR. Defaults to `DEFAULT_DATA_CMP_PURPOSE`.
 *
 * @returns A string containing the JavaScript snippet to initialize GTM with the specified parameters.
 */
export const getGTMScript = (
    id: Pick<ConfigGTM, 'id'>['id'],
    environment?: CustomEnvironmentParams,
    customDomain: ConfigGTM['customDomain'] = DEFAULT_DOMAIN,
    customScriptName: ConfigGTM['customScriptName'] = DEFAULT_SCRIPT_NAME,
    dataCmpVendor: ConfigGTM['dataCmpVendor'] = DEFAULT_GOOGLE_VENDORS,
    dataCmpPurpose: ConfigGTM['dataCmpPurpose'] = DEFAULT_DATA_CMP_PURPOSE,
) => {
    let params = ``
    if (environment) {
        const { gtm_auth, gtm_preview } = environment
        params = `+"&gtm_auth=${gtm_auth}&gtm_preview=${gtm_preview}&gtm_cookies_win=x"`
    }
    return `
        (function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.type = "text/plain";
            j.setAttribute("data-cmp-src", "${customDomain}/${customScriptName}?id=" + i + dl + "${params}");
            j.classList.add("cmplazyload");
            if (${!!dataCmpVendor}) {
                j.setAttribute("data-cmp-vendor", "${dataCmpVendor}");
            }
            if (${!!dataCmpPurpose}) {
                j.setAttribute("data-cmp-purpose", "${dataCmpPurpose}");
            }
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${id}');
    `;
}
