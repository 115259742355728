import React, { createContext, useContext, useState } from "react";

interface MessageOptionsContextType {
    activeMessageId: string | null;
    setActiveMessageId: (id: string | null) => void;
}

export const MessageOptionsContext = createContext<MessageOptionsContextType | undefined>(undefined);

export const MessageOptionsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [activeMessageId, setActiveMessageId] = useState<string | null>(null);

    return (
        <MessageOptionsContext.Provider value={{ activeMessageId, setActiveMessageId }}>
            {children}
        </MessageOptionsContext.Provider>
    );
};

