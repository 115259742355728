import AppButton from "../../common/components/app-button";
import {ChevronLeftIcon} from "@heroicons/react/20/solid";
import React from "react";
import {useNavigate} from "react-router-dom";
import {classNames} from "../../../utils/class-names";

interface SettingsHeaderProps {
    title: string;
    className?: string;
}

const SettingsHeader = ({title, className}: SettingsHeaderProps) => {
    const navigate = useNavigate();

    return (
        <header className={classNames("flex items-center gap-3",
            className
        )}>
            <AppButton label=''
                       onClick={() => navigate(-1)}
                       className="bg-transparent w-[40px] h-[40px] !rounded-2xl !p-0 !m-0 text-special-gray
                           hover:text-primary focus:text-primary md:hidden inline-block"
            >
                <ChevronLeftIcon className='w-5 h-5 min-w-5'/>
            </AppButton>
            <h1 className='font-bold text-black text-[1.25rem] leading-6 md:text-[2rem] md:leading-[2.4rem]'>
                {title}
            </h1>
        </header>
    )
}

export default SettingsHeader
