const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
  enableAutoCheck?: boolean;
  intervalCheckingUpdates?: number
};

export function register(config?: Config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) return;

    window.addEventListener('load', () => {
      let swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);
        navigator.serviceWorker.ready.then(() => {
          console.log(
              'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://cra.link/PWA'
          );
        });
      } else {
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
      .register(swUrl, { type: 'module' })
      .then((registration) => {
        if (!!config?.enableAutoCheck) {
          setupUpdateCheckInterval(registration,config.intervalCheckingUpdates || 300000);
        }

        // Nasłuchiwanie zdarzenia `updatefound`
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;

          if (installingWorker) {
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  // Nowa wersja gotowa do użycia
                  console.log('New updateEntity available!');

                  // Powiadom użytkownika o aktualizacji (opcjonalnie)
                  if (config && config.onUpdate) {
                    config.onUpdate(registration);
                  }
                } else {
                  // Pierwsza instalacja Service Workera
                  console.log('Content is cached for offline use.');
                  if (config && config.onSuccess) {
                    config.onSuccess(registration);
                  }
                }
              }
            };
          }
        };
      })
      .catch((error) => {
        console.error('Error during service worker registration:', error);
      });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

function checkForUpdates() {
  if (navigator.serviceWorker && navigator.serviceWorker.controller) {
    navigator.serviceWorker.getRegistration().then((registration) => {
      if (registration) {
        registration.update().then(() => {
          console.log('Checked for updates');
        });
      }
    });
  }
}

function setupUpdateCheckInterval(registration: ServiceWorkerRegistration, intervalMs: number) {
  console.log(`Setting up update check interval: ${intervalMs} ms.`);

  let interval: NodeJS.Timer = setInterval(() => {
    const now = new Date();
    const formattedTime: string = now.toLocaleTimeString('pl-PL', { hour: '2-digit', minute: '2-digit' });
    console.log(`Checking for updates at ${formattedTime}...`);
    checkForUpdates();
  }, intervalMs);

  registration.addEventListener('statechange', () => {
    if (registration.active) {
      console.log(`Interval for update checks is running every ${intervalMs} ms.`);
    } else {
      console.log('Update check interval has been cleared because SW is no longer active.');
      clearInterval(interval);
    }
  });

  return () => {
    console.log('Clearing updateEntity interval manually.');
    clearInterval(interval);
  };
}
