interface MessageErrorTextProps {
    errorMessage?: string,
}

const MessageErrorText = (props: MessageErrorTextProps) => {
    const {errorMessage = "Message couldn't be sent"} = props;
    return (
        <span className='text-red-500 text-sm font-medium'>
            {errorMessage}
        </span>
    )
}

export default MessageErrorText
