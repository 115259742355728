import {useEffect, useState} from "react";

interface PositionOptions {
    preferredPositionY?: 'top' | 'bottom';
    preferredPositionX?: 'left' | 'right';
    offset?: number;
    shift?: number;
    lockToPreferred?: boolean;
}

export const useDynamicPositionFixed = (
    shouldUpdatePosition: boolean,
    triggerRef: React.RefObject<HTMLElement>,
    targetRef: React.RefObject<HTMLElement>,
    {
        preferredPositionY = 'bottom',
        preferredPositionX = 'right',
        offset = 8,
        shift = 10,
        lockToPreferred = false,
    }: PositionOptions = {}
) => {
    const [position, setPosition] = useState({ top: 0, left: 0 });

    const calculateFixedPosition = () => {
        if (!triggerRef.current || !targetRef.current) return;

        const triggerRect = triggerRef.current.getBoundingClientRect();
        const targetRect = targetRef.current.getBoundingClientRect();

        let top = 0;
        let left = 0;

        // Calculate vertical position
        if (preferredPositionY === 'top') {
            top = triggerRect.top - targetRect.height - offset;
            if (!lockToPreferred && top < 0) {
                top = triggerRect.bottom + offset;
            }
        } else {
            top = triggerRect.bottom + offset;
            if (!lockToPreferred && top + targetRect.height > window.innerHeight) {
                top = triggerRect.top - targetRect.height - offset;
            }
        }

        // Calculate horizontal position
        if (preferredPositionX === 'left') {
            left = triggerRect.left + shift;
            if (!lockToPreferred && left < 0) {
                left = triggerRect.right - targetRect.width - shift;
            }
        } else {
            left = triggerRect.right - targetRect.width - shift;
            if (!lockToPreferred && left + targetRect.width > window.innerWidth) {
                left = triggerRect.left + shift;
            }
        }

        setPosition({ top, left });
    };

    const adjustPosition = () => {
        calculateFixedPosition();
    };

    useEffect(() => {
        if (shouldUpdatePosition) {
            adjustPosition();

            window.addEventListener("resize", adjustPosition);

            return () => {
                window.removeEventListener("resize", adjustPosition);
            };
        }
    }, [shouldUpdatePosition, preferredPositionY, preferredPositionX, offset, shift]);

    return position;
};
