const installInstructions = {
    en: {
        ios: {
            notSafari: {
                header: "Installing the app on iOS is only available in Safari:",
                steps: [
                    "To install the app, open the page in Safari and follow the installation instructions."
                ]
            }
        },
        chrome: {
            desktop: {
                header: "Installation for Google Chrome (Desktop):",
                steps: [
                    "In the address bar, locate the app download icon.",
                    "Click the icon and confirm installation.",
                    "The app will be added to the desktop or Start menu."
                ]
            },
            mobile: {
                header: "Installation for Google Chrome (Mobile):",
                steps: [
                    "Open the menu (three dots) in the top right corner.",
                    "Select 'Add to Home Screen.'",
                    "Confirm the addition by clicking 'Install as an app.'"
                ]
            }
        },
        edge: {
            desktop: {
                header: "Installation for Microsoft Edge (Desktop):",
                steps: [
                    "In the address bar, locate the installation icon (window with a plus).",
                    "Click the icon and select 'Install as an app.'",
                    "The app will appear in the Start menu or on the desktop."
                ]
            },
            mobile: {
                header: "Installation for Microsoft Edge (Mobile):",
                steps: [
                    "Open the menu (three dots) in the bottom right corner.",
                    "Select 'Add to Home Screen.'",
                    "Confirm the addition by clicking 'Add.'"
                ]
            }
        },
        opera: {
            desktop: {
                header: "Installation for Opera (Desktop):",
                steps: [
                    "Locate the 'Install' icon in the address bar.",
                    "Click the icon and confirm installation.",
                    "The app will appear on the desktop or in the Start menu."
                ]
            },
            mobile: {
                header: "Installation for Opera (Mobile):",
                steps: [
                    "Open the menu (three dots) in the top right corner.",
                    "Select 'Add to Home Screen.'",
                    "Confirm by clicking 'Add.'"
                ]
            }
        },
        safari: {
            desktop: {
                header: "Installation for Safari (Desktop):",
                steps: [
                    "Click the 'Share' icon in the toolbar.",
                    "Select 'Add to Dock.'",
                    "The app icon will appear in the Dock."
                ]
            },
            mobile: {
                header: "Installation for Safari (Mobile):",
                steps: [
                    "Tap the 'Share' icon (square with an upward arrow) at the bottom of the screen.",
                    "Select 'Add to Home Screen.'",
                    "Confirm by clicking 'Add.'"
                ]
            }
        },
        brave: {
            desktop: {
                header: "Installation for Brave (Desktop):",
                steps: [
                    "In the address bar, locate the 'Install' icon.",
                    "Click the icon and confirm the installation.",
                    "The app will be added to the desktop and Start menu."
                ]
            },
            mobile: {
                header: "Installation for Brave (Mobile):",
                steps: [
                    "Open the menu (three dots) in the top right corner.",
                    "Select 'Add to Home Screen.'",
                    "Confirm by clicking 'Add' in the dialog box."
                ]
            }
        },
        default: {
            header: "Browser not supported:",
            steps: [
                "Try using a supported browser such as Chrome, Safari, Edge, Opera, or Brave."
            ]
        },
    },
    pl: {
        ios: {
            notSafari: {
                header: "Instalacja aplikacji na iOS jest dostępna wyłącznie w Safari:",
                steps: [
                    "Aby zainstalować aplikację, otwórz stronę w przeglądarce Safari i postępuj zgodnie z instrukcjami instalacji."
                ]
            }
        },
        chrome: {
            desktop: {
                header: "Instalacja dla Google Chrome (Desktop):",
                steps: [
                    "W pasku adresu znajduje się ikona pobrania aplikacji.",
                    "Kliknij ikonę i potwierdź instalację.",
                    "Aplikacja zostanie dodana na pulpit lub do menu Start."
                ]
            },
            mobile: {
                header: "Instalacja dla Google Chrome (Mobile):",
                steps: [
                    "W menu (trzy kropki) w prawym górnym rogu wybierz 'Dodaj do ekranu głównego.'",
                    "Potwierdź dodanie, klikając 'Zainstaluj jako aplikację.'"
                ]
            }
        },
        edge: {
            desktop: {
                header: "Instalacja dla Microsoft Edge (Desktop):",
                steps: [
                    "W pasku adresu znajduje się ikona instalacji (okno z plusem).",
                    "Kliknij ikonę i wybierz 'Zainstaluj jako aplikację.'",
                    "Aplikacja pojawi się w menu Start lub na pulpicie."
                ]
            },
            mobile: {
                header: "Instalacja dla Microsoft Edge (Mobile):",
                steps: [
                    "W menu (trzy kropki) w prawym dolnym rogu wybierz 'Dodaj do ekranu głównego.'",
                    "Potwierdź dodanie, klikając 'Dodaj.'"
                ]
            }
        },
        opera: {
            desktop: {
                header: "Instalacja dla Opera (Desktop):",
                steps: [
                    "W pasku adresu pojawia się ikona 'Zainstaluj.'",
                    "Kliknij ikonę i potwierdź instalację.",
                    "Aplikacja pojawi się na pulpicie lub w menu Start."
                ]
            },
            mobile: {
                header: "Instalacja dla Opera (Mobile):",
                steps: [
                    "W menu (trzy kropki) w prawym górnym rogu wybierz 'Dodaj do ekranu głównego.'",
                    "Potwierdź, klikając 'Dodaj.'"
                ]
            }
        },
        safari: {
            desktop: {
                header: "Instalacja dla Safari (Desktop):",
                steps: [
                    "Kliknij ikonę 'Udostępnij' w pasku narzędzi.",
                    "Wybierz 'Dodaj do Docka.'",
                    "Ikona aplikacji pojawi się w Docku."
                ]
            },
            mobile: {
                header: "Instalacja dla Safari (Mobile):",
                steps: [
                    "Wybierz ikonę 'Udostępnij' (kwadrat z strzałką w górę) na dole ekranu.",
                    "Wybierz 'Dodaj do ekranu początkowego.'",
                    "Potwierdź, klikając 'Dodaj.'"
                ]
            }
        },
        brave: {
            desktop: {
                header: "Instalacja dla Brave (Desktop):",
                steps: [
                    "W pasku adresu pojawia się ikona 'Zainstaluj.'",
                    "Kliknij ikonę i potwierdź dodanie aplikacji.",
                    "Aplikacja zostanie dodana na pulpit i do menu Start."
                ]
            },
            mobile: {
                header: "Instalacja dla Brave (Mobile):",
                steps: [
                    "W menu (trzy kropki) w prawym górnym rogu wybierz 'Dodaj do ekranu głównego.'",
                    "Potwierdź, klikając 'Dodaj' w oknie dialogowym."
                ]
            }
        },
        default: {
            header: "Twoja przeglądarka nie jest wspierana:",
            steps: [
                "Spróbuj użyć kompatybilnej przeglądarki, takiej jak Chrome, Safari, Edge, Opera lub Brave."
            ]
        },
    }
};

export default installInstructions;
