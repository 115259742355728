import MessagesBadge from "../components/badges/messages-badge";

export const badgeComponentMapper = (key: string) => {
    switch (key) {
        case "/app/messages":
            return <MessagesBadge/>
        default:
            return null;
    }
}
