import AppButton from "../../../common/components/app-button";
import React from "react";
import {UniversalClickHandler} from "../../../common/models/types/universal-click-handler.type";

interface SkipStepVerificationProps {
    onClick?: UniversalClickHandler
}

const SkipStepVerification = ({onClick}: SkipStepVerificationProps) => {
    return (
        <AppButton
            onClick={onClick}
            loaderColor='text-black'
            label='Skip'
            className='text-special-gray font-semibold text-xs leading-[0.9rem] underline
                                !rounded-3xl !py-3 h-fit border-transparent bg-transparent transition ml-auto
                                hover:border-primary-pastel'
        />
    )
}

export default SkipStepVerification
