import AppHeader from "../../../layout/app-header";
import React, {useRef} from "react";
import AppBackButton from "../../../layout/components/app-back-button";
import AppContentWrapper from "../../../layout/components/app-content-wrapper";
import ProductForm from "../components/product-form";
import CreatorToolsActions from "../components/creator-tools-actions";
import {FormDigitalProductProvider} from "../contexts/form-digital-product-context";
import PreviewDigitalProductDetailsView from "./preview-digital-product-details-view";
import {useFormDigitalProduct} from "../hooks/use-form-digital-product.hook";
import {classNames} from "../../../utils/class-names";
import { useNavigate } from "react-router-dom";

interface FormDigitalProductViewProps {
    mode: "edit" | "create";
}

const FormDigitalProductView = (props: FormDigitalProductViewProps) => {
    const wrapperRef = useRef(null);
    const {mode} = props
    const {previewModeOn} = useFormDigitalProduct();

    return (
        <>
            {!previewModeOn && (
                <AppHeader customContent={true}>
                    <div
                        className='flex items-center font-bold text-2xl pt-10 lg:text-[40px] text-primary-darken whitespace-nowrap'>
                        <div className='mr-3 flex'><AppBackButton/></div>
                        {mode === "create" ? "Add product" : "Edit product"}
                    </div>
                </AppHeader>
            )}

            <AppContentWrapper
                ref={wrapperRef}
                className={classNames(
                    previewModeOn ? "!p-0" : 'p-4 bg-white rounded-t-[1.5rem] sm:pt-9 h-full space-y-8'
                )}>
                {previewModeOn ? (
                    <PreviewDigitalProductDetailsView/>
                ) : (
                    <ProductForm/>
                )}
                <CreatorToolsActions parentRef={wrapperRef}/>
            </AppContentWrapper>
        </>
    )
}

const FormDigitalProductViewWithProvider = (props: FormDigitalProductViewProps) => {
    const navigate = useNavigate();
    return (
        <FormDigitalProductProvider mode={props.mode} callbackOnSuccessSubmit={() => {
            navigate("../digital-products");
        }}>
            <FormDigitalProductView mode={props.mode}/>
        </FormDigitalProductProvider>
    )
}

export default FormDigitalProductViewWithProvider
