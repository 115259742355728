import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage, deleteToken} from "firebase/messaging";
import { firebaseConfig } from "./firebaseConfig";
import { Firebase } from "./firebase.enum";
import { environment } from "../environments/environment";

export const firebaseApp = initializeApp(firebaseConfig, Firebase.NAME);

export const messagingFirebaseApp = getMessaging(firebaseApp);
export const getFirebaseToken = getToken;
export const deleteFirebaseToken = deleteToken;

onMessage(messagingFirebaseApp, (payload) => {
    console.log('Message received in foreground: ', payload);
    
    const urlToOpen = payload.data?.url || environment.frontendUrl;
    if (Notification.permission === "granted") {
        const notificationTitle = payload.data?.title || "New Notification";
        const notificationOptions = {
            body: payload.data?.body || "You have a new message",
            icon: payload.data?.icon || "/assets/icons/icon-128x128.png",
            image: payload.data?.imageUrl,
            data: {
                url: urlToOpen
            }
        };

        // Show the notification
        new Notification(notificationTitle, notificationOptions);
    } else {
        console.warn("Notifications are not enabled or permission was not granted.");
    }
});
