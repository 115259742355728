import React from 'react';
import {Channel} from "stream-chat";
import Avatar from "./ui/avatar";
import {useActiveChat} from "../hooks/use-active-chat";
import ChatItemDetails from "./ui/chat-item-details";
import {useChatPartnerInfo} from "../hooks/use-chat-partner-info";


interface CustomChatItemProps {
    channel: Channel;
}

const CustomChatItem: React.FC<CustomChatItemProps> = ({channel}) => {
    const {activeChannel, setActiveChannel} = useActiveChat();
    const {userName, userAvatar, isOnline} = useChatPartnerInfo(channel);

    const isActiveChat = activeChannel?.id === channel.id

    const handleClick = () => {
        !isActiveChat && setActiveChannel(channel);
    }

    return (
        <div className={`custom-chat-item-wrapper ${isActiveChat ? 'active-chat': ''}`} onClick={handleClick}>
            <Avatar
                size="md"
                className={isActiveChat ? "active-chat" : ""}
                image={{name: userName, contentBase64: userAvatar}}
                isOnline={isOnline}/>
            <ChatItemDetails channel={channel}/>
        </div>
    );
};

export default CustomChatItem;

