import React, {CSSProperties, MutableRefObject, useEffect, useRef, useState} from 'react';
import {sanitizeUrl} from '@braintree/sanitize-url';
import {BaseImage, DefaultStreamChatGenerics, Modal, ModalGallery, useComponentContext} from "stream-chat-react"
import type {Attachment} from 'stream-chat';
import {Dimensions} from "stream-chat-react/dist/types/types";
import ReactDOM from "react-dom";

export type ImageProps<
    StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
> = {
    dimensions?: Dimensions;
    innerRef?: MutableRefObject<HTMLImageElement | null>;
    previewUrl?: string;
    style?: CSSProperties;
} & (
    | {
    /** The text fallback for the image */
    fallback?: string;
    /** The full size image url */
    image_url?: string;
    /** The thumb url */
    thumb_url?: string;
}
    | Attachment<StreamChatGenerics>
    );

const Image = <
    StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
    props: ImageProps<StreamChatGenerics>,
) => {
    const {dimensions = {}, fallback, image_url, thumb_url, innerRef, previewUrl, style} = props;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const imageSrc = sanitizeUrl(previewUrl || image_url || thumb_url);
    const toggleModal = () => setModalIsOpen((modalIsOpen) => !modalIsOpen);
    const wrapperImageRef = useRef<HTMLImageElement>(null);
    const [orientation, setOrientation] = useState('');

    useEffect(() => {
        const imgEl = wrapperImageRef.current?.children[0] as HTMLImageElement | undefined;
        if (!imgEl) return;


        const handleLoad = () => {
            const w = imgEl.naturalWidth;
            const h = imgEl.naturalHeight;
            if (w > h) {
                setOrientation('landscape');
            } else {
                setOrientation('portrait');
            }
        };

        if (imgEl.complete) {
            handleLoad();
        } else {
            imgEl.addEventListener('load', handleLoad);
            return () => imgEl.removeEventListener('load', handleLoad);
        }
    }, [imageSrc]);

    return (
        <div ref={wrapperImageRef}>
            <BaseImage
                alt={fallback}
                className={`str-chat__message-attachment--img w-full h-full ${orientation}`}
                loading="lazy"
                height={10}
                width={10}
                data-testid='image-test'
                onClick={toggleModal}
                src={imageSrc}
                style={style}
                tabIndex={0}
                title={fallback}
                {...dimensions}
                {...(innerRef && {ref: innerRef})}
            />
            {modalIsOpen &&
                ReactDOM.createPortal(
                    <div className='fixed z-[100] bg-[rgba(0,0,0,0.2)] backdrop-blur-[3px] inset-0'>
                        <Modal className='str-chat__image-modal' onClose={toggleModal} open={modalIsOpen}>
                            <ModalGallery images={[props]} index={0}/>
                        </Modal>
                    </div>
                    ,
                    document.body,
                )}
        </div>
    );
};

export default Image
