import NotificationService from "../../common/services/notification.service";
import ApiService from "../../../axios/api.service";
import {AxiosError} from "axios";

class BecomeCreatorApiService extends ApiService {

    public async submitFormAndGoToPersonalExperiences(formData: FormData) {
        return this.post<void>('/account/promotion-request', formData, {
            errorMessage: ""
        }).catch((error: AxiosError) => {
            const response = error.response

            if (response) {
                const data = response.data
                switch (response.status) {
                    case 422:
                        if (Array.isArray(data)) {
                            if (data[0]?.errorCode === "USERNAME_ALREADY_TAKEN") NotificationService.error("This username is already taken.")
                        }
                        break;
                }
            }

            throw error;
        });

        // this.createPromotionRequest(formData).subscribe({
        //   next: value => {
        //     this.currentStep = 4
        //     this.localStorageService.save(LocalStorageKey.PROMOTION_REQUEST_CREATED, 'true');
        //   },
        //   error: err => {
        //     if (err.status == 422) {
        //       this.currentStep = 1;
        //     } else {
        //       this.messageService.open(err.error, 'close', {
        //         duration: 3000,
        //         verticalPosition: "top",
        //         panelClass: ['snackbar-warn']
        //       })
        //     }
        //   }
        // });
    }
}

export default new BecomeCreatorApiService();
