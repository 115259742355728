import React, {useEffect} from 'react';
import AppModal from "../../common/components/app-modal";
import AppButton from "../../common/components/app-button";
import Coin from "../../core/components/ui/coin";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import {useModal} from "../../common/hooks/use-modal";

interface SuccessPurchasedCreditsModalProps {
    creditsAmount: number;
    appendTo?: string | React.RefObject<HTMLElement> | null;
}

const SuccessPurchasedCreditsModal = ({creditsAmount, appendTo}: SuccessPurchasedCreditsModalProps) => {
    const {closeModal, setCloseCallback} = useModal();

    const handleCloseModal = () => {
        closeModal(ModalIds.SUCCESS_PURCHASE_CREDITS);
    };

    useEffect(() => {
        setCloseCallback(ModalIds.SUCCESS_PURCHASE_CREDITS, () => {
            const url = new URL(window.location.href);
            url.search = '';
            window.history.replaceState(null, '', url.toString());
        });
    },[]);

    return (
        <AppModal appendTo={appendTo} key={ModalIds.SUCCESS_PURCHASE_CREDITS} id={ModalIds.SUCCESS_PURCHASE_CREDITS}
                  disableScroll={true} zIndex={40}>
            <div className="flex flex-col w-full sm:w-[360px] gap-1">
                <h2 className="text-xl font-semibold">Credits Purchased Successfully</h2>
                {/*<div className="flex items-center gap-2 text-3xl font-bold">*/}
                {/*    <Coin/>*/}
                {/*    <span>{creditsAmount}</span>*/}
                {/*    <span className='text-lg'>yC</span>*/}
                {/*</div>*/}
                <p className="text-gray text-sm">Thank you for your purchase!</p>

                <div className="flex justify-end gap-4 mt-5">
                    <AppButton
                        onClick={handleCloseModal}
                        className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-darken transition"
                        label="Go to Wallet"
                    />
                </div>
            </div>
        </AppModal>
    );
};

export default SuccessPurchasedCreditsModal;
