import { useMediaQuery } from "react-responsive";
import AppButton from "../../../common/components/app-button";
import {ReactComponent as ProductsIcon} from "@assets/icons/products.svg";

interface ButtonProps {
    onClick: () => void;
}
const ShopButton = ({onClick} : ButtonProps) => {
    return(
        <AppButton label=''className='text-special-gray w-[65px] h-[48px] md:w-fit h-fit text-sm font-semibold min-h-[40px]' disabled={false} onClick={onClick}>
            <div className='flex gap-1 items-center'>
            <ProductsIcon className="block w-3 min-w-3 h-3 text-special-gray"/>
                <span className='whitespace-nowrap'>Shop</span>
            </div>
        </AppButton>
    );
}
export default ShopButton;

