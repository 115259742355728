import {useEffect, RefObject} from "react";

/**
 * `useClickOutside` Hook
 *
 * A React hook that detects clicks outside of specified elements and triggers a callback.
 *
 * @param {boolean} enabled Enables or disables the hook.
 * @param {(event: MouseEvent | TouchEvent, target: HTMLElement) => void} onClickOutside
 * Callback executed when a click occurs outside the specified elements.
 * @param {Array<RefObject<HTMLElement> | HTMLElement>} excluded
 * Array of React refs or raw HTML elements to exclude. Clicking inside these elements will not trigger the callback.
 * @param {Array<RefObject<HTMLElement> | HTMLElement | string>} include
 * Array of React refs, raw HTML elements, or `data-*` attribute names that explicitly trigger the callback,
 * even if they are children of `excluded`.
 *
 * @example
 * ```tsx
 * useClickOutside(
 *   isActive,
 *   (event, target) => {
 *     console.log("Clicked outside:", target);
 *   },
 *   [dropdownRef],
 *   ["data-trigger-outside"]
 * );
 * ```
 */
const useClickOutside = (
    enabled: boolean = true,
    onClickOutside: (event: MouseEvent | TouchEvent, target: HTMLElement) => void,
    excluded: Array<RefObject<HTMLElement> | HTMLElement> = [],
    include: Array<RefObject<HTMLElement> | HTMLElement | string> = []
) => {

    const resolveElement = (
        item: RefObject<HTMLElement> | HTMLElement
    ): HTMLElement | null => {
        if (!item) return null;
        return item instanceof HTMLElement ? item : item.current;
    };

    const collectIncludedElements = (): Set<HTMLElement> => {
        const includedElements = new Set<HTMLElement>();

        include.forEach(item => {
            if (typeof item === "string") {
                const elements = document.querySelectorAll(`[${item}]`);
                elements.forEach(el => includedElements.add(el as HTMLElement));
            } else {
                const element = resolveElement(item);
                if (element) includedElements.add(element);
            }
        });

        return includedElements;
    };

    useEffect(() => {
        if (!enabled) return;

        const includedElements = collectIncludedElements();

        const handleClickOutside = (event: MouseEvent | TouchEvent) => {
            const target = event.target as HTMLElement;

            const isInsideIncluded = Array.from(includedElements).some(el =>
                el.contains(target)
            );

            if (isInsideIncluded) {
                onClickOutside(event, target);
                return;
            }

            const isInsideExcluded = excluded.some(item => {
                const element = resolveElement(item);
                return element?.contains(target);
            });

            if (!isInsideExcluded) {
                onClickOutside(event, target);
            }
        };

        window.addEventListener("mousedown", handleClickOutside);
        window.addEventListener("touchstart", handleClickOutside);

        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
            window.removeEventListener("touchstart", handleClickOutside);
        };
    }, [enabled, excluded, include, onClickOutside]);
};

export default useClickOutside;

