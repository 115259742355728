import {useEffect, useRef, useState} from "react";
import LocalStorageService from "../../common/services/local-storage.service";
import {LocalStorageKeys} from "../../common/models/enums/local-storage-keys.enum";
import NotificationService from "../../common/services/notification.service";
import useAuth from "../hooks/use-auth";
import {UseEmailTokenHandlerConfig} from "../../common/models/interfaces/use-email-token-handler-config.interface";
import useEffectOnce from "../../common/hooks/use-effect-once";

/**
 * Hook: useVerifyEmailTokenHandler
 *
 * Manages email verification token actions with countdown timers:
 * - Sends the first token on mount (once per hour, configurable).
 * - Handles resend tokens (once per minute, configurable).
 *
 * Config:
 * - resendDelay (number): Delay in seconds before the resend token action is available. Default: 60 seconds.
 * - firstTokenDelay (number): Delay in seconds before the first token can be sent again. Default: 3600 seconds.
 * - initSendToken (boolean): Determines if the first token is sent on mount. Default: true.
 * - notifyOnResend (boolean): Determines if a notification should be displayed on token resend. Default: true.
 *
 * Features:
 * - Countdown persistence using LocalStorage.
 * - Notifications for success or failure.
 *
 * Returns:
 * - resendCountdown (number): Seconds until the resend action is available.
 * - firstTokenCountdown (number): Seconds until the first token can be sent again.
 * - handleResendToken (function): Triggers the resend token action.
 */
const defaultConfig: UseEmailTokenHandlerConfig = {
    firstTokenDelay: 3600,
    resendDelay: 60,
    initSendToken: true,
}

const useVerifyEmailTokenHandler = (config?: UseEmailTokenHandlerConfig) => {
    const {sendEmailConfirmationToken, currentUser} = useAuth();

    const {
        firstTokenDelay,
        resendDelay,
        initSendToken
    }: UseEmailTokenHandlerConfig = {...defaultConfig, ...config};

    const userId: string | undefined = currentUser?.id;

    const getUserTimers = (): Record<string, number> => {
        const savedTimers = LocalStorageService.get(LocalStorageKeys.TIMER_MAP);
        return savedTimers ? JSON.parse(savedTimers) : {};
    };

    const setUserTimers = (timers: Record<string, number>): void => {
        LocalStorageService.save(LocalStorageKeys.TIMER_MAP, JSON.stringify(timers));
    };

    const [resendCountdown, setResendCountdown] = useState(() => {
        if (!userId) return 0;
        const timers: Record<string, number> = getUserTimers();
        return timers[`${userId}_resend`]
            ? Math.max(Number(timers[`${userId}_resend`]) - Math.floor(Date.now() / 1000), 0)
            : 0;
    });

    const [firstTokenCountdown, setFirstTokenCountdown] = useState(() => {
        if (!userId) return 0;
        const timers: Record<string, number> = getUserTimers();
        return timers[`${userId}_firstToken`]
            ? Math.max(Number(timers[`${userId}_firstToken`]) - Math.floor(Date.now() / 1000), 0)
            : 0;
    });

    const handleResendToken = async () => {
        if (resendCountdown === 0) {
            try {
                await sendEmailConfirmationToken(currentUser?.email!);
                setResendCountdown(resendDelay!);

                const timers: Record<string, number> = getUserTimers();
                timers[`${userId}_resend`] = Math.floor(Date.now() / 1000) + resendDelay!;
                setUserTimers(timers);

                NotificationService.success(
                    `A verification code has been resent to ${currentUser?.email} successfully`, "",
                    {duration: 10000}
                );
            } catch (error) {
                console.error("Failed to resend verification code:", error);
                throw error;
            }
        }
    };

    const sendFirstToken = async () => {
        if (firstTokenCountdown === 0) {
            try {
                await sendEmailConfirmationToken(currentUser?.email!);
                setFirstTokenCountdown(firstTokenDelay!);

                const timers: Record<string, number> = getUserTimers();
                timers[`${userId}_firstToken`] = Math.floor(Date.now() / 1000) + firstTokenDelay!;
                setUserTimers(timers);


                NotificationService.success(
                    "A verification code has been sent to your email. Please check your inbox and follow the instructions.",
                    "",
                    {
                        duration: 10000,
                        waitForPrevious: true,
                    }
                );
            } catch (error) {
                console.error("Failed to send initial verification code:", error);
                throw error;
            }
        }
    };

    useEffect(() => {
        let resendTimer: NodeJS.Timeout;
        if (resendCountdown > 0) {
            resendTimer = setInterval(() => setResendCountdown((prev) => prev - 1), 1000);
        }
        return () => clearInterval(resendTimer);
    }, [resendCountdown]);

    useEffect(() => {
        let firstTokenTimer: NodeJS.Timeout;
        if (firstTokenCountdown > 0) {
            firstTokenTimer = setInterval(() => setFirstTokenCountdown((prev) => prev - 1), 1000);
        }
        return () => clearInterval(firstTokenTimer);
    }, [firstTokenCountdown]);

    useEffectOnce(() => {
        initSendToken && sendFirstToken();
    })

    return {
        resendCountdown,
        firstTokenCountdown,
        handleResendToken,
        sendFirstToken,
    };
};

export default useVerifyEmailTokenHandler;
