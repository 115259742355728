import {MessageText as SCRMessageText, renderText, StreamMessage} from "stream-chat-react";
import QuotedMessage from "./quoted-message";
import React from "react";

interface MessageTextProps {
    message?: StreamMessage;
    byOwner: boolean;
}

const MessageText = ({message, byOwner}: MessageTextProps) => {
    let rootClass = "message-text font-medium text-base leading-[1.3rem]"

    rootClass = byOwner ? rootClass + " text-black" : rootClass + " text-primary-darken"

    const hasQuotedMessage = !!message?.quoted_message_id
    const modifiedMessage: StreamMessage = {
        ...message!,
        quoted_message: undefined,
        type: "regular"
    }

    return (
        <div className='flex flex-col gap-y-2 max-w-fit'>
            {hasQuotedMessage && <QuotedMessage message={message?.quoted_message}/>}
            <SCRMessageText renderText={renderText} message={modifiedMessage} customInnerClass={rootClass}/>
        </div>
    )
}

export default MessageText
