import WrapperCard from "../../../common/components/wrapper-card";
import {ReactComponent as WarningIcon} from "@assets/icons/warning.svg"
import {useNavigate} from "react-router-dom";

interface DontHaveEnoughCoinsCardProps {
}

const DontHaveEnoughCoinsCard = (props: DontHaveEnoughCoinsCardProps) => {
    const navigate = useNavigate();

    const goToBuyCredits = () => {
        navigate("/app/wallet")
    }

    return (
        <WrapperCard
            className='bg-semantic-warning-pastel border-0 text-special-gray flex flex-col justify-between w-full flex-wrap h-[106px]'>
            <header className='flex flex-col gap-3 leading-none h-full'>
                <div className='flex gap-2 h-full'>
                    <div className='rounded-full bg-semantic-warning min-w-6 w-6 h-6 flex items-center justify-center'>
                        <WarningIcon className='h-[0.625rem] w-fit min-w-fit text-white'/>
                    </div>

                    <div className='flex flex-col justify-between h-full'>
                        <span className='font-semibold text-base text-black leading-[1.2rem] max-w-[200px]'>
                            You don’t have enough yCoins to buy this product
                        </span>
                        <span
                            onClick={goToBuyCredits}
                            className='text-special-gray font-medium text-sm leading-4 cursor-pointer hover:underline focus:underline'>
                           Buy coins to continue
                        </span>
                    </div>
                </div>
            </header>
        </WrapperCard>
    )
}

export default DontHaveEnoughCoinsCard
