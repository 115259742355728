import React from "react";
import {useChatPartnerInfo} from "../../hooks/use-chat-partner-info";
import {Channel} from "stream-chat";
import MessagesBadge from "../../../core/components/badges/messages-badge";
import useAuth from "../../../auth/hooks/use-auth";
import {UserAuthority} from "../../../auth/models/enums/user-authority.enum";
import {MessageTimestamp} from "./message-time-stamp";
import MessageStatus from "./message-status";
import {useMessageState} from "../../hooks/use-message-state";

interface ChatItemDetailsProps {
    channel: Channel;
}

const ChatItemDetails = ({channel}: ChatItemDetailsProps) => {
    const {hasAnyAuthorities} = useAuth();
    const {userName, lastDeliveredMessage, lastDeliveredMessageText} = useChatPartnerInfo(channel);
    const {isReadByAll} = useMessageState(lastDeliveredMessage, channel);

    return (
        <div className="custom-chat-item-details">
            <div className="header">
                <div className='flex gap-2 items-center truncate'>
                    {/*<span className='subscriber'>*/}
                    {/*    <SubFilledIcon/>*/}
                    {/*</span>*/}
                    <h4 className="name">{userName || 'Unknown User'}</h4>
                </div>
                {hasAnyAuthorities([UserAuthority.FAN]) ? (
                    <span className="last-active">
                        <MessageTimestamp timestamp={lastDeliveredMessage?.created_at}/>
                        <MessageStatus isReadByPartner={isReadByAll}/>
                    </span>
                ) : (
                    // <div>coins</div>
                    <span className="last-active">
                        <MessageTimestamp timestamp={lastDeliveredMessage?.created_at}/>
                        <MessageStatus isReadByPartner={isReadByAll}/>
                    </span>
                )}
            </div>
            <div className="custom-chat-item-extra">
                <span className="last-message">
                    {lastDeliveredMessageText || 'No messages yet'}
                </span>
                {hasAnyAuthorities([UserAuthority.FAN]) ? (
                    <MessagesBadge count="per-channel" channelId={channel.id!}/>
                ) : (
                    <MessagesBadge count="per-channel" channelId={channel.id!}/>
                    // <div className='flex gap-1'>
                    //     <span className="last-active">
                    //         <MessageTimestamp timestamp={lastDeliveredMessage?.created_at!}/>
                    //         <MessageStatus isReadByPartner={isReadByPartner}/>
                    //     </span>
                    //     <MessagesBadge count="per-channel" channelId={channel.id!}/>
                    // </div>
                )}
            </div>
        </div>
    )
}

export default ChatItemDetails
