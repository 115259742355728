export const formatDateForSeparator = (date: Date): string => {
    const now = new Date();
    const differenceInDays = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

    if (differenceInDays === 0) {
        return "Today";
    } else if (differenceInDays === 1) {
        return "Yesterday";
    } else {
        // Format: DD/MM/YYYY
        return date.toLocaleDateString(undefined, {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    }
}
