import React, {createContext, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import useAuth from "../../auth/hooks/use-auth";
import LocalStorageService from "../../common/services/local-storage.service";
import {LocalStorageKeys} from "../../common/models/enums/local-storage-keys.enum";

interface RegistrationContextProps {
    selectedTypeAccount: UserAuthority;
    setSelectedTypeAccount: (type: UserAuthority) => void;
    isSelectedCC: boolean;
    isLoadingLocalStorage: boolean;
}

const RegistrationContext = createContext<RegistrationContextProps | undefined>(undefined);

const RegistrationContextProvider = ({children}: { children: ReactNode }) => {
    const {currentUser} = useAuth();
    const [isLoadingLocalStorage, setIsLoadingLocalStorage] = useState(true);
    const [selectedTypeAccount, setSelectedTypeAccountState] = useState<UserAuthority>(
        UserAuthority.FAN
    );

    useEffect(() => {
        if (currentUser?.id) {
            const storedMapString = LocalStorageService.get(LocalStorageKeys.REGISTRATION_SELECTED_TYPE_ACC);
            const storedMap = storedMapString ? JSON.parse(storedMapString) : {};

            if (storedMap[currentUser.id]) {
                setSelectedTypeAccountState(storedMap[currentUser.id] as UserAuthority);
            }
        }
        setIsLoadingLocalStorage(false);
    }, [currentUser]);

    const handleSetSelectedTypeAccount = useCallback((type: UserAuthority) => {
        setSelectedTypeAccountState(type);

        if (currentUser?.id) {
            const storedMapString = LocalStorageService.get(LocalStorageKeys.REGISTRATION_SELECTED_TYPE_ACC);
            const storedMap = storedMapString ? JSON.parse(storedMapString) : {};
            storedMap[currentUser.id] = type;
            LocalStorageService.save(LocalStorageKeys.REGISTRATION_SELECTED_TYPE_ACC, JSON.stringify(storedMap))
        }
    }, [currentUser]);

    const isSelectedCC = useMemo(() => {
        return (
            selectedTypeAccount === UserAuthority.CONTENT_CREATOR &&
            currentUser?.authority === UserAuthority.FAN
        );
    }, [selectedTypeAccount, currentUser, isLoadingLocalStorage]);

    return (
        <RegistrationContext.Provider
            value={{
                selectedTypeAccount,
                setSelectedTypeAccount: handleSetSelectedTypeAccount,
                isSelectedCC,
                isLoadingLocalStorage,
            }}
        >
            {children}
        </RegistrationContext.Provider>
    );
};

export {RegistrationContextProvider, RegistrationContext};
