import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {animated, useTransition} from "@react-spring/web";
import {classNames} from "../../../utils/class-names";

interface StickyActionBarProps {
    children: React.ReactNode;
    parentRef?: React.RefObject<HTMLDivElement>;
    forceReload?: boolean;
}

const StickyBottomActionBar: React.FC<StickyActionBarProps> = ({children, parentRef, forceReload}) => {
    const [position, setPosition] = useState({x: 0, maxWidth: 'auto'});
    const actionBarRef = useRef<HTMLDivElement>(null);
    const placeholderRef = useRef<HTMLDivElement>(null);
    const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth >= 768);

    const updatePosition = () => {
        setIsDesktop(window.innerWidth >= 768);
        if (parentRef?.current && actionBarRef.current) {
            const parentRect = parentRef.current.getBoundingClientRect();
            const windowWidth = window.innerWidth;

            const newX = parentRect.left + (parentRect.width / 2) - (actionBarRef.current.offsetWidth / 2);
            const adjustedX = Math.max(0, Math.min(newX, windowWidth - actionBarRef.current.offsetWidth));

            setPosition({
                x: adjustedX,
                maxWidth: isDesktop ? 'auto' : `${parentRect.width}px`,
            });
        }
    };

    const transitions = useTransition(true, {
        delay: 300,
        from: {transform: 'translateY(calc(120%))'},
        enter: {transform: 'translateY(0%)'},
        leave: {transform: 'translateY(100%)'},
        config: {tension: 220, friction: 20},
    });

    useLayoutEffect(() => {
        window.addEventListener('resize', updatePosition);
        parentRef?.current?.addEventListener('resize',updatePosition);
        return () => {
            window.removeEventListener('resize', updatePosition)
            parentRef?.current?.removeEventListener('resize', updatePosition)
        }
    }, [parentRef, parentRef?.current]);

    useEffect(() => {
        if (!parentRef?.current) return;

        const observer = new ResizeObserver(() => {
            updatePosition();
        });

        observer.observe(parentRef.current);

        return () => {
            if (parentRef.current) observer.unobserve(parentRef.current);
        };
    }, [parentRef, parentRef?.current]);

    useLayoutEffect(() => {
        if (parentRef?.current && actionBarRef.current) updatePosition();
    }, [forceReload, parentRef?.current, actionBarRef.current]);

    return (
        <>
            {/* Placeholder div zajmujący przestrzeń */}
            <div ref={placeholderRef} style={{height: actionBarRef.current?.offsetHeight || 0}}/>

            {/* Faktyczny StickyBottomActionBar */}
            {transitions((style: any, item: any) =>
                    item && (
                        <animated.div
                            ref={actionBarRef}
                            style={{
                                boxShadow: isDesktop
                                    ? '0px 0px 8px -5px rgb(66, 68, 90)'
                                    : '0px -12px 25px -2px rgba(66, 68, 90, 0.1)',
                                left: isDesktop ? `${position.x}px` : '0',
                                transform: isDesktop ? 'translateX(0)' : '',
                                maxWidth: position.maxWidth,
                                ...style,
                            }}
                            className={classNames(
                                `z-20 bg-white md:bg-gray-light w-full md:w-fit md:rounded-3xl md:max-w-fit`,
                                'md:block fixed bottom-0 md:bottom-[20px]',
                            )}
                        >
                            <div className='p-5 md:p-4'>
                                {children}
                            </div>
                        </animated.div>
                    )
            )}
        </>
    );
};

export default StickyBottomActionBar;
