import * as yup from "yup";
import {validateUsername} from "./validate-username";

export const schemaAccountDetailsPartOne = yup.object({
    username: validateUsername,

    birthDate: yup
        .date()
        .typeError("Invalid date format")
        .max(new Date(), "Date of birth must be in the past")
        .required("Date of birth is required"),

    roleId: yup
        .number()
        .required("Account type is required"),
}).required();

export const schemaAccountDetailsPartTwo = yup.object({
    contactNumber: yup
        .string()
        .matches(/^[0-9+() -]*$/, "Enter a valid phone number")
        .nullable()
        .optional(),

    areaCode: yup
        .string()
        .nullable()
        .optional(),

    genderId: yup
        .number()
        .nullable()
        .optional(),

    firstname: yup
        .string()
        .nullable()
        .transform(value => (value === "" ? null : value))
        .optional()
        .min(2, "Name must be at least 2 characters")
        .max(50, "Name cannot exceed 50 characters"),


    lastname: yup
        .string()
        .nullable()
        .transform(value => (value === "" ? null : value))
        .optional()
        .min(2, "Surname must be at least 2 characters")
        .max(50, "Surname cannot exceed 50 characters"),

    countryId: yup
        .number()
        .nullable()
        .optional(),
}).required();
