import React, {useEffect, useRef, useState} from "react";
import {DragDropContext, Droppable} from "@hello-pangea/dnd";
import {ReactComponent as InfoIcon} from "@assets/icons/info.svg";
import AppButton from "../../common/components/app-button";
import FileHelper from "../../../utils/file-helper";
import {TempProductFile} from "../models/interfaces/product-file.interface";
import {v4 as uuidv4} from "uuid";
import NotificationService from "../../common/services/notification.service";
import FileItem from "./file-item";
import {DeepRequired, FieldErrorsImpl} from "react-hook-form";
import {DigitalProductFormBody} from "../models/interfaces/digital-product-form-body.interface";

interface MultiFileUploaderProps {
    label?: string;
    description?: string[];
    maxFileSizeInMb?: number;
    maxFiles?: number;
    allowedFormats?: string[];
    onFilesChange: (files: TempProductFile[]) => void;
    initialFiles: TempProductFile[];
    onRemoveFile: (file: TempProductFile) => void;
    isSubmittingSuccessful: boolean;
    isSubmitting: boolean;
    errors: FieldErrorsImpl<DeepRequired<DigitalProductFormBody>>;
}

const MultiFileUploader: React.FC<MultiFileUploaderProps> = (props: MultiFileUploaderProps) => {
    const [files, setFiles] = useState<TempProductFile[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);

    const {
        label = "Files",
        description = ["Up to 10 files per product. Max. 50mb", "file in .pdf, .zip, .jpg, .png, .svg format"],
        maxFileSizeInMb = 50,
        maxFiles = 10,
        allowedFormats = ["pdf", "zip", "jpg", "png", "svg"],
        onFilesChange,
        initialFiles = [],
        onRemoveFile,
        isSubmittingSuccessful,
        isSubmitting,
        errors,
    } = props;

    useEffect(() => {
        if (initialFiles.length > 0) {
            const fileItems = initialFiles
                .map((file) => ({
                    ...file,
                    previewUrl: file.previewUrl || URL.createObjectURL(file.file),
                }))
                .sort((el1, el2) => el1.ordinalNumber - el2.ordinalNumber);

            setFiles(fileItems);
        }
    }, [initialFiles]);

    useEffect(() => {
        if (isSubmittingSuccessful) {
            setFiles([]);
        }
    }, [isSubmittingSuccessful]);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const uploadedFiles = event.target.files;

        if (uploadedFiles) {
            const newFiles: TempProductFile[] = [];
            let totalSize = files.reduce((sum, file) => sum + file.file.size, 0);


            for (let i = 0; i < uploadedFiles.length; i++) {
                const file = uploadedFiles[i];

                const validation = FileHelper.validateFile(file, allowedFormats, maxFileSizeInMb, totalSize);

                if (!validation.isValid) {
                    NotificationService.warning(validation.message!, "", {duration:5000});
                } else {
                    const newFile: TempProductFile = {
                        uuid: uuidv4(),
                        file,
                        previewUrl: URL.createObjectURL(file),
                        ordinalNumber: files.length + i + 1,
                    };

                    newFiles.push(newFile);
                    totalSize += file.size;
                }
            }

            const allFiles = [...files, ...newFiles].slice(0, maxFiles);

            setFiles(allFiles);
            onFilesChange(allFiles);
        }
    };

    const handleRemoveFile = (fileToRemove: TempProductFile) => {
        onRemoveFile && onRemoveFile(fileToRemove);
    };

    const handleOpenExplorerFiles = () => {
        inputRef.current && inputRef.current.click();
    };

    const handleDragEnd = (result: any) => {
        if (!result.destination) return;

        const updatedFiles = reorderFiles(files, result.source.index, result.destination.index);

        setFiles(updatedFiles);
        onFilesChange(updatedFiles);
    };

    const reorderFiles = (files: TempProductFile[], sourceIndex: number, destinationIndex: number): TempProductFile[] => {
        const reorderedFiles = Array.from(files);
        const [removed] = reorderedFiles.splice(sourceIndex, 1);
        reorderedFiles.splice(destinationIndex, 0, removed);

        return reorderedFiles.map((file, index) => ({
            ...file,
            ordinalNumber: index + 1,
        }));
    };

    return (
        <div className="border-2 border-dashed border-gray-light rounded-xl p-4 space-y-1">
            <div className="text-lg font-semibold leading-5">{label}</div>
            <div className="flex items-start mb-3">
                <InfoIcon className="h-3 w-3 min-w-3 inline-block mr-1"/>
                <p className="text-special-gray font-medium text-xs flex flex-col gap-1 leading-[0.9rem]">
                    {description.map((el, i) => (
                        <span key={i} className="first-letter:uppercase">{el}</span>
                    ))}
                </p>
            </div>

            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="file-list">
                    {(provided) => (
                        <ul {...provided.droppableProps} ref={provided.innerRef} className="space-y-2 !my-4">
                            {files.map((fileItem, index) =>
                                <FileItem
                                    key={fileItem.uuid}
                                    fileItem={fileItem}
                                    index={index}
                                    isSubmitting={isSubmitting}
                                    handleRemoveFile={handleRemoveFile}
                                />
                            )}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>

            {files.length < maxFiles && (
                <AppButton
                    onClick={handleOpenExplorerFiles}
                    label="+ Add files"
                    disabled={isSubmitting}
                    className="w-full bg-primary-pastel hover:bg-primary hover:text-white text-primary font-semibold !rounded-2xl"
                />
            )}

            <span>
                {errors["files"] && <p className="text-red-500 text-xs">{"" + errors["files"]?.message}</p>}
            </span>

            <input
                ref={inputRef}
                type="file"
                multiple
                accept={allowedFormats.map((ext) => `.${ext}`).join(",")}
                className="hidden"
                onChange={handleFileUpload}
            />
        </div>
    );
};

export default MultiFileUploader;
