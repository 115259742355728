import React, {ReactNode, useEffect, useMemo, useState} from "react";
import {ViewState} from "../models/enums/view-state.enum";


interface ViewStatesProps<DataType> {
    loading: boolean;
    error: string | null;
    data: DataType | null | undefined;
    children: ReactNode;
}

interface StateCaseProps {
    state: ViewState | ViewState[];
    children?: ReactNode;
    condition?: boolean;
    onInit?: () => void;
}

const ViewStates = <DataType,>({loading, error, data, children}: ViewStatesProps<DataType>) => {
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    useEffect(() => {
        if (data !== null && data !== undefined || error) {
            setIsInitialLoad(false);
        }
    }, [data, error]);

    const isEmpty = useMemo(() => {
        if (data === null || data === undefined) return true; // Dane są faktycznie puste
        if (Array.isArray(data)) return data.length === 0; // Tablica jest pusta
        if (typeof data === "object") return Object.keys(data).length === 0; // Obiekt jest pusty
        return false; // Dane nie są puste
    }, [data]);

    const currentState: ViewState = useMemo(() => {
        if (loading || isInitialLoad) return ViewState.LOADING; // Zawsze najpierw pokazuj `LOADING`
        if (error) return ViewState.ERROR; // Jeśli wystąpił błąd
        if (!isEmpty) return ViewState.FETCHED; // Jeśli dane nie są puste
        if (!loading && !isInitialLoad && isEmpty) return ViewState.EMPTY; // Puste dane po zakończeniu ładowania
        return ViewState.LOADING; // Bezpieczny domyślny stan
    }, [loading, error, isEmpty, isInitialLoad]);

    const childrenArray = React.Children.toArray(children) as React.ReactElement<StateCaseProps>[];

    const match = childrenArray.find(
        (child) =>
            React.isValidElement(child) &&
            "state" in child.props &&
            (Array.isArray(child.props.state)
                ? child.props.state.includes(currentState)
                : child.props.state === currentState)
    );

    return match || null;
};


const State = ({children}: StateCaseProps) => <>{children}</>;

export {ViewStates, State};
