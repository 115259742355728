import {useContext, useState} from "react";
import {ReactComponent as LinkIcon} from "@assets/icons/link.svg";
import {ReactComponent as EllipsisVerticalIcon} from "@assets/icons/more.svg";
import {ReactComponent as CrossIcon} from "@assets/icons/cross.svg";
import {classNames} from "../../../utils/class-names";
import NotificationService from "../../common/services/notification.service";
import AppButton from "../../common/components/app-button";
import ClipboardHelper from "../../../utils/clipboard-helper";
import {UserProfileContext, UserProfileContextProps} from "../contexts/user-profile-context";
import {PublicUserProfile} from "../../core/models/interfaces/public-user-profile.interface";

interface PublicProfileSettingsButtonProps {
    creatorUserName: string;
    isPreviewMode?: boolean;
}

const PublicProfileSettingsButton = (props: PublicProfileSettingsButtonProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const {
        creatorUserName,
        isPreviewMode
    } = props;
    const {
        isFollowed,
        unfollowCreator,
    } = useContext(UserProfileContext) as UserProfileContextProps<PublicUserProfile>;

    const urlPublicProfile = `${window.location.origin}/${creatorUserName}`

    const handleCopyLink = async () => {
        await ClipboardHelper.copyText(urlPublicProfile,
            () => NotificationService.success('Link has been copied to clipboard!', "Success", {duration:5000}),
            (error) => NotificationService.error('Failed to copy link', error, 'Error', {duration:5000})
        );
        setIsOpen(false);
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="relative inline-block text-left">
            <AppButton
                onClick={toggleMenu}
                className={classNames(
                    "flex items-center justify-center w-10 h-10 bg-white text-special-gray border border-special-gray rounded-2xl",
                    isOpen && "!border-black !text-black"
                )}
                label=''>
                <EllipsisVerticalIcon className='w-fit h-[0.875rem]'/>
            </AppButton>

            {isOpen && (
                <div
                    className="absolute right-0 z-10 w-48 mt-2 bg-white rounded-2xl shadow-notification py-1 !font-semibold !text-sm">
                    <AppButton
                        onClick={handleCopyLink}
                        classNameContentWrapper="!justify-start"
                        className="block w-full px-4 py-2 border-0 hover:text-primary"
                        label=''>
                        <div className='flex gap-3 items-center'>
                            <LinkIcon className='w-[1.125rem] h-[1.125rem]'/>
                            <span className=''>Copy link to profile</span>
                        </div>
                    </AppButton>
                    {!isPreviewMode && isFollowed && (
                        <AppButton
                            onClick={unfollowCreator}
                            classNameContentWrapper="!justify-start"
                            className="block w-full px-4 py-2 border-0 hover:text-primary "
                            label=''>
                            <div className='flex gap-3 items-center'>
                                <CrossIcon className='w-3 h-3 mr-[0.375rem]'/>
                                <span className=''>Unfollow</span>
                            </div>
                        </AppButton>
                    )}
                </div>
            )}
        </div>
    );
}

export default PublicProfileSettingsButton;
