import analyticsService from "../services/analytics-service";
import {StripePrice} from "../models/interfaces/stripe-price.interface";
import useAuth from "../../auth/hooks/use-auth";
import {PublicUserProfile} from "../models/interfaces/public-user-profile.interface";
import {DigitalProduct} from "../models/interfaces/digital-product.interface";
import {DigitalProductDetails} from "../models/interfaces/digital-product-details.interface";
import {AuthenticatedUser} from "../../auth/models/interfaces/authenticated-user.interface";
import CreditsConversionService from "../../purchases/services/credits-conversion.service";
import {AddPaymentInfoEvent} from "../models/interfaces/events/add-payment-info-event.interface";

export const useAnalytics = () => {
    const {currentUser} = useAuth();

    const sendRegisterAccInfo = (phone: string) => {
        if (currentUser) {
            analyticsService.sendRegisterEvent({
                user_ID: currentUser?.id!,
                mail: currentUser?.email!,
                phone: phone,
            });
        }
    };

    const sendDigitalProductViewInfo = (product: DigitalProductDetails | null) => {
        analyticsService.sendViewItemEvent({
            ecommerce: {
                currency: "USD",
                value: parseFloat(CreditsConversionService.convertYCreditsToDollars(product?.price!)),
                items: [{
                    item_id: product!.id,
                    item_name: product!.name,
                    coupon: "",
                    discount: 0,
                    item_brand: product!.creatorName,
                    item_list_name: "Produkty Kreatora",
                    price: parseFloat(CreditsConversionService.convertYCreditsToDollars(product?.price!)),
                    quantity: 1
                }]
            }
        });
    };

    const sendYCreditsListViewInfo = (prices: StripePrice[]) => {
        analyticsService.sendViewItemListEvent({
            ecommerce: {
                item_list_name: "view_yCredits_list",
                items: prices.map(price => ({
                    item_id: price.productId,
                    item_name: `yCredits ${price.coins}`,
                    item_brand: "yurs",
                    item_category: "Kredyty",
                    item_list_name: "Zakup kredytów",
                    price: parseFloat((price.unitAmount / 100).toFixed(2)),
                    quantity: 1,
                    coupon: "",
                }))
            }
        });
    };

    const sendCreditsPromotionViewInfo = (data: StripePrice, discount: number) => {
        analyticsService.sendViewPromotionEvent({
            ecommerce: {
                creative_name: "Hot promotion",
                promotion_name: "Hot promotion",
                items: [{
                    item_id: data.productId,
                    item_name: `yCredits ${data.coins}`,
                    price: parseFloat((data.unitAmount / 100).toFixed(2)),
                    quantity: 1,
                    discount: discount,
                    item_brand: "yurs",
                    item_category: "Kredyty",
                    item_category2: "",
                }]
            }
        });
    };

    const sendSelectedCreditsPromotionInfo = (data: StripePrice, discount: number) => {
        analyticsService.sendSelectedPromotionEvent({
            ecommerce: {
                creative_name: "Hot promotion",
                promotion_name: "Hot promotion",
                items: [{
                    item_id: data.productId,
                    item_name: `yCredits ${data.coins}`,
                    price: parseFloat((data.unitAmount / 100).toFixed(2)),
                    quantity: 1,
                    discount: discount,
                    item_brand: "yurs",
                    item_category: "Kredyty",
                    item_category2: "",
                }]
            }
        });
    }

    const sendCheckoutStartDigitalProductInfo = (userProfile: PublicUserProfile, product: DigitalProductDetails | DigitalProduct) => {
        analyticsService.sendBeginCheckoutEvent(
            {
                ecommerce: {
                    currency: "USD",
                    value: parseFloat(CreditsConversionService.convertYCreditsToDollars(product?.price!)),
                    coupon: "",
                    items: [{
                        item_id: product.id,
                        item_name: product.name,
                        coupon: "",
                        discount: 0,
                        item_brand: userProfile!.creatorName,
                        item_category: "Produkt Kreatora",
                        item_list_name: "Produkty Kreatora",
                        price: parseFloat(CreditsConversionService.convertYCreditsToDollars(product?.price!)),
                        quantity: 1
                    }]
                }
            }
        );
    };

    const sendPaymentInfo = (data: AddPaymentInfoEvent) => {
        analyticsService.sendAddPaymentInfoEvent(data);
    };

    const sendPurchaseDigitalProductInfo = (userProfile: PublicUserProfile | null, product: DigitalProduct, transactionId: string) => {
        if (userProfile) {
            analyticsService.sendPurchaseEvent({
                ecommerce: {
                    transaction_id: transactionId,
                    value: parseFloat(CreditsConversionService.convertYCreditsToDollars(product?.price!)),
                    currency: "USD",
                    coupon: "",
                    items: [{
                        item_id: product.id,
                        item_name: product.name,
                        coupon: "",
                        discount: 0,
                        item_brand: userProfile.creatorName,
                        item_category: "Produkt kreatora",
                        item_list_name: "Produkty kreatora",
                        price: parseFloat(CreditsConversionService.convertYCreditsToDollars(product?.price!)),
                        quantity: 1
                    }]
                }
            });
        }
    };

    // const sendNewsletterInfo = (data: FormSentEvent) => {
    //     analyticsService.sendNewsletterSentEvent(data);
    // };

    const sendVisitedCreatorInfo = (userProfile: PublicUserProfile) => {
        analyticsService.sendViewCreatorEvent({
            creator_ID: userProfile.id,
            creator_name: userProfile.username
        });
    }

    const sendUserInfo = (userProfile: AuthenticatedUser) => {
        analyticsService.sendUserData({
            user_id: userProfile.id,
            crm_id: userProfile.id
        })
    }

    return {
        sendRegisterAccInfo,
        sendDigitalProductViewInfo,
        sendYCreditsListViewInfo,
        sendCreditsPromotionViewInfo,
        sendCheckoutStartDigitalProductInfo,
        // sendPaymentInfo,
        sendPurchaseDigitalProductInfo,
        // sendNewsletterInfo,
        sendVisitedCreatorInfo,
        sendSelectedCreditsPromotionInfo,
        sendUserInfo
    };
};
