// StepTwo.tsx
import React from 'react';
import {ReactComponent as ChevronLeftIcon} from "@assets/icons/chevron-left.svg";
import {ReactComponent as VideoCameraIcon} from "@assets/icons/video.svg";
import {ReactComponent as InformationCircleIcon} from "@assets/icons/info.svg";
import {classNames} from "../../../utils/class-names";
import AppButton from "../../common/components/app-button";
import SwitcherFormField from "../../common/components/forms/switcher-form-field";
import NumberFormField from "../../common/components/forms/number-form-field";
import StepDot, {StepDotState as DotState} from '../../common/components/app-dot';
import {ReactComponent as AudioIcon} from '@assets/icons/audio.svg';
import {ReactComponent as ChatIcon} from '@assets/icons/chat.svg';
import { UseFormReturn } from 'react-hook-form';
import { FormValues } from '../views/become-creator-form';
import CreditsConversionService from "../../purchases/services/credits-conversion.service";
import SkipStepBecomeCreator from "../../core/components/ui/skip-step-become-creator";
import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";

interface StepTwoProps {
    prevStep: () => void;
    nextStep: () => void;
    messageDisabled: boolean;
    voiceDisabled: boolean;
    videoDisabled: boolean;
    messagePrice: number;
    voicePrice: number;
    videoPrice: number;
    onSkipAll?: UniversalClickHandler;
}

const StepTwo: React.FC<StepTwoProps> = ({ onSkipAll,prevStep, nextStep, messageDisabled, voiceDisabled, videoDisabled, messagePrice, voicePrice, videoPrice }) => (
    <div className='max-w-[450px] md:ml-auto md:mr-auto space-y-6'>
        <div className={classNames('space-y-3')}>
            <div className="flex gap-1 justify-between">
                    <AppButton
                        type="button"
                        label=""
                        className="bg-gray-light text-gray w-10 h-10 !rounded-2xl border-0"
                        onClick={prevStep}
                    >
                        <ChevronLeftIcon className="w-2 h-3"/>
                    </AppButton>
                {onSkipAll && (
                    <span className='inline-block md:hidden'><SkipStepBecomeCreator onClick={onSkipAll}/></span>
                )}
            </div>
            <h1 className="md:max-w-[282px] font-bold text-4xl">
                Set your <br/>
                contact pricing
            </h1>
            {/* <div className="text-gray">Lorem Ipsum is simply dummy text of the printing and typesetting industry</div> */}
        </div>

        <div className="flex justify-start items-center space-x-3 pb-4">
            <ChatIcon className="min-w-5 w-5 h-5" />
            <div className='font-bold text-lg leading-[1.35rem] w-full'>Text messages</div>
            <SwitcherFormField className="float-right" name="isTextMessages" />
        </div>

        {!(messageDisabled) &&
            <span>
                <NumberFormField
                    min={0}
                    showArrows={false}
                    isYcoin={true}
                    disabled={messageDisabled}
                    name="textMessagePrice"
                    label="One message block price"
                />
                <div className="text-color-gray-500 text-xs text-gray flex justify-start mt-2">
                    <InformationCircleIcon className="w-4 h-4 text-gray-light mr-2" />
                    <div>You'll earn {CreditsConversionService.convertYCreditsToDollarsWithCurrency(messagePrice)} for every message block</div>
                </div>
            </span>
        }

        {/*<div className="flex justify-start items-center space-x-3 pb-4">*/}
        {/*    <AudioIcon className="min-w-5 w-5 h-5" />*/}
        {/*    <div className='font-bold text-lg leading-[1.35rem] w-full'>Voice messages</div>*/}
        {/*    <SwitcherFormField className="float-right" name="isVoiceMessages" />*/}
        {/*</div>*/}

        {/*{!(voiceDisabled) &&*/}
        {/*    <span>*/}
        {/*        <NumberFormField*/}
        {/*            min={0}*/}
        {/*            showArrows={false}*/}
        {/*            isYcoin={true}*/}
        {/*            disabled={voiceDisabled}*/}
        {/*            name="voiceMessagePrice"*/}
        {/*            label="One minute price"*/}
        {/*        />*/}

        {/*        <div className="text-color-gray-500 text-xs text-gray flex justify-start mt-2">*/}
        {/*            <InformationCircleIcon className="w-4 h-4 text-gray-light mr-2" />*/}
        {/*            <div>You'll earn {CreditsConversionService.convertYCreditsToDollarsWithCurrency(voicePrice)} for every minute</div>*/}
        {/*        </div>*/}
        {/*    </span>*/}
        {/*}*/}
        {/*<div className="flex justify-start items-center space-x-3 pb-4 ">*/}
        {/*    <VideoCameraIcon className="min-w-5 w-5 h-5" />*/}
        {/*    <div className='font-bold text-lg leading-[1.35rem] w-full'>Video messages</div>*/}
        {/*    <SwitcherFormField className="float-right" name="isVideoMessages" />*/}
        {/*</div>*/}

        {/*{!(videoDisabled) &&*/}
        {/*    <span>*/}
        {/*        <NumberFormField*/}
        {/*            min={0}*/}
        {/*            showArrows={false}*/}
        {/*            isYcoin={true}*/}
        {/*            disabled={videoDisabled}*/}
        {/*            name="videoMessagePrice"*/}
        {/*            label="One minute price"*/}
        {/*        />*/}
        {/*        <div className="text-color-gray-500 text-xs text-gray flex justify-start mt-2">*/}
        {/*            <InformationCircleIcon className="w-4 h-4 text-gray-light mr-2" />*/}
        {/*            <div>You'll earn {CreditsConversionService.convertYCreditsToDollarsWithCurrency(videoPrice)} for every minute</div>*/}
        {/*        </div>*/}
        {/*    </span>*/}
        {/*}*/}

        <div className={classNames('flex space-x-5 my-6 justify-center items-center')}>
            <StepDot state={DotState.PREVIOUS}/>
            <StepDot state={DotState.ACTIVE}/>
            <StepDot state={DotState.NEXT}/>
            <StepDot state={DotState.NEXT}/>
        </div>

        <AppButton
            type="button"
            label="Next"
            className="bg-primary text-white w-full !rounded-3xl"
            onClick={nextStep}
        />
    </div>
);

export default StepTwo;
