import {useStreamChat} from "../../../chat/hooks/use-stream-chat-context";
import Badge from "../../../common/components/badge";
import React, {memo, useEffect, useMemo, useState} from "react";
import {GetUnreadCountAPIResponse} from "stream-chat";

interface MessagesBadgeBaseProps {
    count?: "all";
    channelId?: never;
}

interface MessagesBadgePerChannelProps {
    count: "per-channel";
    channelId: string;
}

type MessagesBadgeProps = MessagesBadgeBaseProps | MessagesBadgePerChannelProps;

const MessagesBadge = (props: MessagesBadgeProps) => {
    const {unreadChannels} = useStreamChat();
    const {count = "all", channelId} = props;

    const getUnreadMessagesByChannelId = (unreadChannels: GetUnreadCountAPIResponse | undefined, channelId: string): number => {
        if (!unreadChannels?.channels) return 0;

        const channel = unreadChannels.channels.find(
            (channel) => channel.channel_id === "messaging:" + channelId
        );

        return channel?.unread_count || 0;
    };

    const unreadMessagesForChannel = useMemo(() => {
        if (count === "per-channel" && unreadChannels) {
            return getUnreadMessagesByChannelId(unreadChannels, channelId!);
        }
        return 0;
    }, [count, unreadChannels, channelId]);

    const totalUnreadChannels = useMemo(() => {
        return count === "all" ? unreadChannels?.channels.length || 0 : 0;
    }, [count, unreadChannels]);

    const isEmptyBadge =
        (count === "all" && !totalUnreadChannels)
        || (count === "per-channel" && !unreadMessagesForChannel)

    if (isEmptyBadge || !unreadChannels) return null;

    return (
        <Badge className='!bg-secondary !text-black'>
            {count === "all" ? totalUnreadChannels : unreadMessagesForChannel}
        </Badge>
    )
}

export default MessagesBadge
