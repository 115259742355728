export function convertEmptyStringsToNull(obj: any): any {
    if (obj === "") {
        return null;
    }

    if (Array.isArray(obj)) {
        return obj.map((item) => convertEmptyStringsToNull(item));
    }

    if (obj !== null && typeof obj === "object") {
        return Object.keys(obj).reduce((acc, key) => {
            acc[key] = convertEmptyStringsToNull(obj[key]);
            return acc;
        }, {} as Record<string, any>);
    }

    return obj;
}
