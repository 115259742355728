import {Channel, FormatMessageResponse} from "stream-chat";
import {StreamMessage} from "stream-chat-react";

/**
 * Helper function `checkIsReadByPartner` determines if the last message sent by the user
 * has been read by the chat partner.
 *
 * @param {Channel | null} channel - The currently active chat channel.
 * @param {FormatMessageResponse | null} deliveredMessage - The last message sent by the user.
 * @param {string | undefined} chatPartnerId - The ID of the chat partner.
 * @returns {boolean} - True if the message was read by the partner, false otherwise.
 */
export const isReadByPartner = (
    channel: Channel | null,
    deliveredMessage: FormatMessageResponse | StreamMessage | null,
    chatPartnerId: string | undefined
): boolean => {
    if (!deliveredMessage || !channel || !chatPartnerId) return false;

    const partnerReadState = channel.state.read[chatPartnerId];
    return (
        deliveredMessage.created_at !== undefined &&
        (partnerReadState.last_read > deliveredMessage.created_at)
    )
};
