import useAuth from "../features/auth/hooks/use-auth";
import React, {useContext, useEffect} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import useGuardOnRegistrationSteps from "../features/core/hooks/use-guard-registration-steps";
import AppWrapper from "../layout/components/app-wrapper";
import Logo from "../features/core/components/ui/logo";
import VerifyEmailForm from "../features/auth/components/verify-email-form";
import {Case, Default, Switch} from "../features/common/components/switch-case";
import {RegistrationSteps} from "../features/core/models/enums/registration-steps.enum";
import LogoutButton from "../features/auth/components/logout-button";
import useRegistrationSteps from "../features/auth/hooks/use-registration-steps";
import AccountDetailsForm from "../features/core/components/account-details-form";
import AppContentWrapper from "../layout/components/app-content-wrapper";
import AdditionalAccountDetailsForm from "../features/core/components/additional-account-details-form";
import SkipStepVerification from "../features/core/components/ui/skip-step-verification";
import BecomeCreatorForm from "../features/become-creator/views/become-creator-form";
import withAnyProvider from "../features/common/hoc/with-any-provider";
import {RegistrationContext, RegistrationContextProvider} from "../features/core/contexts/registration-context";
import SkipStepBecomeCreator from "../features/core/components/ui/skip-step-become-creator";
import {useAnalytics} from "../features/core/hooks/use-analytics";
import {AdditionalAccountDetails} from "../features/core/models/interfaces/additional-account-details.interface";

const VerificationUserPage = () => {
    const {currentUser} = useAuth();
    const hasCompletedIntroduction: boolean = useGuardOnRegistrationSteps(currentUser);
    const {currentStep, nextStep, isLastStep, moveStepBy, moveToLastStep} = useRegistrationSteps();
    const navigate = useNavigate();
    const {isSelectedCC, isLoadingLocalStorage} = useContext(RegistrationContext)!
    const {sendRegisterAccInfo} = useAnalytics();

    useEffect(() => {
        if (isLoadingLocalStorage) return;
        if (isLastStep) navigate("/");
    }, [currentStep, isLoadingLocalStorage]);

    if (hasCompletedIntroduction) {
        return <Navigate to="/" replace/>;
    }

    const onSuccessHandlerSTEP_3 = (data: AdditionalAccountDetails) => {
        isSelectedCC ? nextStep() : moveStepBy(2)
        sendRegisterAccInfo(`${(data?.areaCode ?? "") + (data?.contactNumber ?? "")}`.replaceAll(" ", ""))
    };

    const onSkipHandlerSTEP_3 = () => {
        isSelectedCC ? nextStep() : moveStepBy(2)
        sendRegisterAccInfo("")
    };

    return (
        <AppWrapper>
            <div className='hidden md:flex flex-col justify-between w-full flex-grow overflow-y-auto p-4'>
                <div className='flex justify-between gap-3 w-full md:mb-4'>
                    <Logo type='complete' className='w-[6rem]'/>
                    <Switch value={currentStep}>
                        <Case case={[RegistrationSteps.STEP_1, RegistrationSteps.STEP_2]}><LogoutButton/></Case>
                        <Case case={RegistrationSteps.STEP_3}>
                            <SkipStepVerification onClick={onSkipHandlerSTEP_3}/>
                        </Case>
                        <Case case={RegistrationSteps.STEP_4} condition={isSelectedCC}>
                            <SkipStepBecomeCreator onClick={nextStep}/>
                        </Case>
                        <Default><SkipStepVerification onClick={nextStep}/></Default>
                    </Switch>
                </div>
            </div>
            <AppContentWrapper className='max-h-full h-svh md:h-full'>
                <Switch value={currentStep}>
                    <Case case={RegistrationSteps.STEP_1}><VerifyEmailForm onSuccess={nextStep}/></Case>
                    <Case case={RegistrationSteps.STEP_2}><AccountDetailsForm onSuccess={nextStep}/></Case>
                    <Case case={RegistrationSteps.STEP_3}>
                        <AdditionalAccountDetailsForm onSuccess={onSuccessHandlerSTEP_3}/>
                    </Case>
                    {!isLoadingLocalStorage && (
                        <>
                            <Case case={RegistrationSteps.STEP_4} condition={!isSelectedCC}
                                  callbackOnInit={moveToLastStep}></Case>
                            <Case case={RegistrationSteps.STEP_4} condition={isSelectedCC}>
                                <BecomeCreatorForm placeForm="registration" onEnding={nextStep} onSkipAll={nextStep}/>
                            </Case>
                        </>
                    )}
                </Switch>
            </AppContentWrapper>
        </AppWrapper>
    )
}

export default withAnyProvider(RegistrationContextProvider, VerificationUserPage)
