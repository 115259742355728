import Base64Helper from "../../../../utils/base64-helper";
import {PhotoIcon} from "@heroicons/react/24/outline";
import React from "react";
import {UniversalClickHandler} from "../../../common/models/types/universal-click-handler.type";
import {ShortDataImage} from "../../../common/models/interfaces/short-data-image.interface";
import SpinnerLoader from "../../../common/components/spinner-loader";

interface DeliveredProductCardProps {
    imageProduct?: ShortDataImage,
    category: string,
    name: string,
    onClick?: UniversalClickHandler<any,any>,
    loadingImage?: boolean,
}

const DeliveredProductCard = (props: DeliveredProductCardProps) => {
    const {
        imageProduct,
        category,
        name,
        onClick,
        loadingImage = false,
    } = props;

    return (
        <div onClick={onClick}
             className='cursor-pointer w-full rounded-2xl bg-white border border-special-gray p-2 gap-2 flex'>
            <div
                className='max-w-[48px] min-w-[48px] w-[48px] h-[48px] flex-grow border border-special-gray rounded-2xl bg-gray-light flex justify-center items-center'>
                {imageProduct?.contentBase64 ? (
                    <img
                        src={Base64Helper.addBase64Prefix(imageProduct.contentBase64)}
                        alt={`Product image - ${imageProduct.name}`}
                        className="w-full h-full object-cover rounded-2xl"
                    />
                ) : (
                    <>
                        {loadingImage ? (
                            <SpinnerLoader size="12px" color='var(--text-gray)'/>
                        ): (
                            <PhotoIcon className='w-5 h-5 text-gray'/>
                        )}
                    </>

                )}
            </div>

            <div className='flex flex-col justify-center'>
                <span
                    className='uppercase font-semibold tracking-[1px] text-special-gray text-[0.625rem] leading-[0.75rem]'>
                    {category}
                </span>
                <span className='font-medium text-base leading-[1.2rem] text-black'>
                    {name}
                </span>
            </div>
        </div>
    )
}

export default DeliveredProductCard
