import {SetupGTM} from "./models/interfaces/setup-cmp.interface";
import {getConsentManagerScript} from "./snippets/get-cmp-script.snippet";
import {ConfigCMP} from "./models/interfaces/config-cmp.interface";

class ConsentManager {
    private config: ConfigCMP;

    constructor(params: ConfigCMP) {
        this.config = params;
    }

    /**
     * Creates a script element for loading the Consent Management Platform (CMP).
     * The script contains the necessary attributes and the initialization snippet.
     * @returns {HTMLElement} - The <script> element with the CMP loading code.
     */
    private getCMPScript(): HTMLElement {
        const cmpScript = document.createElement('script');
        if (this.config.nonce) {
            cmpScript.setAttribute('nonce', this.config.nonce);
        }
        cmpScript.innerHTML = getConsentManagerScript(
            this.config.src,
            this.config.cdid,
            this.config.dataCmpHost,
            this.config.dataCmpCdn,
            this.config.dataCmpCodeSrc,
            this.config.dataCmpAb,
        )
        return cmpScript
    }

    /**
     * Prepares the setup for the Consent Management Platform (CMP).
     * Returns an object containing a method to generate the CMP script element.
     * @returns {SetupGTM} - An object with a method to get the CMP script element.
     */
    public setupCMP(): SetupGTM {
        return {
            getCMPScript: this.getCMPScript.bind(this),
        }
    }

    /**
     * Initializes the Consent Management Platform (CMP) by inserting the CMP script element into the document head.
     * Ensures that the script is added as the first child of the <head> element.
     */
    public initCMP(): void {
        if (!this.config.cdid) {
            console.error("CMP initialization failed: Missing 'cdid' in configuration.");
            return;
        }

        const cmp = this.setupCMP();
        const script = cmp.getCMPScript();

        document.head.insertBefore(script, document.head.childNodes[0]);
    }
}

export default ConsentManager;
