import AppContentWrapper from "../layout/components/app-content-wrapper";
import AppChat from "../features/chat/app-chat";
import React from "react";
import {ActiveChatProvider} from "../features/chat/contexts/active-chat-context";
import {Chat as GetStreamChatProvider} from "stream-chat-react";
import StreamChatService from "../features/chat/services/stream-chat.service";

const MessagesPage = () => {
    return (
        <AppContentWrapper className='md:p-4 bg-white md:rounded-t-[1.5rem] mx-0 h-full max-w-full'>
            <GetStreamChatProvider client={StreamChatService.client}>
                <ActiveChatProvider>
                    <AppChat/>
                </ActiveChatProvider>
            </GetStreamChatProvider>
        </AppContentWrapper>
    );

};
export default MessagesPage;
