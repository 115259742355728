import {ChannelSearchFunctionParams, DefaultStreamChatGenerics} from "stream-chat-react";
import {SetStateAction} from "react";
import {ChannelFilters, ChannelSort, StreamChat} from "stream-chat";

export const customSearchFunction = async (
    props: ChannelSearchFunctionParams,
    event: { target: { value: SetStateAction<string> } },
    client: StreamChat,
) => {
    const {setResults, setSearching, setQuery} = props;
    const value = event.target.value;
    const query = typeof value === "string" ? value.toLowerCase() : "";

    if (!query) {
        setResults([]);
        setQuery("");
        return;
    }

    const filters: ChannelFilters<DefaultStreamChatGenerics> = {
        members: {$in: [client.userID ?? ""]},
        last_message_at: {$exists: true},
    };

    const sort: ChannelSort = { last_message_at: -1 };

    try {
        setSearching(true);
        setQuery(query);

        const channels = await client.queryChannels(filters,sort,{
            state: true,
            watch: true,
        });

        const filteredChannels = channels.filter((channel) =>
            channel.state.members &&
            Object.values(channel.state.members).some(
                (member) => member.user?.name?.toLowerCase().includes(query)
            )
        );

        setResults(filteredChannels);
    } catch (error) {
        console.error("Error querying channels:", error);
        setResults([]);
    } finally {
        setSearching(false);
    }
};
