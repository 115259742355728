import React from "react";
import {ReactComponent as CheckIcon} from "@assets/icons/check.svg";
import {ReactComponent as DoubleCheckIcon} from "@assets/icons/double-check.svg";


interface MessageStatusProps {
    isReadByPartner: boolean;
}

const MessageStatus = ({isReadByPartner}: MessageStatusProps) => {
    return (
        <span
            className="flex gap-2 items-center font-medium text-xs text-special-gray leading-[0.9rem] min-w-fit text-nowrap">
            {isReadByPartner ? (
                <DoubleCheckIcon className="w-[0.875rem] h-fit"/>
            ) : (
                <CheckIcon className="w-[0.675rem] h-fit"/>
            )}
        </span>
    );
};


export default MessageStatus;
