import React from 'react';
import {StepOnBoarding} from "../models/interfaces/step-on-boarding.interface";


const OnboardingStep: React.FC<StepOnBoarding> = (props: StepOnBoarding) => {
    const {description, subHeader, header, illustration} = props

    return (
        <div className='w-full flex justify-center'>
            <section className="flex flex-col items-center max-w-md space-y-6">
                <header className='space-y-10 w-full'>
                    <img src={illustration} alt='Illustration' className="max-w-[328px] sm:max-w-sm mx-auto max-h-[300px]"/>
                    <h2 className="text-[2rem] leading-[2.5rem] font-bold">{header}</h2>
                </header>
                <div className='space-y-4'>
                    <p className="font-semibold text-xl leading-7">{subHeader}</p>
                    <p className="text-special-gray text-sm leading-6">{description}</p>
                </div>
            </section>
        </div>
    );
}

export default OnboardingStep;
