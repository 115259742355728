import {DataCmpAb} from "../models/types/data-cmp-ab.type";
import {DEFAULT_DATA_CMP_AB} from "../constants/default-data-cmp-ab";
import {DataCmpCodeSrc} from "../models/types/data-cmp-code-src.type";
import {DEFAULT_DATA_CMP_CODE_SRC} from "../constants/default-data-cmp-code-src";
import {Snippets} from "../models/interfaces/snippets.interface";
import {DEFAULT_DATA_CMP_CDN} from "../constants/default-data-cmp-cdn";
import {DEFAULT_DATA_CMP_HOST} from "../constants/default-data-cmp-host";
import {DEFAULT_CMP_SRC} from "../constants/default-cmp-src";

/**
 * Function to generate a ConsentManager script snippet
 *
 * @param src - The URL of the ConsentManager script.
 *              Typically points to the CDN location of the `semiautomatic.min.js` script.
 * @param cdid - The unique ID for the ConsentManager configuration (CDID).
 *               This is used to identify the specific ConsentManager setup.
 * @param dataCmpHost - The host server for CMP delivery. Defaults to `d.delivery.consentmanager.net`.
 *                      Specifies the backend service responsible for ConsentManager functionality.
 * @param dataCmpCdn - The CDN used for loading ConsentManager assets. Defaults to `cdn.consentmanager.net`.
 * @param dataCmpCodeSrc - Determines how the CMP code is sourced. Defaults to `DEFAULT_DATA_CMP_CODE_SRC`.
 *                         Controls the configuration of the ConsentManager code delivery.
 * @param dataCmpAb - A/B testing identifier for ConsentManager. Defaults to `DEFAULT_DATA_CMP_AB`.
 *                    Used to enable or manage specific A/B testing configurations within CMP.
 *
 * @returns A string containing the JavaScript snippet to initialize ConsentManager.
 */
export const getConsentManagerScript = (
    src: string = DEFAULT_CMP_SRC,
    cdid: string,
    dataCmpHost: string = DEFAULT_DATA_CMP_HOST,
    dataCmpCdn: string = DEFAULT_DATA_CMP_CDN,
    dataCmpCodeSrc: DataCmpCodeSrc = DEFAULT_DATA_CMP_CODE_SRC,
    dataCmpAb: DataCmpAb = DEFAULT_DATA_CMP_AB,
): Pick<Snippets, 'cmpScript'>['cmpScript'] => {
    return `
        (function(d) {
            var s = d.createElement('script');
            s.type = "text/javascript";
            s.setAttribute("data-cmp-ab", "${dataCmpAb}");
            s.src = "${src}";
            s.setAttribute("data-cmp-cdid", "${cdid}");
            s.setAttribute("data-cmp-host", "${dataCmpHost}");
            s.setAttribute("data-cmp-cdn", "${dataCmpCdn}");
            s.setAttribute("data-cmp-codesrc", "${dataCmpCodeSrc}");
            d.head.appendChild(s);
        })(document);
    `;
};
