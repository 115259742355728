import useAuth from "../hooks/use-auth";
import {useNavigate, useParams} from "react-router-dom";
import useEffectOnce from "../../common/hooks/use-effect-once";

const AdminLoginHandlerComponent = () => {
    const {handleAdminTokenLogin} = useAuth();
    const navigate = useNavigate();
    const {token} = useParams();

    const init = async () => {
        try {
            await handleAdminTokenLogin(token!);
            navigate("/app");
        } catch (error) {
            console.error("Login failed:", error);
            navigate("/");
        }
    }

    useEffectOnce(() => {
        (async () => {
            await init();
        })();
    })

    return null;
}
export default AdminLoginHandlerComponent;
