import React, {useContext, useEffect, useState} from "react";
import AppModal from "../../common/components/app-modal";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import useModalWithData from "../../common/hooks/use-modal-with-data";
import {DigitalProduct} from "../../core/models/interfaces/digital-product.interface";
import {useDigitalProductApi} from "../../core/hooks/use-digital-product-api";
import CardDigitalProduct from "../../digital-products/components/card-digital-product";
import BuyButton from "./buy-button";
import YourCurrentBalanceCard from "./ui/your-current-balance-card";
import Tooltip from "../../common/components/tooltip";
import {useWalletApi} from "../../core/hooks/use-wallet-api";
import DontHaveEnoughCoinsCard from "./ui/dont-have-enough-coins-card";
import Coin from "../../core/components/ui/coin";
import useAuth from "../../auth/hooks/use-auth";
import {useNavigate} from "react-router";
import { useAnalytics } from "../../core/hooks/use-analytics";
import { UserProfileContext, UserProfileContextProps } from "../../profile/contexts/user-profile-context";
import { PublicUserProfile } from "../../core/models/interfaces/public-user-profile.interface";

interface PurchaseProductModalProps {
    appendTo?: string | React.RefObject<HTMLElement> | null;
    ownerProduct?: string;
    modalPlace: "publicProfile" | "allProducts" | "productDetails";
    onSuccessBuyProduct?: (product: DigitalProduct) => void;
}

const PurchaseProductModal = (props: PurchaseProductModalProps) => {
    const [error, setError] = useState<string | null>(null);
    const {isOpenModal, hideModal, data, setCloseCallback} = useModalWithData<DigitalProduct>(ModalIds.PURCHASE_PROMPT);
    const product: DigitalProduct | null = data;
    const [buyerBalance, setBuyerBalance] = useState<number | null>(null);
    const [remainingBalance, setRemainingBalance] = useState<number | null>(null); // New state for remaining balance
    const {buyDigitalProduct} = useDigitalProductApi();
    const {getUserBalance} = useWalletApi();
    const {isAuthenticated} = useAuth();
    const navigate = useNavigate();
    const {sendPurchaseDigitalProductInfo} = useAnalytics();
    const {
        appendTo,
        ownerProduct = "Unknown owner",
        onSuccessBuyProduct,
        modalPlace,
    } = props;

    const {userProfile} = useContext(UserProfileContext) as UserProfileContextProps<PublicUserProfile>;;

    const handleCloseModal = () => {
        hideModal();
    };

    const navToDetails = () => {
        if (modalPlace == "publicProfile") {
            navigate("./" + product!.aliasName)
        } else if (modalPlace == "allProducts") {
            navigate("../" + product!.aliasName)
        } else if (modalPlace == "productDetails") {

        }
    }

    const handlePurchaseProduct = async () => {
        try {
            if ((buyerBalance !== null) && (buyerBalance >= product!.price!)) {
                if (product?.id) {
                    const newBalance = buyerBalance - product.price!;
                    setRemainingBalance(newBalance);
                    return await buyDigitalProduct(product.id).then(transactionId => {
                        handleCloseModal();
                        navToDetails();
                        const updatedProduct: DigitalProduct = {...product}
                        updatedProduct.owned = true;
                        onSuccessBuyProduct && onSuccessBuyProduct(updatedProduct);
                        sendPurchaseDigitalProductInfo(userProfile, product,transactionId);
                    });
                }
            } else {
                setError("Not enough yCoins");
            }
        } catch (err: any) {
            setError(err.response.data);
        }
    };

    const loadBuyerBalance = async () => {
        try {
            if (isAuthenticated) {
                const balance = await getUserBalance();
                setBuyerBalance(balance);
            }
        } catch (error: any) {
            setBuyerBalance(0);
        }
    };

    useEffect(() => {
        loadBuyerBalance();
    }, []);

    useEffect(() => {
        if (isOpenModal()) {
            setCloseCallback(ModalIds.PURCHASE_PROMPT, () => setError(null));
        }
    }, [isOpenModal]);

    return (
        <AppModal
            key={ModalIds.PURCHASE_PROMPT}
            id={ModalIds.PURCHASE_PROMPT}
            appendTo={appendTo}
            disableScroll={true}
            zIndex={30}
            classModal='md:min-w-[500px]'
        >
            {product && (
                <div className="flex flex-col w-full gap-3 bg-white ">
                    <span className="text-[0.625rem] text-primary font-semibold uppercase tracking-wider">
                        {ownerProduct} Digital Products
                    </span>
                    <h2 className="text-[1.375rem] leading-[1.65rem] font-bold mb-2">Buy product</h2>
                    <CardDigitalProduct product={product}/>

                    {!error && (
                        <div>
                            <YourCurrentBalanceCard isLoadingBalance={buyerBalance === null}
                                                    balance={buyerBalance ?? 0}/>
                            {remainingBalance !== null && remainingBalance >= 0 ? (
                                <span className="block text-xs leading-[0.9rem] font-medium text-special-gray mt-4">
                                    <Tooltip classNameIcon='relative top-[2px] mr-1'/>
                                    After you buy the product, you’ll be left with {remainingBalance} yC
                                </span>
                            ) : (
                                <span
                                    className="block text-xs leading-[0.9rem] font-medium text-special-gray mt-4 text-red-500">
                                    <Tooltip classNameIcon='relative top-[2px] mr-1'/>
                                    Not enough yCoins to complete the purchase
                                </span>
                            )}
                        </div>
                    )}

                    {error && <DontHaveEnoughCoinsCard/>}

                    <div className="flex justify-center mt-4">
                        <BuyButton
                            disabled={!!error}
                            onClick={handlePurchaseProduct}
                            label={
                                <div className='flex gap-1 items-center'>
                                    Buy: {product.price}
                                    <Coin/>
                                </div>
                            }/>
                    </div>
                </div>
            )}
        </AppModal>
    );
};

export default PurchaseProductModal;
