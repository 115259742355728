import {ChannelList, ChannelOrUserResponse} from "stream-chat-react";
import useAuth from "../../../auth/hooks/use-auth";
import React, {useEffect} from 'react';
import CustomChatItem from "../custom-chat-item";
import {useLocation} from 'react-router-dom';
import {useActiveChat} from "../../hooks/use-active-chat";
import AppHeader from "../../../../layout/app-header";
import AppHamburgerMenu from "../../../../layout/components/app-hamburger-menu";
import Breadcrumbs from "../../../../layout/components/breadcrumbs";
import SubmenuTab from "../../../core/components/submenu-tab";
import AppContentWrapper from "../../../../layout/components/app-content-wrapper";
import StreamChatService from "../../services/stream-chat.service";
import {useStreamChat} from "../../hooks/use-stream-chat-context";
import CustomSearchBar from "../custom-search-bar";
import {configLoadChannels} from "../../config/config-load-channels";
import {Channel} from "stream-chat";

const ChatList = () => {
    const {currentUser} = useAuth();
    const {client, unreadChannels,isConnected} = useStreamChat();
    const {setActiveChannel} = useActiveChat();
    const location = useLocation();
    const channelId = location.state?.channelId;

    const setChannelById = async () => {
        if (channelId && client?.userID) {
            const channel = getChannelById(channelId);
            if (channel && !channel.initialized) {
                await channel.watch();
            }
            setActiveChannel(channel);
        }
    };


    const handleSelectResult = (selectedResult: ChannelOrUserResponse) => {
        const channelId = selectedResult?.id;
        if (channelId && client) {
            const selectedChannel = getChannelById(channelId);
            setActiveChannel(selectedChannel!);
        }
    };

    const getChannelById = (channelId: string): Channel | null => {
        if (channelId && client) {
            return client.channel('messaging', channelId);
        }
        return null;
    };

    useEffect(() => {
        channelId && setChannelById();
    }, [channelId]);

    return (
        <div className='flex flex-col max-w-full min-w-[300px] w-full md:max-w-[400px] h-full flex-shrink-[2]'>
            <AppHeader customContent={true} className='!p-0' classNameBgDesktop='md:rounded-3xl h-full'>
                <div className='flex justify-between flex-wrap gap-5'>
                    <div className='flex flex-col gap-5'>
                        <div className='flex items-center w-fit'>
                            <div className='mr-3 lg:hidden'><AppHamburgerMenu/></div>
                            <Breadcrumbs path='Messages'/>
                        </div>
                        <SubmenuTab text='All messages' isActive={true} badge={unreadChannels?.channels.length}/>
                    </div>
                </div>
            </AppHeader>
            <AppContentWrapper className='pt-6 md:pt-4 !px-2 md:!px-0 bg-white rounded-t-[1.5rem] w-full relative [&>*]:!border-0 [&>*]:space-y-4 !overflow-y-auto'>
                {isConnected && (
                    <ChannelList
                        {...configLoadChannels(currentUser?.id)}
                        showChannelSearch={true}
                        additionalChannelSearchProps={{
                            searchFunction: StreamChatService.searchNonGroupChannels,
                            SearchBar: CustomSearchBar,
                            onSelectResult: (__, result) => {
                                handleSelectResult(result)
                            },
                            SearchResultItem: (props) => (
                                <CustomChatItem
                                    channel={props.result as Channel}
                                    key={props.result.id}
                                />
                            )
                        }}
                        Preview={(props) => (
                            <CustomChatItem
                                channel={props.channel}
                                key={props.channel.id}
                            />
                        )}
                    />
                )}
            </AppContentWrapper>
        </div>
    );
};

export default ChatList;
