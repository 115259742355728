import {ReactComponent as ReplyIcon} from "@assets/icons/reply.svg";
import {UniversalClickHandler} from "../../../common/models/types/universal-click-handler.type";

interface ReplyActionProps{
    onClick: UniversalClickHandler
}

const ReplyAction = ({onClick}: ReplyActionProps) => {

    const handleClick = () => {
        onClick();
    }

    return (
        <div onClick={handleClick}
             className='flex items-center gap-4 cursor-pointer rounded-2xl p-3 hover:bg-gray-light focus:bg-gray-light'>
            <ReplyIcon className="w-4 max-w-4 h-fit"/>
            <span className="font-semibold leading-[1.125rem] text-[0.9375rem] text-black">Reply</span>
        </div>
    )
}

export default ReplyAction
