import React, {createContext, useState} from "react";
import {GetUnreadCountAPIResponse} from "stream-chat";

interface UnreadChannelsContextValue {
    unreadChannels: GetUnreadCountAPIResponse | undefined;
    setUnreadChannels: (data: GetUnreadCountAPIResponse) => void;
}

export const UnreadChannelsContext = createContext<UnreadChannelsContextValue | null>(null);

export const UnreadChannelsProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [unreadChannels, setUnreadChannels] = useState<GetUnreadCountAPIResponse | undefined>(undefined);

    return (
        <UnreadChannelsContext.Provider value={{unreadChannels, setUnreadChannels}}>
            {children}
        </UnreadChannelsContext.Provider>
    );
};
