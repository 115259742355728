import AppHeader from "../../../layout/app-header";
import {ReactComponent as StarIcon} from '@assets/icons/star.svg';
import {ReactComponent as SupportIcon} from '@assets/icons/support.svg';
import {ReactComponent as HelpIcon} from '@assets/icons/help.svg';
import React from "react";
import SettingsHeader from "../components/settings-header";
import SupportCard from "../components/support-card";

const SupportView = () => {
    return (
        <>
            <SettingsHeader title='Support' className='mb-6 md:mb-10'/>
            <div className="md:overflow-y-auto md:rounded-3xl space-y-3">
                <AppHeader className='!p-0' classNameBgDesktop="overflow-hidden rounded-3xl" customContent={true}>
                    <SupportCard
                        icon={HelpIcon}
                        textButton='Open Help Center'
                        label="Help center"
                        title="Check out general questions"
                        description="Find answers to the most common questions about yurs."
                        linkUrl="https://intercom.help/yurs/"
                        className='!p-4 sm:!p-2 md:!p-0 text-primary-darken'
                        classNameDescription='!text-primary-darken'
                        classNameButton='!bg-primary !text-white'
                        classNameLabel='!text-primary'
                    />
                </AppHeader>

                <div className="grid w-full gap-3 grid-cols-1 @[54rem]:grid-cols-2 flex-grow">
                    <SupportCard
                        icon={SupportIcon}
                        textButton='Contact us'
                        label="Contact us"
                        description="If you need assistance, please don't hesitate to contact our support team."
                        title="Do you have a question?"
                        linkUrl="https://intercom.help/yurs/"
                    />
                    <SupportCard
                        icon={StarIcon}
                        textButton='Request a Feature'
                        label="Request a Feature"
                        description="Share your suggestions with us! We’re always looking to improve and value your input."
                        title="Got an idea to make yurs even better?"
                        linkUrl="https://intercom.help/yurs/"
                    />
                </div>
            </div>
        </>
    );
}

export default SupportView;
