import React, {BaseSyntheticEvent, forwardRef, MouseEvent, useEffect, useRef, useState} from "react";
import ButtonMessageOptions from "../ui/button-message-options";
import CustomReactionsMessage from "../custom-reactions-message";
import {StreamMessage, useChannelActionContext, useMessageContext} from "stream-chat-react";
import {useDynamicPositionByTrigger} from "../../../common/hooks/use-dynamic-position-by-trigger";
import ReplyAction from "../ui/reply-action";
import DeleteAction from "../ui/delete-action";
import {classNames} from "../../../../utils/class-names";
import {useMediaQuery} from "react-responsive";

interface MessageOptionsProps {
    message: StreamMessage | null;
    triggerRef: React.RefObject<HTMLElement>;
    onClose: () => void;
}

const MessageOptions = forwardRef<HTMLDivElement, MessageOptionsProps>((props, ref) => {
    const {message, onClose, triggerRef} = props;
    const optionsRef = useRef<HTMLDivElement | null>(null);
    const containerRef = useRef<HTMLElement | null>(null); // Referencja na kontener .str-chat__list
    const {isMyMessage, handleDelete} = useMessageContext();
    const {setQuotedMessage} = useChannelActionContext()
    const [showOptions, setShowOptions] = useState(false);
    const isMobile = useMediaQuery({maxWidth: 640});

    const position = useDynamicPositionByTrigger(
        true,
        triggerRef,
        optionsRef as React.RefObject<HTMLDivElement>,
        {
            preferredPositionY: "top",
            preferredPositionX: isMyMessage() ? "right" : "left",
            offset: isMobile ? -46 : 4,
            shift: 0,
            lockToPreferred: true,
        },
        containerRef
    );

    const handleSwapOptions = (e: MouseEvent<HTMLButtonElement> | undefined) => {
        e?.stopPropagation();
        setShowOptions(prev => !prev)
    }

    const replyMessageHandler = () => {
        message && setQuotedMessage(message)
        onClose();
    }

    const deleteMessageHandler = (event: BaseSyntheticEvent) => {
        handleDelete(event)
        onClose();
    }

    useEffect(() => {
        containerRef.current = document.querySelector(".str-chat__ul") as HTMLElement | null;
        return () => setShowOptions(false);
    }, []);

    return (
        <div
            className="w-full max-w-[328px] md:max-w-[367px]"
            ref={ref}
            style={{
                position: "absolute",
                top: position.top,
                left: position.left,
            }}
        >
            <div
                ref={optionsRef}
                className={classNames(
                    "z-[2] absolute bottom-0 left-0 right-0",
                    "flex gap-3 w-fit md:w-full h-fit max-w-[328px] md:max-w-[367px] flex-col md:flex-row",
                    isMyMessage() ? "justify-end !w-full items-end md:items-baseline" : "justify-start",
                )}
            >
                <div className={classNames(
                    "transition-all duration-300 ease-linear max-h-[232px] flex flex-col bg-white rounded-[2rem] shadow-drop max-w-[315px] w-fit overflow-hidden",
                    showOptions ? "max-h-[232px]" : "max-h-[56px]"
                )}>
                    <CustomReactionsMessage onSelect={onClose}/>
                    <div className='w-full h-fit px-2 pb-2 pt-1 border-t border-special-gray'>
                        <ReplyAction onClick={replyMessageHandler}/>
                        {isMyMessage() && <DeleteAction onClick={deleteMessageHandler}/>}
                    </div>
                </div>
                <ButtonMessageOptions isMyMessage={isMyMessage()} onClick={handleSwapOptions}
                                      isVisibleOptions={showOptions}/>
            </div>
        </div>
    );
});

export default MessageOptions;
