import React from 'react';
import AppButton from "../../common/components/app-button";
import {classNames} from "../../../utils/class-names";
import useRequiredLogin from "../../auth/hooks/use-required-login";
import {useModal} from "../../common/hooks/use-modal";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import {useNavigate} from "react-router-dom";

interface FollowButtonProps {
    isFollowed: boolean;
    onFollow: () => Promise<void>;
    onOpenChat: () => Promise<void>;
    disabled: boolean;
}

const FollowButton: React.FC<FollowButtonProps> = ({isFollowed, onFollow, onOpenChat, disabled}) => {
    const {openModal} = useModal();
    const checkLogin = useRequiredLogin({onLoginRequired: () => openModal(ModalIds.LOGIN_REQUIRED)});
    const navigate = useNavigate();

    //TODO REDRIECTION to chat with followed CC
    const handleClick = async (event?: React.MouseEvent) => {
        if (!checkLogin(event)) return;

        if (isFollowed) {
            const chatId = await onOpenChat();
            navigate("/app/messages", {state: {chatId}})
        } else {
            await onFollow();
        }
    };

    return (
        <div className="text-center">
            <AppButton
                className={classNames(
                    "w-full text-white text-sm py-[9px] h-fit rounded-2xl font-semibold bg-primary",
                    {
                        "bg-primary-pastel border-primary": isFollowed,
                    }
                )}
                onClick={() => handleClick()}
                label={isFollowed ? 'Send message' : 'Follow'}
                disabled={disabled}
            />
        </div>
    );
};

export default FollowButton;
