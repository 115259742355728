import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {XMarkIcon} from "@heroicons/react/20/solid";
import {useSidebar} from "../hooks/use-sidebar";
import {useMediaQuery} from "react-responsive";
import {SidebarIds} from "../types/sidebar-ids.interface";
import {classNames} from "../../utils/class-names";
import {animated, useTransition} from "@react-spring/web";

interface SidebarWrapperProps {
    children: React.ReactNode;
    id: SidebarIds;
    forceMobile?: boolean;
    classNameSidebar?: string;
    side: 'left' | 'right';
    appendTo?: HTMLElement;
}

const AppSidebarWrapper: React.FC<SidebarWrapperProps> = (props: SidebarWrapperProps) => {
    const {
        children,
        id,
        forceMobile = false,
        side,
        classNameSidebar,
        appendTo,
    } = props;

    const {isOpen, closeSidebar, registerSidebar} = useSidebar();
    const isDesktop = useMediaQuery({minWidth: 1024});
    const isMobileView = !isDesktop || forceMobile;

    const transitionsSidebar = useTransition(isOpen(id) || (isDesktop && !forceMobile), {
        from: {opacity: isMobileView ? 0 : 1},
        enter: {opacity: 1},
        leave: {opacity: 0},
        config: {tension: 200, friction: 30},
    });

    const transitionsBackdrop = useTransition(isOpen(id) && isMobileView, {
        from: {opacity: 0},
        enter: {opacity: isMobileView ? 0.2 : 0},
        leave: {opacity: 0},
        config: {tension: 200, friction: 30},
    });

    useEffect(() => {
        registerSidebar(id);
    }, [id]);

    const sidebarContent = (
        <div className={classNames(
            'flex',
            isMobileView && 'absolute inset-0',
        )}>
            {transitionsBackdrop((style, item) =>
                    item && (
                        <animated.div
                            style={style}
                            className={classNames(
                                isMobileView && "fixed inset-0 z-30 bg-special-gray"
                            )}
                        />
                    )
            )}
            {transitionsSidebar((style, item) =>
                    item && (
                        <animated.div
                            style={style}
                            className={classNames(
                                `z-40 flex w-full ${classNameSidebar}`,
                                side === 'right' && 'flex-row-reverse',
                                isMobileView && 'gap-3 p-3'
                            )}
                        >
                            <div className={classNames(
                                "bg-white lg:static lg:block z-40 h-full transition-all max-w-sm duration-300 w-fit",
                                isDesktop && !forceMobile && 'flex h-full',
                                isMobileView && 'relative top-0 left-0 rounded-3xl shadow-lg max-h-full w-full overflow-hidden',
                            )}>
                                {children}
                            </div>
                            {isMobileView && (
                                <button
                                    className="z-40 min-w-fit max-w-fit flex-1 w-fit h-fit mt-9 p-2 rounded-xl bg-white focus:outline-none "
                                    onClick={() => closeSidebar()}
                                >
                                    <XMarkIcon className="h-5 w-5 text-gray-600"/>
                                </button>
                            )}
                        </animated.div>
                    )
            )}
        </div>
    );

    return appendTo ? ReactDOM.createPortal(sidebarContent, appendTo) : sidebarContent;
};

export default AppSidebarWrapper;
