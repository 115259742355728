import {ReactComponent as ReplyIcon} from "@assets/icons/reply.svg"
import {ReactComponent as ExitIcon} from "@assets/icons/cross.svg"
import {useChannelActionContext, useChannelStateContext} from "stream-chat-react";


const MessageQuote = () => {
    const {setQuotedMessage} = useChannelActionContext();
    const {quotedMessage} = useChannelStateContext();

    const handleCancelReply = () => setQuotedMessage(undefined)

    return (
        <>
            {quotedMessage &&
                <div
                    className="bg-primary-pastel h-[40px] m-1 mb-0 rounded-2xl px-4 flex justify-between text-primary-darken font-semibold text-xs">
                    <div className="w-fit flex justify-start">
                        <ReplyIcon className="w-[10px] h-[8.71px] self-center mr-1"/>
                        <div className="self-center">
                            Reply to: "{quotedMessage.text}"
                        </div>
                    </div>
                    <ExitIcon onClick={handleCancelReply} className="w-2 h-2 self-center cursor-pointer"/>
                </div>
            }
        </>
    )
}
export default MessageQuote;
