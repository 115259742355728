import React from 'react';
import {ChannelHeaderProps} from 'stream-chat-react';
import VoiceCallButton from "./ui/voice-call-button";
import VideoCallButton from "./ui/video-call-button";
import MoreOptionsButton from "./ui/more-options-button";
import {useChatPartnerInfo} from "../hooks/use-chat-partner-info";
import {useActiveChat} from "../hooks/use-active-chat";
import {classNames} from "../../../utils/class-names";
import {useMediaQuery} from "react-responsive";
import {ReactComponent as ChevronLeftIcon} from "@assets/icons/chevron-left.svg";
import DefaultProfileImg from "../../common/components/default-profile-img";
import {ReactComponent as ChatIcon} from "@assets/icons/chat.svg"
import {ReactComponent as AudioIcon} from "@assets/icons/audio.svg"
import {ReactComponent as VideoIcon} from "@assets/icons/video.svg"
import ShopButton from './ui/shop-button';
import {ReactComponent as RightIcon} from "@assets/icons/chevron-right.svg"
import Avatar from "./ui/avatar";

const CustomChannelHeader = (props: ChannelHeaderProps) => {
    const {userAvatar, userName, lastActiveAt} = useChatPartnerInfo();
    const {isChatWithCC, setActiveChannel} = useActiveChat();
    const isDesktop = useMediaQuery({minWidth: 768});

    const goToCCProfile = () => {
        if (isChatWithCC) window.open(`/${userName}`, '_blank');
    }

    const goToCCShop = () => {
        if (isChatWithCC) window.open(`/${userName}/digital-products`, '_blank');
    }

    const goBackOnMobileHandler = () => setActiveChannel(null);

    return (
        <div className="custom-channel-header-wrapper">

            <div className={`custom-channel-header-left ${isChatWithCC && "cursor-pointer"}`}
                 onClick={goToCCProfile}
            >
                <div onClick={goBackOnMobileHandler} className="custom-channel-header-back-button">
                    <ChevronLeftIcon className="custom-channel-header-chevron-icon"/>
                </div>
                <Avatar className='mr-4' image={{name: `Avatar ${userName}`, contentBase64: userAvatar}} size="lg" isOnline={false}/>
                <div className="custom-channel-header-user-info">
                    <span className="custom-channel-header-user-name">{userName} <RightIcon
                        className="w-[4.69px] h-[8px] inline-block text-gray"/></span>
                    <div className="custom-channel-header-last-seen inline-block w-full md:w-fit">
                    <span
                        className="text-[10px] font-semibold bg-primary-pastel text-primary p-1 leading-none uppercase rounded-lg tracking-widest w-fit">
                        {isChatWithCC ?
                            "CREATOR"
                            :
                            "FAN"
                        }
                    </span>
                        <div
                            className='inline-block ml-0.5'>{lastActiveAt == "Just now" ? "Online" : `Last seen: ${lastActiveAt}`}</div>
                        {/* <div className='inline-block ml-3'>
                        <span><ChatIcon className='w-4 h-4 inline-block  ml-1 px-0.5'/> 15 / 30 </span>
                        <span><AudioIcon className='w-4 h-4 inline-block ml-1 px-0.5'/> 15 / 30 min.</span>
                        <span><VideoIcon className='w-4 h-4 inline-block ml-1 px-0.5'/> 15 / 30 </span>
                    </div> */}
                    </div>

                </div>

                {/* {!isDesktop && <MoreOptionsButton/>} */}
            </div>

            <div className="custom-channel-header-buttons">
                {/* <VoiceCallButton/>
                <VideoCallButton/> */}
                {isChatWithCC && <ShopButton onClick={goToCCShop}/>}
                {/* {isDesktop && <MoreOptionsButton/>} */}
            </div>


        </div>
    );
};

export default CustomChannelHeader;
