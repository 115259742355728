import React from "react";
import AppModal from "../../common/components/app-modal";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import useModalWithData from "../../common/hooks/use-modal-with-data";
import {useWalletApi} from "../../core/hooks/use-wallet-api";
import BuyButton from "./buy-button";
import {StripePrice} from "../../core/models/interfaces/stripe-price.interface";
import {ModalPosition} from "../../common/models/enums/modal-positions.enum";
import PaymentSecurityInfo from "./ui/payment-security-info";
import withAnyProvider from "../../common/hoc/with-any-provider";
import {PaymentMethodProvider} from "../contexts/payment-method-context";
import PaymentMethodSelector from "./payment-method-selector";
import {usePaymentMethod} from "../hooks/use-payment-method";
import {ProductPriceData, useDataStripePrice} from "../hooks/use-data-stripe-price";
import {PaymentMethodType} from "../../core/models/enums/payment-method-type.enum";
import {getPaymentMethodButtonName} from "../constants/payment-method-button-names";
import Coin from "../../core/components/ui/coin";

interface PurchaseYcModalProps {
    appendTo?: string | React.RefObject<HTMLElement> | null;
}

const PurchaseYcModal = (props: PurchaseYcModalProps) => {
    const {data} = useModalWithData<StripePrice>(ModalIds.PURCHASE_YC);
    const product: ProductPriceData | null = useDataStripePrice(data)
    const {appendTo} = props;
    const {createCheckoutSession} = useWalletApi();
    const {selectedMethod} = usePaymentMethod();

    const creatCheckoutSessionHandler = async () => {
        if (product?.stripeData && selectedMethod) {
            let stripeProductId;

            if (onlyPolishPayment) {
                stripeProductId = product.stripeData.plnPrice.id
            } else {
                stripeProductId = product.stripeData.id
            }

            await createCheckoutSession(stripeProductId, selectedMethod)
                .then(res => {
                    window.location.href = res.checkoutUrl;
                })
        }
    }

    const onlyPolishPayment = selectedMethod === PaymentMethodType.BLIK || selectedMethod === PaymentMethodType.P24

    return (
        <AppModal
            id={ModalIds.PURCHASE_YC}
            appendTo={appendTo}
            desktopPosition={ModalPosition.TOP_CENTER}
            disableScroll={true}
            zIndex={40}
            classModal='md:!min-w-[324px] md:!max-w-[562px]'
        >
            {product && (
                <div className="flex flex-col w-full gap-3 bg-white md:min-w-[460px] md:max-w-[460px] ">
                    <span className="text-[0.625rem] text-primary font-semibold uppercase tracking-wider">
                        Wallet
                    </span>
                    <h2 className="text-[1.375rem] leading-[1.65rem] font-bold mb-2">
                        Buy coins
                    </h2>
                    <PaymentSecurityInfo applyFullWidth={true}/>

                    <div className='mt-1'>
                        <PaymentMethodSelector
                            excludedMethods={product.originalPricePln ? [] : [PaymentMethodType.BLIK, PaymentMethodType.P24]}/>
                        {/*<SelectedPayment/>*/}
                    </div>

                    <div className="flex justify-center mt-4">
                        <BuyButton
                            onClick={creatCheckoutSessionHandler}
                            price=''
                            disabled={!selectedMethod}
                            label={
                                <div className='flex gap-1'>
                                    <span>Buy {product?.stripeData?.coins}</span>
                                    <Coin/>
                                    <span>
                                        {getPaymentMethodButtonName(selectedMethod)} ({onlyPolishPayment ? product.originalPricePln + 'PLN' : '$' + product.originalPrice})
                                    </span>
                                </div>
                            }/>
                    </div>
                </div>
            )}
        </AppModal>
    );
};

export default withAnyProvider(PaymentMethodProvider, PurchaseYcModal)
