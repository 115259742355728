import DigitalProductDetails from "../components/digital-product-details";
import React, {useContext, useEffect, useRef, useState} from "react";
import {
    DigitalProductDetails as IDigitalProductDetails
} from "../../core/models/interfaces/digital-product-details.interface";
import {useParams} from "react-router-dom";
import {useDigitalProductApi} from "../../core/hooks/use-digital-product-api";
import PurchaseProductModal from "../../purchases/components/purchase-product-modal";
import PublicProductActions from "../components/public-product-actions";
import AppWrapper from "../../../layout/components/app-wrapper";
import useAuth from "../../auth/hooks/use-auth";
import {classNames} from "../../../utils/class-names";
import {UserProfileContext, UserProfileContextProps} from "../../profile/contexts/user-profile-context";
import {PublicUserProfile} from "../../core/models/interfaces/public-user-profile.interface";
import {DigitalProduct} from "../../core/models/interfaces/digital-product.interface";
import {useAnalytics} from "../../core/hooks/use-analytics";

interface PublicDigitalProductDetailsViewProps {
    isPreviewMode?: boolean
}

const PublicDigitalProductDetailsView = (props: PublicDigitalProductDetailsViewProps) => {
    const {isPreviewMode = false} = props
    const [product, setProduct] = useState<IDigitalProductDetails | null>(null);
    const params = useParams();
    const {currentUser} = useAuth();
    const {getDigitalProduct} = useDigitalProductApi();
    const {
        updateProduct,
        digitalProducts,
    } = useContext(UserProfileContext) as UserProfileContextProps<PublicUserProfile>;
    const [ownerUserName, setOwnerUserName] = useState<string | null>(null);
    const parentRef = useRef<HTMLDivElement>(null);

    const {sendDigitalProductViewInfo} = useAnalytics();

    useEffect(() => {
        const aliasName = params['aliasName'];
        const username = params["username"] || currentUser?.username;
        if (username) setOwnerUserName(username);
        if (aliasName && username && !product) {
            getDigitalProduct(aliasName, username).then(res => {
                setProduct(res);
                sendDigitalProductViewInfo(res);
            })
        }
    }, [params]);

    const successBuyProductHandler = () => {
        if (product) {
            const digitalProductFromContext = digitalProducts.find(d => d.id === product.id)
            setProduct(prevState => ({...prevState!, owned: true}))
            if (digitalProductFromContext) {
                const updatedDigitalProductFromContext: DigitalProduct = {...digitalProductFromContext, owned: true}
                updateProduct(updatedDigitalProductFromContext);
            }
        }
    }

    return (
        <AppWrapper
            ref={parentRef}
            className={classNames(
                'p-0 text-wrap break-words w-full space-y-4 h-full',
                isPreviewMode ? "overflow-clip !mb-8" : "overflow-y-auto"
            )}>
            <DigitalProductDetails
                product={product}
                backEventPath="../"
            />
            {!isPreviewMode && (
                <PublicProductActions parentRef={parentRef} product={product} creatorUserName={ownerUserName ?? ""}/>
            )}
            <PurchaseProductModal modalPlace={"productDetails"} onSuccessBuyProduct={successBuyProductHandler}/>
        </AppWrapper>
    )
}

export default PublicDigitalProductDetailsView
