import {useEffect, useState} from "react";
import LocalStorageService from "../../common/services/local-storage.service";
import {LocalStorageKeys} from "../../common/models/enums/local-storage-keys.enum";
import NotificationService from "../../common/services/notification.service";
import useAuth from "../hooks/use-auth";
import useEffectOnce from "../../common/hooks/use-effect-once";
import {UseEmailTokenHandlerConfig} from "../../common/models/interfaces/use-email-token-handler-config.interface";

/**
 * Hook: useResetPasswordTokenHandler
 *
 * Manages reset password token actions with countdown timers:
 * - Sends the first token on mount (once per hour, configurable).
 * - Handles resend tokens (once per minute, configurable).
 * - Verifies tokens.
 *
 * Requires:
 * - LocalStorage entry for `email_reset_password` containing the user's email address.
 *
 * Config:
 * - resendDelay (number): Delay in seconds before the resend token action is available. Default: 60 seconds.
 * - firstTokenDelay (number): Delay in seconds before the first token can be sent again. Default: 3600 seconds.
 * - initSendToken (boolean): Determines if the first token is sent on mount. Default: true.
 *
 * Features:
 * - Countdown persistence using LocalStorage.
 * - Notifications for success or failure.
 *
 * Returns:
 * - resendCountdown (number): Seconds until the resend action is available.
 * - firstTokenCountdown (number): Seconds until the first token can be sent again.
 * - handleResendToken (function): Triggers the resend token action.
 * - handleVerifyToken (function): Verifies the provided token.
 */
const defaultConfig: UseEmailTokenHandlerConfig = {
    firstTokenDelay: 3600,
    resendDelay: 60,
    initSendToken: true,
};

const useResetPasswordTokenHandler = (config?: UseEmailTokenHandlerConfig) => {
    const {getTokenToResetPassword, verifyTokenToResetPassword} = useAuth();

    const {
        firstTokenDelay,
        resendDelay,
        initSendToken
    }: UseEmailTokenHandlerConfig = {...defaultConfig, ...config};

    const getUserTimers = (): Record<string, number> => {
        const savedTimers = LocalStorageService.get(LocalStorageKeys.TIMER_MAP);
        return savedTimers ? JSON.parse(savedTimers) : {};
    };

    const setUserTimers = (timers: Record<string, number>): void => {
        LocalStorageService.save(LocalStorageKeys.TIMER_MAP, JSON.stringify(timers));
    };

    const [resendCountdown, setResendCountdown] = useState(() => {
        const timers: Record<string, number> = getUserTimers();
        return timers[`reset_password_resendToken`]
            ? Math.max(Number(timers[`reset_password_resendToken`]) - Math.floor(Date.now() / 1000), 0)
            : 0;
    });

    const [firstTokenCountdown, setFirstTokenCountdown] = useState(() => {
        const timers: Record<string, number> = getUserTimers();
        return timers[`reset_password_firstToken`]
            ? Math.max(Number(timers[`reset_password_firstToken`]) - Math.floor(Date.now() / 1000), 0)
            : 0;
    });

    const handleResendToken = async () => {
        if (resendCountdown === 0) {
            try {
                const email: string | undefined = LocalStorageService.get(LocalStorageKeys.EMAIL_OR_USERNAME_RESET_PASSWORD);

                if (email) {
                    await getTokenToResetPassword(email);
                    setResendCountdown(resendDelay!);

                    const timers: Record<string, number> = getUserTimers();
                    timers[`reset_password_resendToken`] = Math.floor(Date.now() / 1000) + resendDelay!;
                    setUserTimers(timers);

                    NotificationService.success(
                        `Password reset token resent to ${email} successfully`,
                        "",
                        {duration: 10000}
                    );
                } else {
                    console.error("Email not found in LocalStorage under the key 'email_reset_password'. Unable to proceed with resending the token.");
                }
            } catch (error) {
                console.error("Failed to resend password reset token:", error);
                throw error;
            }
        }
    };

    const sendFirstToken = async () => {
        if (firstTokenCountdown === 0) {
            try {
                const email: string | undefined = LocalStorageService.get(LocalStorageKeys.EMAIL_OR_USERNAME_RESET_PASSWORD);

                if (email) {
                    await getTokenToResetPassword(email);
                    setFirstTokenCountdown(firstTokenDelay!);

                    const timers: Record<string, number> = getUserTimers();
                    timers[`reset_password_firstToken`] = Math.floor(Date.now() / 1000) + firstTokenDelay!;
                    setUserTimers(timers);

                    NotificationService.success(
                        `The code to reset the password sent to your email. Check your email and go through the instructions.`,
                        "",
                        {
                            duration: 10000,
                            waitForPrevious: true,
                        }
                    );
                } else {
                    console.error("Email not found in LocalStorage under the key 'email_reset_password'. Unable to proceed with sending the token.");
                }
            } catch (error) {
                console.error("Failed to send initial password reset token:", error);
                throw error;
            }
        }
    };

    const handleVerifyToken = async (token: string) => {
        try {
            const emailOrUsername = LocalStorageService.get(LocalStorageKeys.EMAIL_OR_USERNAME_RESET_PASSWORD);
            await verifyTokenToResetPassword(token, emailOrUsername);
            NotificationService.success(`Password reset token verified successfully.`);
        } catch (error) {
            console.error("Failed to verify password reset token:", error);
            throw error;
        }
    };

    useEffect(() => {
        let resendTimer: NodeJS.Timeout;
        if (resendCountdown > 0) {
            resendTimer = setInterval(() => setResendCountdown((prev) => prev - 1), 1000);
        }
        return () => clearInterval(resendTimer);
    }, [resendCountdown]);

    useEffect(() => {
        let firstTokenTimer: NodeJS.Timeout;
        if (firstTokenCountdown > 0) {
            firstTokenTimer = setInterval(() => setFirstTokenCountdown((prev) => prev - 1), 1000);
        }
        return () => clearInterval(firstTokenTimer);
    }, [firstTokenCountdown]);

    useEffectOnce(() => {
        initSendToken && sendFirstToken();
    });

    return {
        resendCountdown,
        firstTokenCountdown,
        handleResendToken,
        sendFirstToken,
        handleVerifyToken,
    };
};

export default useResetPasswordTokenHandler;
