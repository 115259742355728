import {DateSeparatorProps} from "stream-chat-react";
import {formatDateForSeparator} from "../../helpers/format-date-for-seperator";

const DateSeparator = ({date}: DateSeparatorProps) => {
    const formattedDate = formatDateForSeparator(new Date(date || Date.now()));

    return (
        <div className="my-4 pt-6 flex items-center p-2 gap-3">
            <div className="flex-grow border-t border-special-gray"></div>
            <span className="font-medium text-xs leading-[0.9rem] text-center text-special-gray">
                {formattedDate}
            </span>
            <div className="flex-grow border-t border-special-gray"></div>
        </div>
    );
};

export default DateSeparator
