import {FormProvider, useForm} from "react-hook-form";
import React from "react";
import useAuth from "../hooks/use-auth";
import {ResetPasswordFormBody} from "../models/interfaces/reset-email-body.interface";
import {yupResolver} from "@hookform/resolvers/yup";
import AppButton from "../../common/components/app-button";
import {SchemaResetPassword} from "../constants/schema-reset-password";
import PasswordFormField from "../../common/components/forms/password-form-field";

interface ResetPasswordFormProps {
    onSubmit: (newPassword: string) => Promise<void>
}

const ResetPasswordForm = (props: ResetPasswordFormProps) => {
    const {loading} = useAuth();
    const {onSubmit} = props

    const methods = useForm<ResetPasswordFormBody>({
        resolver: yupResolver(SchemaResetPassword),
        mode: "onBlur",
        delayError: 100,
        disabled: loading,
    });

    const handleResetPassword = async (): Promise<void> => {
        const body = methods.getValues();
        await onSubmit(body.newPassword)
    };

    return (
        <FormProvider {...methods}>
            <form className='flex flex-col justify-between md:justify-start gap-y-5 flex-grow'>
                <div className='space-y-3'>
                    <PasswordFormField name='newPassword' label='New password' placeholder='Write'/>
                    <PasswordFormField name='confirmPassword' label='Repeat new password' placeholder='Write'/>
                </div>
                <AppButton
                    type='submit'
                    loading={loading}
                    label='Set new password'
                    onClick={handleResetPassword.bind(this)}
                    className="w-full text-white text-sm py-[9px] h-fit !rounded-2xl font-semibold bg-primary"
                />
            </form>
        </FormProvider>
    );
}

export default ResetPasswordForm
