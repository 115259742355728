import "../../styles/coin.css";
import {ReactComponent as CoinIcon} from "@assets/icons/coin.svg"
import {classNames} from "../../../../utils/class-names";

interface CoinProps{
    className?: string,
}

const Coin = ({className}:CoinProps) => {
    return (
        <CoinIcon className={classNames("w-5 h-5",className)}/>
        // <div className="coin">
        //     {/*<div className="coin-ring-inner"></div>*/}
        //     <div className="coin-inner">
        //         <span className="coin-text y">Y</span>
        //         <span className="coin-text c">C</span>
        //     </div>
        // </div>
    );
}

export default Coin;
