import useEffectOnce from "../../common/hooks/use-effect-once";
import GoogleTagManager from "../google-tag-manager";

const UseInitGTM = () => {
    const GTM = new GoogleTagManager({
        id: process.env.REACT_APP_GTM_ID!,
        dataLayer: window.dataLayer,
    })

    useEffectOnce(() => GTM.initGTM())
}

export default UseInitGTM
