import {useContext} from "react";
import {MessageOptionsContext} from "../contexts/message-options-context";

export const useMessageOptions = () => {
    const context = useContext(MessageOptionsContext);
    if (!context) {
        throw new Error("useMessageOptions must be used within a MessageOptionsProvider");
    }
    return context;
};
