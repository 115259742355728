import * as yup from "yup";

export const validateUsername = yup.string()
    .required('Username is required')
    .min(2, 'Username must be at least 2 characters long')
    .max(50, 'Username cannot exceed 50 characters')
    .matches(
        /^[a-zA-Z0-9_$-]+$/,
        'Username can only contain letters, numbers, underscores (_), dashes (-), and dollar signs ($)'
    )
    .test(
        'no-spaces',
        'Username cannot contain spaces',
        (value) => value ? !/\s/.test(value) : true
    )
