import React, {useEffect} from "react";
import AppWrapper from "../layout/components/app-wrapper";
import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import {UserProfileProvider} from "../features/profile/contexts/user-profile-context";
import PublicCcProfile from "../features/profile/views/public-cc-profile";
import {PublicUserProfile} from "../features/core/models/interfaces/public-user-profile.interface";
import useAuth from "../features/auth/hooks/use-auth";
import AppTopBarAnnouncement from "../layout/components/app-top-bar-announcement";
import AppSidebarWrapper from "../layout/components/app-sidebar-wrapper";
import {SidebarIds} from "../layout/types/sidebar-ids.interface";
import AppMenu from "../layout/app-menu";
import {SidebarProvider} from "../layout/contexts/sidebar-context";
import PublicDigitalProductDetailsView from "../features/digital-products/views/public-digital-product-details-view";
import PublicProfileAllDigitalProductsView
    from "../features/digital-products/views/public-profile-all-digital-products-view";
import FollowRequiredModal from "../features/core/components/follow-required-modal";

const AppPublicProfilePage = () => {
    const params = useParams();
    const {currentUser, isAuthenticated} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser?.username.toLowerCase() === params['username']?.toLowerCase()) {
            navigate('/app/my-profile')
        }
    });

    if (!isAuthenticated) {
        return (
            <UserProfileProvider<PublicUserProfile>
                username={params['username']}
                initFetch={true}>
                <Routes>
                    <Route index element={
                        <AppWrapper className='!overflow-visible md:p-4 text-wrap break-words'>
                            <PublicCcProfile/>
                        </AppWrapper>
                    }/>
                    <Route path='/:aliasName' element={<PublicDigitalProductDetailsView/>}/>
                    <Route path='/digital-products/' element={<PublicProfileAllDigitalProductsView/>}/>
                </Routes>
            </UserProfileProvider>
        )
    }

    return (
        <SidebarProvider>
            <div className={'w-full bg-white h-svh flex flex-col'}>
                <AppTopBarAnnouncement/>
                <div className='w-full flex h-full'>
                    <AppSidebarWrapper id={SidebarIds.MENU} side='left' classNameSidebar='lg:max-w-sm'>
                        <AppMenu/>
                    </AppSidebarWrapper>
                    <UserProfileProvider<PublicUserProfile>
                        username={params['username']}
                        initFetch={true}>
                        <Routes>
                            <Route index element={
                                <AppWrapper
                                    className='md:p-4 text-wrap break-words w-full flex-grow flex flex-col overflow-y-auto h-full'>
                                    <PublicCcProfile/>
                                </AppWrapper>
                            }/>
                            <Route path='/:aliasName' element={<PublicDigitalProductDetailsView/>}/>
                            <Route path='/digital-products/' element={<PublicProfileAllDigitalProductsView/>}/>
                        </Routes>
                    </UserProfileProvider>
                </div>
            </div>
        </SidebarProvider>
    )
}

export default AppPublicProfilePage
