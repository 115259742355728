import React from 'react';
import VerifyTokenResetPasswordForm from "../../components/send-token-reset-password-form";
import useResetPasswordTokenHandler from "../../hooks/use-reset-password-token-handler";

interface SendTokenResetPasswordViewProps {
    onSuccess: () => void
}

const VerifyTokenResetPasswordView: React.FC<SendTokenResetPasswordViewProps> = (props: SendTokenResetPasswordViewProps) => {
    const {onSuccess} = props;
    const {resendCountdown, handleResendToken, handleVerifyToken} = useResetPasswordTokenHandler({
        initSendToken: false,
    });

    const resendTokenHandler = async (): Promise<void> => await handleResendToken();
    const verifyTokenHandler = async (token: string): Promise<void> => {
        try {
            await handleVerifyToken(token);
            onSuccess && onSuccess();
        } catch (error: any) {
            console.error(error);
        }
    }

    return (
        <div className='max-h-full flex flex-col h-full md:h-fit'>
            <p className="mb-6 text-special-gray text-sm md:text-base leading-[1.05rem] md:leading-6 font-medium">
                Forgot your password? No worries! Our password recovery
                feature makes it simple to regain access to your account
                in just a few easy steps.
            </p>
            <VerifyTokenResetPasswordForm
                resendCountdown={resendCountdown}
                onResend={resendTokenHandler}
                onSubmit={verifyTokenHandler}/>
        </div>
    );
};

export default VerifyTokenResetPasswordView;
