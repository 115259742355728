import useStepper from "../../common/hooks/use-stepper";
import {RegistrationSteps} from "../../core/models/enums/registration-steps.enum";
import useAuth from "./use-auth";
import {useUserApi} from "../../core/hooks/use-user-api";
import {useEffect} from "react";
import {AuthenticatedUser} from "../models/interfaces/authenticated-user.interface";

const useRegistrationSteps = () => {
    const {currentUser, updateAuthenticationField} = useAuth();
    const {updateRegistrationStep} = useUserApi();
    const numberOfSteps = Object.keys(RegistrationSteps).length;
    const steps = Object.values(RegistrationSteps);
    const {currentStep: currentStepIndex, nextStep, isLastStep, goToStep} = useStepper({
        initialStep: ((currentUser?.registrationStep! || 1) - 1),
        maxSteps: numberOfSteps,
    });
    const currentStep = steps[currentStepIndex];

    const handleNextStep = async () => {
        try {
            await updateRegistrationStep(currentStep + 1);
            nextStep();
        } catch (err: any) {
            console.error(err)
        }
    }

    const handleGoToStepBy = async (stepIncrement: number) => {
        try {
            let targetStep = currentStep + stepIncrement;

            if (targetStep > steps.length) {
                targetStep = steps.length;
            } else if (targetStep < 1) {
                targetStep = 1;
            }

            await updateRegistrationStep(targetStep);
            goToStep(targetStep - 1);
        } catch (err: any) {
            console.error(err);
        }
    };

    const moveToLastStep = async () => {
        try {
            const lastStepValue = steps.length;

            await updateRegistrationStep(lastStepValue);

            goToStep(lastStepValue - 1);
        } catch (err: any) {
            console.error(err);
        }
    };

    useEffect(() => {
        const updatedUser: AuthenticatedUser = {
            ...currentUser!,
            registrationStep: currentStep,
        }
        updateAuthenticationField("user", updatedUser);
    }, [currentStepIndex]);

    return {
        currentStep,
        isLastStep,
        nextStep: handleNextStep,
        moveStepBy: handleGoToStepBy,
        moveToLastStep
    };
};

export default useRegistrationSteps;
