import {Channel} from "stream-chat";

/**
 * Helper funkcja, która zwraca ID partnera czatu z listy członków (members).
 * @param {Channel} channel - Obiekt kanału, z którego będziemy pobierać listę członków.
 * @param {string} userID - ID aktualnego użytkownika (czyli nadawcy wiadomości).
 * @returns {string | null} - Zwraca ID partnera lub null, jeśli nie znaleziono.
 */
export const getChatPartnerId = (userID: string, channel: Channel | null): string | undefined => {
    if (!channel) return undefined;
    const members = channel.state.members;
    const partner = Object.values(members).find((member) => member.user?.id !== userID);
    return partner ? partner.user?.id ?? undefined : undefined;
};
