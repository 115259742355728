import React, {useState} from "react";
import {FileDetails} from "../../../core/models/interfaces/digital-product-details.interface";
import FileHelper from "../../../../utils/file-helper";
import {useDigitalProductApi} from "../../../core/hooks/use-digital-product-api";
import {classNames} from "../../../../utils/class-names";
import SpinnerLoader from "../../../common/components/spinner-loader";
import Base64Helper from "../../../../utils/base64-helper";

interface FilesProductSectionProps {
    files: FileDetails[],
    canDownloadFile: boolean,
}

const FilesProductSection = (props: FilesProductSectionProps) => {
    const {files, canDownloadFile} = props;
    const {downloadFile} = useDigitalProductApi();
    const [loadingKey, setLoadingKey] = useState<number | undefined>(undefined);

    const handleDownloadFile = async (file: FileDetails) => {
        if (canDownloadFile && !loadingKey) {
            try {
                const fileName = file.fileName+"."+file.fileFormat.toLowerCase()
                if (file.id){
                    setLoadingKey(file.id)
                    await downloadFile(file.id).then(res => FileHelper.triggerFileDownload(res, fileName))
                }else {
                    const blob = Base64Helper.base64ToBlob(file.base64)
                    blob && FileHelper.triggerFileDownload(blob,fileName)
                }
            } finally {
                setLoadingKey(undefined)
            }
        }
    }

    return (
        <section className="space-y-4">
            <h2 className="text-lg font-semibold text-black leading-[1.35rem]">Files</h2>
            <ul className="space-y-1">
                {files.map((file: FileDetails, index: number) => (
                    <li
                        onClick={() => handleDownloadFile(file)}
                        key={index}
                        className={classNames(
                            "relative flex flex-col gap-1 p-2 bg-gray-light rounded-2xl",
                            canDownloadFile && "cursor-pointer"
                        )}>
                        <p className="font-semibold text-base leading-[1.2rem] text-black first-letter:uppercase">
                            {file.fileName}
                            {file.id}
                        </p>
                        <span className="text-xs leading-[0.9rem] font-medium text-special-gray">
                            {FileHelper.getReadableFileSize(file.fileSize)}, {file.fileFormat}
                        </span>
                        {loadingKey === file.id && (
                            <div
                                className='absolute left-0 top-0 h-full w-full bg-[rgba(0,0,0,0.1)] flex items-center justify-center rounded-2xl'>
                                <SpinnerLoader color='var(--primary)'/>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </section>
    )
}

export default FilesProductSection
