import AppRoutes from "./app-routes";
import useInitStreamChat from "./features/chat/hooks/use-init-stream-chat";
import React from "react";
import FollowRequiredModal from "./features/core/components/follow-required-modal";
import LoginRequiredModal from "./features/auth/components/login-required-modal";
import InstructionsInstallPwa from "./features/core/components/instructions-install-pwa";
import NotificationPrompt from "./features/common/components/notification/notification-prompt";
import MetaUpdater from "./features/core/components/meta-updater";
import useInitGtm from "./features/google-analytics/hooks/use-init-gtm";
import useInitCmp from "./features/google-analytics/hooks/use-init-cmp";


const AppInitDependencies = () => {
    useInitCmp();
    useInitGtm();
    useInitStreamChat();

    return <>
        <MetaUpdater/>
        <AppRoutes/>
        <FollowRequiredModal/>
        <LoginRequiredModal/>
        <InstructionsInstallPwa/>
        <NotificationPrompt/>
    </>
}

export default AppInitDependencies
