import {usePwaInstallFlow} from "../../common/hooks/use-pwa-install-flow";
import {XMarkIcon} from "@heroicons/react/20/solid";

export const InstructionsInstallPwa = () => {
    const {instructions, hideInstructions} = usePwaInstallFlow();

    if (!instructions) return null;

    return (
        <div
            className='z-[1090] fixed left-0 top-0 w-full shadow-lg bg-indigo-400 text-white p-5 flex justify-between gap-3 text-sm'>
            <div>
                <p className='mb-2'>
                    We apologize for any inconvenience. Automatic installation of our application is not supported by your browser or operating system.
                </p>
                <div>
                    <span>{instructions?.header}</span>
                    <ol className='list-disc ml-4'>
                        {instructions?.steps.map((el, i) => (
                            <li key={i}>{el}</li>
                        ))}
                    </ol>
                </div>
            </div>
            <XMarkIcon onClick={hideInstructions} className='w-7 h-7 min-w-7 cursor-pointer'/>
        </div>
    )
}

export default InstructionsInstallPwa
