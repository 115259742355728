import React from "react";
import {useMediaQuery} from "react-responsive";
import {useSidebar} from "../hooks/use-sidebar";
import {SidebarIds} from "../types/sidebar-ids.interface";
import {ReactComponent as HamburgerIcon} from "@assets/icons/hamburger.svg"
import {classNames} from "../../utils/class-names";
import MessagesBadge from "../../features/core/components/badges/messages-badge";

interface AppHamburgerMenuProps{
    className?: string
}

const AppHamburgerMenu = ({className}: AppHamburgerMenuProps) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 });
    const { openSidebar } = useSidebar();

    return (
        <div className='z-[5] !text-black relative'>
            {!isDesktop && (
                <>
                    <button
                        onClick={() => openSidebar(SidebarIds.MENU)}
                        className={classNames(
                            "flex items-center justify-center w-10 h-10 p-2 rounded-2xl hover:!bg-gray-100 " +
                            "focus:outline-none",
                            className,
                        )}
                        style={{
                            background:'#e1d7fd',
                            color: 'var(--black)'
                        }}
                    >
                        <HamburgerIcon className='min-w-3 w-3 h-3'/>
                    </button>
                    <div className='absolute -top-[8px] -right-[8px]'>
                        <MessagesBadge/>
                    </div>
                </>
            )}
        </div>
    );
}

export default AppHamburgerMenu;
