import React from 'react';
import ResetPasswordForm from "../../components/reset-password-form";
import useAuth from "../../hooks/use-auth";
import LocalStorageService from "../../../common/services/local-storage.service";
import {LocalStorageKeys} from "../../../common/models/enums/local-storage-keys.enum";

interface ResetPasswordViewProps {
    onSuccess: () => void
}

const ResetPasswordView: React.FC<ResetPasswordViewProps> = (props: ResetPasswordViewProps) => {
    const {onSuccess} = props;
    const {resetPassword} = useAuth();
    const email = LocalStorageService.get(LocalStorageKeys.EMAIL_OR_USERNAME_RESET_PASSWORD)

    const handleResetPasswordSubmit = async (newPassword: string): Promise<void> => {
        try {
            await resetPassword(newPassword);
            onSuccess && onSuccess();
        } catch (error: any) {
            console.error(error);
        }
    };

    return (
        <div className="flex flex-col h-full bg-gray-100">
            <div className="flex flex-col flex-grow gap-y-3 bg-white rounded w-full justify-center">
                <p className="mb-3 text-sm md:text-base text-special-gray leading-[1.05rem] md:leading-6 font-medium">
                    {email && `For ${email}`}
                </p>
                <ResetPasswordForm onSubmit={handleResetPasswordSubmit}/>
            </div>
        </div>
    );
};

export default ResetPasswordView;
