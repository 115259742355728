import {ChannelFilters, ChannelOptions, ChannelSort} from "stream-chat";
import {DefaultStreamChatGenerics} from "stream-chat-react";
import {GetChannelsParameters} from "../models/interfaces/get-channels-parameters.interface";

export const configLoadChannels = (currentUserId: string | undefined): GetChannelsParameters => {
    const filters: ChannelFilters<DefaultStreamChatGenerics> = {
        type: "messaging",
        members: {$in: [currentUserId ?? ""]},
        last_message_at: {$exists: true},
    };

    const sort: ChannelSort<DefaultStreamChatGenerics> = {
        last_message_at: -1,
    };

    const options: ChannelOptions = {
        state: true,
        presence: true,
        watch: true,
    };

    return {filters, sort, options};
};
