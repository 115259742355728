import {useState} from 'react';
import ProfileApiService from "../services/profile-api.service";
import {UserProfile} from "../models/interfaces/user-profile.interface";
import {PublicUserProfile} from "../models/interfaces/public-user-profile.interface";
import {ProfileFormDataRequestBody, ProfileUserForm} from "../models/interfaces/profile-form-data.interface";
import Base64Helper from "../../../utils/base64-helper";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";

export const useUserProfileApi = () => {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const getUserProfile = async (): Promise<UserProfile> => {
        setLoading(true);
        setError(null);
        try {
            return await ProfileApiService.getUserProfile();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const getPublicCCUserProfile = async (username: string): Promise<PublicUserProfile> => {
        setLoading(true);
        setError(null);
        try {
            return await ProfileApiService.getPublicCCUserProfile(username);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const checkTypeUser = async (username: string): Promise<UserAuthority> => {
        setLoading(true);
        setError(null);
        try {
            return await ProfileApiService.getUserType(username);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const followCreator = async (creatorId: string,creatorUsername: string): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            await ProfileApiService.followCreator(creatorId,creatorUsername);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const unfollowCreator = async (username: string): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            await ProfileApiService.unfollowCreator(username);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const isCreatorFollowed = async (username: string): Promise<boolean> => {
        setLoading(true);
        setError(null);
        try {
            return await ProfileApiService.isCreatorFollowed(username);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const isContentCreatorOnline = async (username: string): Promise<boolean> => {
        setLoading(true);
        setError(null);
        try {
            return await ProfileApiService.isContentCreatorOnline(username);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const visitProfile = async (username: string): Promise<void> => {
        if (username) {
            setLoading(true);
            setError(null);
            try {
                await ProfileApiService.visitProfile(username);
            } catch (err: any) {
                setError(err.message);
                throw err;
            } finally {
                setLoading(false);
            }
        }
    };

    const updateProfile = async (formValues: ProfileUserForm): Promise<void> => {
        if (formValues) {
            setLoading(true);
            setError(null);
            try {
                const copyFormValues: ProfileUserForm = {...formValues}
                const mappedFormValues: ProfileFormDataRequestBody = {
                    backgroundImage: Base64Helper.removeBase64Prefix(copyFormValues.backgroundProfileImage ?? ''),
                    avatar: Base64Helper.removeBase64Prefix(copyFormValues.profileImage ?? ''),
                    personalData: {
                        firstname: copyFormValues.firstname || '',
                        username: copyFormValues.username || '',
                        bio: copyFormValues.bio || '',
                        lastname: copyFormValues.lastname || '',
                    },
                }
                const formData = prepareFormData(mappedFormValues);
                await ProfileApiService.updateProfile(formData);
            } catch (err: any) {
                setError(err.message);
                throw err;
            } finally {
                setLoading(false);
            }
        }
    };

    function prepareFormData(data: ProfileFormDataRequestBody): FormData {
        const formData = new FormData();

        const avatarBlob = Base64Helper.base64ToBlob(data.avatar + '', 'image/png')
        if (avatarBlob) {
            formData.append('avatar', avatarBlob);
        }

        const bgBlob = Base64Helper.base64ToBlob(data.backgroundImage + '', 'image/png')
        if (bgBlob) {
            formData.append('backgroundProfile', bgBlob);
        }

        formData.append(
            'request',
            new Blob([JSON.stringify(data.personalData)], {type: 'application/json'})
        );

        return formData;
    }

    return {
        getUserProfile,
        getPublicUserProfile: getPublicCCUserProfile,
        followCreator,
        unfollowCreator,
        isCreatorFollowed,
        isContentCreatorOnline,
        visitProfile,
        updateProfile,
        checkTypeUser,
        error,
        setError,
        loading,
        setLoading,
    };
};
