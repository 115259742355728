import WrapperCard from "../../common/components/wrapper-card";
import {classNames} from "../../../utils/class-names";
import {DigitalProduct} from "../../core/models/interfaces/digital-product.interface";
import Coin from "../../core/components/ui/coin";
import {PhotoIcon} from "@heroicons/react/24/solid";
import AppButton from "../../common/components/app-button";
import React, {useContext} from "react";
import {useModal} from "../../common/hooks/use-modal";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import useRequiredFollow from "../../core/hooks/use-required-follow";
import useRequiredLogin from "../../auth/hooks/use-required-login";
import useModalWithData from "../../common/hooks/use-modal-with-data";
import {Base64Prefix} from "../../common/constats/base64-prefix";
import {useNavigate} from "react-router-dom";
import {ReactComponent as CheckIcon} from "@assets/icons/check.svg";
import {UserProfileContext, UserProfileContextProps} from "../../profile/contexts/user-profile-context";
import {PublicUserProfile} from "../../core/models/interfaces/public-user-profile.interface";
import {DataFollowRequiredModal} from "../../core/components/follow-required-modal";
import { useAnalytics } from "../../core/hooks/use-analytics";

interface PreviewDigitalProductProps {
    product: DigitalProduct;
    creatorUsername: string;
    disableBuy?: boolean;
    productImage?: string,
    disableDetails?: boolean,
    allItemsView?: boolean,
    creatorId: string,
    // imgBase64: string,
}

const PreviewDigitalProductToBuy = (props: PreviewDigitalProductProps) => {
    const {
        name,
        price,
        description,
        productPicture,
        id,
        aliasName,
        owned,
    } = props.product;
    const {
        userProfile,
        changeStateFollowed,
    } = useContext(UserProfileContext) as UserProfileContextProps<PublicUserProfile>;
    const navigate = useNavigate();
    const {openModal} = useModal();
    const {showModal} = useModalWithData<DigitalProduct>(ModalIds.PURCHASE_PROMPT);
    const {showModal: showModalFollow} = useModalWithData<DataFollowRequiredModal>(ModalIds.FOLLOW_REQUIRED);
    const checkLogin = useRequiredLogin({onLoginRequired: openModal.bind(this, ModalIds.LOGIN_REQUIRED)});
    const checkFollow = useRequiredFollow(props.creatorId, {
        onFollowRequired: showModalFollow.bind(this, {
            creatorId: props.creatorId,
            onSuccessFollow: () => changeStateFollowed(true),
            creatorUsername: props.creatorUsername,
        }),
    });
    const {sendCheckoutStartDigitalProductInfo} = useAnalytics();

    const handleBuyDigitalProduct = async (event?: React.MouseEvent) => {
        if (!props.disableBuy) {
            if (!checkLogin()) return;
            const isAllowed = await checkFollow(event);
            if (!isAllowed) return;
            showModal(props.product);
            sendCheckoutStartDigitalProductInfo(userProfile!, props.product);
        }
    };

    const goToDetails = (aliasName: string) => {
        if (!props.disableDetails) {
            if (props.allItemsView) {
                navigate(`../${aliasName}`)
            } else {
                navigate(`./${aliasName}`)
            }
        }
    }

    return (
        <WrapperCard className={classNames(
            "w-full flex flex-col gap-3 !p-3"
        )}>
            <div className='flex justify-between gap-4'>
                <div className='flex flex-col gap-1 truncate'>
                    <span className='text-primary font-semibold text-xxs uppercase leading-3 tracking-[1px] truncate'>
                        {/*{DigitalProductCategoryService.getCategoryName(type)}*/}
                    </span>
                    <div className="text-primary  text-[10px] font-semibold ">
                        CATEGORY
                    </div>
                    <header className='font-semibold text-base'>
                        <h3 className='truncate'>{name}</h3>
                    </header>
                    <p className='text-gray font-medium text-[0.875rem] break-all text-wrap truncate line-clamp-3'>
                        {description || 'No description available'}
                    </p>
                </div>
                <div
                    className='min-w-[72px] w-[72px] h-[72px] border border-special-gray rounded-2xl bg-gray-light flex justify-center items-center'>
                    {
                        productPicture ?
                            <img
                                src={`${Base64Prefix}${productPicture}`}
                                alt="Avatar"
                                className="w-full h-full object-cover rounded-2xl"
                            />
                            :
                            <PhotoIcon className='w-5 h-5 text-gray'/>
                    }

                </div>
            </div>
            {owned ?
                <div className="pl-4 pr- bg-gray-light rounded-2xl h-[40px] flex justify-between">

                    <div className="flex text-gray pt-2">
                        <CheckIcon className="w-2.5 h-2 mt-2 mr-2"/>
                        <div className="font-semibold text-sm mt-0.5">You bought this product</div>
                    </div>
                    <div className="pt-0.5 ml-2">
                        <AppButton
                            label='Details'
                            onClick={() => goToDetails(aliasName)}
                            className={classNames(
                                '!border-0 underline text-gray text-sm font-semibold border-special-gray',
                                'hover:text-primary focus:text-primary',
                                props.disableDetails && "!cursor-not-allowed"
                            )}
                        />
                    </div>

                </div>
                :
                <div className='flex justify-between items-center'>
                    <div className='flex gap-1 font-bold text-lg leading-5'>
                        {price}<Coin/>
                    </div>
                    <div className='flex gap-3 '>
                        <AppButton
                            label='Details'
                            onClick={() => goToDetails(aliasName)}
                            className={classNames(
                                '!border-0 underline text-gray text-xs font-semibold border-special-gray',
                                'hover:text-primary focus:text-primary',
                                props.disableDetails && "!cursor-not-allowed"
                            )}
                        />
                        <AppButton
                            label='Buy'
                            onClick={handleBuyDigitalProduct}
                            className={classNames(
                                'text-gray text-[0.875rem] !rounded-2xl font-semibold border-special-gray',
                                'hover:text-white hover:bg-primary transition',
                                props.disableBuy && "!cursor-not-allowed"
                            )}
                        />
                    </div>
                </div>
            }

        </WrapperCard>
    )
}

export default PreviewDigitalProductToBuy
