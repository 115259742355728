import AppContentWrapper from "../layout/components/app-content-wrapper";
import React, {useRef} from "react";
import OwnProfile from "../features/profile/views/own-profile";
import {UserProfileProvider} from "../features/profile/contexts/user-profile-context";
import {UserProfile} from "../features/core/models/interfaces/user-profile.interface";
import {ProfileFormProvider} from "../features/profile/contexts/profile-form-context";
import useAuth from "../features/auth/hooks/use-auth";
import withAnyProvider from "../features/common/hoc/with-any-provider";
import {ProfileModeProvider} from "../features/profile/contexts/profile-mode-context";
import {useProfileMode} from "../features/profile/hooks/use-profile-mode";
import ProfileActions from "../features/profile/components/profile-actions";
import {UserAuthority} from "../features/auth/models/enums/user-authority.enum";
import PublicCcProfile from "../features/profile/views/public-cc-profile";
import PublicFanProfile from "../features/profile/views/public-fan-profile";
import {Navigate, Route, Routes} from "react-router-dom";
import PublicDigitalProductDetailsView from "../features/digital-products/views/public-digital-product-details-view";
import PublicProfileAllDigitalProductsView
    from "../features/digital-products/views/public-profile-all-digital-products-view";

const ProfilePage = () => {
    const {currentUser, hasAnyAuthorities} = useAuth();
    const {isPreviewMode} = useProfileMode();
    const ref = useRef<HTMLDivElement>(null);

    const isContentCreatorLogged = hasAnyAuthorities([UserAuthority.CONTENT_CREATOR])

    return (
        <AppContentWrapper
            ref={ref}
            className='bg-white lg:rounded-t-[1.5rem] max-w-full overflow-y-auto overflow-x-clip text-wrap break-words'>
            <UserProfileProvider<UserProfile>
                username={isContentCreatorLogged ? currentUser?.username : ''}
                initFetch={true}>
                <ProfileFormProvider>
                    {isPreviewMode ? (
                        isContentCreatorLogged ? (
                            <Routes>
                                <Route index element={<PublicCcProfile isPreviewMode={true}/>}/>
                                <Route path='/digital-products/' element={<PublicProfileAllDigitalProductsView isPreviewMode={true}/>}/>
                                <Route path='/:aliasName' element={<PublicDigitalProductDetailsView isPreviewMode={true}/>}/>
                                <Route path="*" element={<Navigate to="/not-found"/>}/>
                            </Routes>
                        ) : (
                            <PublicFanProfile isPreviewMode={true}/>
                        )
                    ) : (
                        <OwnProfile/>
                    )}
                    <ProfileActions parentRef={ref}/>
                </ProfileFormProvider>
            </UserProfileProvider>
        </AppContentWrapper>
    )
}

export default withAnyProvider(ProfileModeProvider, ProfilePage)
