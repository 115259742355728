import {ProfileBackgroundImageRepository} from "../repositories/profile-background-image-repository";
import {ProfileBackgroundDto} from "../models/dto/profileBackgroundDto";
import {ProfileImageDto} from "../models/dto/profileImageDto";

class ProfileBackgroundImageService {

    private repo = new ProfileBackgroundImageRepository()


    public async saveImage(value: string) {
        const val: ProfileBackgroundDto = {
            id: 1,
            imageValue: value
        }
        try {
            const existingImage = await this.getImage();
            if (existingImage) {
                await this.repo.updateEntity(val);
            } else {
                await this.repo.createEntity(val);
            }
        } catch (error) {
            console.error("Error saving image:", error);
        }
    }

    public async getImage() {
        return await this.repo.getAllEntities().then(res => res.length ? res[0] : null);
    }

    public async updateImage(value: string) {
        try {
            const val: ProfileBackgroundDto = {
                id: 1,
                imageValue: value
            };
            await this.repo.updateEntity(val);
        } catch (err: any) {
            throw new Error("Image not found for update");
        }
    }

    public async deleteImage() {
        const image = await this.getImage();
        if (image) {
            await this.repo.deleteEntity(image.id)
        }
    }
}

export default new ProfileBackgroundImageService()
