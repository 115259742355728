import React from "react";
import {SearchBarProps} from "stream-chat-react";
import {ReactComponent as SearchIcon} from "@assets/icons/search.svg";


const CustomSearchBar: React.FC<SearchBarProps> = (props: SearchBarProps) => {
    const {placeholder = "Search", onSearch} = props;

    return (
        <div className="custom-search-bar-wrapper">
            <SearchIcon className="custom-search-bar-icon"/>

            <input
                type="text"
                placeholder={placeholder}
                onChange={(e) => onSearch && onSearch(e)}
                className="custom-search-bar-input"
            />
        </div>
    );
};

export default CustomSearchBar;
