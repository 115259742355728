import ChangePasswordForm from "../../auth/components/change-password-form";
import SettingsHeader from "../components/settings-header";


const ChangePasswordView = () => {
    return (
        <>
            <SettingsHeader title='Change password' className='md:justify-center mb-10'/>
            <ChangePasswordForm/>
        </>
    )
}
export default ChangePasswordView;
