import {useChatContext} from 'stream-chat-react';
import {Channel, FormatMessageResponse} from "stream-chat";
import {useActiveChat} from "./use-active-chat";
import {TimestampHelper} from "../helpers/time-stamp-helper";
import {useEffect, useMemo, useState} from "react";
import {useMessageState} from "./use-message-state";

/**
 * Hook `useChatPartnerInfo` returns information about the chat partner (another user in the channel)
 * and the latest delivered message from them, based on the provided channel.
 */
export const useChatPartnerInfo = (channel?: Channel) => {
    const {client} = useChatContext();
    const {activeChannel: activeChannelContext} = useActiveChat();

    const activeChannel: Channel | null = channel || activeChannelContext;

    const [chatPartner, setChatPartner] = useState(() => {
        const members = activeChannel?.state.members || {};
        return Object.values(members).find((member) => member.user?.id !== client.userID) || null;
    });

    const lastDeliveredMessageByPartner = useMemo<FormatMessageResponse | null>(() => {
        return activeChannel?.state.messages
            .filter((msg) => msg.user?.id !== client.userID)
            .pop() || null;
    }, [activeChannel, chatPartner]);

    const lastDeliveredMessage = useMemo<FormatMessageResponse | null>(() => {
        return activeChannel?.lastMessage() || null;
    }, [activeChannel, chatPartner]);

    const lastActiveAt = useMemo(() => {
        return chatPartner?.user?.last_active
            ? TimestampHelper.getFormattedTimestamp(chatPartner.user.last_active)
            : '';
    }, [chatPartner]);

    const userName = useMemo(() => chatPartner?.user?.name ?? 'Unknown User', [chatPartner]);
    const userAvatar = useMemo(() => chatPartner?.user?.image as string ?? '', [chatPartner]);
    const isOnline = useMemo(() => chatPartner?.user?.online, [chatPartner]);

    const {isReadByPartner} = useMessageState(lastDeliveredMessage, activeChannel);

    const getLastMessageText = useMemo(() => {
        if (!lastDeliveredMessage) return "";
        const lastMessage = lastDeliveredMessage;

        if (lastMessage.attachments && lastMessage.attachments.length > 0) {
            const attachment = lastMessage.attachments[0];
            if (attachment.type === 'image') {
                return 'Sent a photo';
            }
            if (attachment.type === 'video') {
                return 'Sent a video';
            }
        }

        return lastMessage.text || '';
    }, [lastDeliveredMessage])

    useEffect(() => {
        const handlePresenceChange = (event: any) => {
            if (event.user.id === chatPartner?.user?.id) {
                setChatPartner(prev => ({
                    ...prev,
                    user: {
                        ...prev?.user,
                        id: event.user.id,
                        online: event.user.online,
                        last_active: event.user.last_active,
                    },
                }));
            }
        };

        client.on("user.presence.changed", handlePresenceChange);

        return () => {
            client.off("user.presence.changed", handlePresenceChange);
        };
    }, [chatPartner, client]);

    return useMemo(() => ({
        lastActiveAt,
        userName,
        userAvatar,
        isOnline,
        lastDeliveredMessage: lastDeliveredMessage,
        lastDeliveredMessageText: getLastMessageText,
        isReadByPartner,
    }), [lastActiveAt, userName, userAvatar, isOnline, lastDeliveredMessage, isReadByPartner, getLastMessageText]);
};
