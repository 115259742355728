import {TimestampHelper} from "../../helpers/time-stamp-helper";
import {classNames} from "../../../../utils/class-names";

interface MessageTimestampProps {
    timestamp?: Date | string;
}

export const MessageTimestamp: React.FC<MessageTimestampProps> = ({timestamp}) => {
    return (
        <span className={classNames(
            "font-medium text-xs leading-[0.9rem] text-special-gray text-right w-full",
        )}>
            {TimestampHelper.getFormattedTimestamp(timestamp)}
        </span>
    );
};
