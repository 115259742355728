import React, {useEffect} from 'react';
import { classNames } from "../../../utils/class-names";

interface StepIndicatorProps {
    totalSteps: number;
    currentStep: number;
    goToStep?: (step: number) => void;
    allowClick?: boolean;
}

const StepIndicator: React.FC<StepIndicatorProps> = ({ totalSteps, currentStep, goToStep, allowClick = false }) => {

    return (
        <div className={classNames('flex space-x-5 md:space-x-2 justify-center items-center py-2')}>
            {Array.from({ length: totalSteps }, (_, index) => (
                <div
                    key={index}
                    onClick={() => allowClick && goToStep && goToStep(index)}
                    className={classNames(
                        'rounded-full cursor-pointer w-1 h-1 p-[1px] border-solid ',
                        index === currentStep
                            ? 'bg-white w-3 h-3 border-[3px] border-primary'    // Aktualny krok
                            : index < currentStep
                                ? 'bg-primary border-2 border-transparent'    // Przejrzane kroki
                                : 'bg-primary-pastel border-2 border-transparent'   // Kroki jeszcze nieprzejrzane
                    )}
                    style={{ cursor: allowClick ? 'pointer' : 'default' }}
                ></div>
            ))}
        </div>
    );
};

export default StepIndicator;
