import { useMessageInputContext } from "stream-chat-react";
import { ReactComponent as AttachmentIcon} from "@assets/icons/attachment.svg"

const AttachmentUploadButton = () => {
  const { cooldownRemaining, uploadNewFiles, isUploadEnabled, maxFilesLeft } = useMessageInputContext();

  function handleChange(e: { currentTarget: { files: any; value: string; }; }) {
    const files = e.currentTarget.files;
    if (files && files.length > 0) {
      uploadNewFiles(files);
      e.currentTarget.value = "";
    }
  }

  return (
    <label className="outline-none bg-transparent font-inherit rounded-md cursor-pointer">
      <input type="file" className="w-0 h-0 pointer-events-none hidden" onChange={handleChange} multiple={true}/>
        <AttachmentIcon className="w-[18px] h-[18px] text-gray"/>
    </label>
  );
};
export default AttachmentUploadButton;