import {useEffect, useState} from 'react';
import {isChrome, isDesktop, isEdge, isIOS, isMobile, isOpera, isSafari} from 'react-device-detect';
import {environment} from "../../../environments/environment";
import {InstallInstruction} from "../models/interfaces/install-pwa-instruction.interface";
import {BeforeInstallPromptEvent} from "../models/interfaces/before-install-prompt-event.interface";
import LocalStorageService from "../services/local-storage.service";
import {LocalStorageKeys} from "../models/enums/local-storage-keys.enum";
import installInstructions from "../constats/install-instructions";


/**
 * usePwaInstallHandler hook
 *
 * `usePwaInstallHandler` manages the entire logic of installing a Progressive Web App (PWA).
 * This hook handles:
 * - Checking if the app is ready for installation (`beforeinstallprompt` event),
 * - Storing the `deferredPrompt` for later installation prompt,
 * - Executing the installation process (`installPWA`),
 * - Providing fallback manual installation instructions if the native prompt is unsupported.
 *
 * ### **Usage Note**
 * **To ensure correct functionality, `usePwaInstallHandler` must be initialized at the very beginning of the app, ideally within a top-level component or context, to capture the `beforeinstallprompt` event.**
 * If this hook is not activated early, the event may not be available later.
 *
 * ### State Variables and Functions
 * - `installPWA`: A function that triggers the installation prompt.
 * - `manualInstructions`: Provides alternative installation instructions for unsupported browsers.
 * - `isInstalled`: A boolean indicating if the PWA is already installed.
 *
 * ### Language Support
 * You can specify the language used for manual installation instructions by passing a language code
 * (`"en"` for English, `"pl"` for Polish) as the first argument to the hook. If no language is provided,
 * it defaults to `"en"`.
 *
 * ### Example Usage
 *
 * ```typescript
 * const {installPWA, manualInstructions, isInstalled } = usePwaInstallHandler("pl");
 * ```
 */
const usePWAInstallHandler = (language: "en" | "pl" = "en") => {
    const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
    const [isInstallable, setIsInstallable] = useState(false);
    const [isInstalled, setIsInstalled] = useState(false);
    const [manualInstructions, setManualInstructions] = useState<InstallInstruction | null>(null);

    useEffect(() => {
        const handler = (e: BeforeInstallPromptEvent) => {
            e.preventDefault();
            LocalStorageService.remove(LocalStorageKeys.PWA_IS_INSTALLED);
            setDeferredPrompt(e);
            setIsInstallable(true);

            if (!environment.production) console.log('mount beforeinstallprompt')
        };

        if (!deferredPrompt) window.addEventListener('beforeinstallprompt', handler);

        return () => {
            if (!environment.production) console.log('unmount beforeinstallprompt')
            window.removeEventListener('beforeinstallprompt', handler);
        };
    }, []);

    useEffect(() => {
        checkIfInstalled();
    }, [isInstallable])

    const installPWA = async () => {
        if (deferredPrompt) {
            deferredPrompt?.prompt();
            const {outcome} = await deferredPrompt?.userChoice;
            if (outcome === 'accepted') {
                setIsInstalled(true);
                LocalStorageService.save(LocalStorageKeys.PWA_IS_INSTALLED, true);
                if (!environment.production) console.log('User accepted install prompt PWA');
            } else {
                if (!environment.production) console.log('User rejected install prompt PWA');
            }
            setDeferredPrompt(null);
            setIsInstallable(false);
        } else {
            handleFallback();
        }
    };

    const checkIfInstalled = () => {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        const isIosStandalone = (window.navigator as any).standalone === true;
        const hasAlreadyInstalledPWA = LocalStorageService.get(LocalStorageKeys.PWA_IS_INSTALLED);

        if ((isStandalone || isIosStandalone) && !hasAlreadyInstalledPWA) LocalStorageService.save(LocalStorageKeys.PWA_IS_INSTALLED, true)
        setIsInstalled(isStandalone || isIosStandalone || hasAlreadyInstalledPWA);
    };

    const handleFallback = () => {
        setManualInstructions(getInstallInstructions())
    };

    const getInstallInstructions = () => {
        const isBrave = navigator.userAgent.includes("Brave");

        if (isIOS && !isSafari) return installInstructions[language].ios.notSafari;

        switch (true) {
            case isChrome && isDesktop:
                return installInstructions[language].chrome.desktop;
            case isChrome && isMobile:
                return installInstructions[language].chrome.mobile;
            case isEdge && isDesktop:
                return installInstructions[language].edge.desktop;
            case isEdge && isMobile:
                return installInstructions[language].edge.mobile;
            case isOpera && isDesktop:
                return installInstructions[language].opera.desktop;
            case isOpera && isMobile:
                return installInstructions[language].opera.mobile;
            case isBrave && isDesktop:
                return installInstructions[language].brave.desktop;
            case isBrave && isMobile:
                return installInstructions[language].brave.mobile;
            case isSafari && isDesktop:
                return installInstructions[language].safari.desktop;
            case isSafari && isMobile:
                return installInstructions[language].safari.mobile;
            default:
                return installInstructions[language].default;
        }
    };

    return {isInstalled, manualInstructions, setManualInstructions, installPWA};
};

export default usePWAInstallHandler;
