import {
    AttachmentPreviewList,
    useChannelListContext,
    useChannelStateContext,
    useMessageInputContext
} from "stream-chat-react";
import AttachmentUploadButton from "./attachment-upload-button";
import {EmojiPicker} from "stream-chat-react/emojis";
import EmojiIcon from "./emoji-icon";
import {useMediaQuery} from "react-responsive";
import {useEffect, useRef, useState} from "react";
import CustomSendButton from "./custom-send-button";
import MessageQuote from "./message-quote";

const CustomMessageInput = () => {
    const {text, handleChange, handleSubmit, numberOfUploads, parent, attachments} = useMessageInputContext();
    const {quotedMessage} = useChannelStateContext();
    const [isKeyboardOut, setIsKeyboardOut] = useState(false);
    const inputRef = useRef<HTMLTextAreaElement | null>(null);
    const shouldShowQuotedMessage = Boolean(quotedMessage) && quotedMessage?.parent_id === parent?.id;
    const isDesktop = useMediaQuery({minWidth: 768});

    const handleOnBlur = () => {
        setIsKeyboardOut(false);
    }

    const handleOnFocus = () => {
        if (!isDesktop) {
            setIsKeyboardOut(true);
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit();
        }
    };

    useEffect(() => {
        if (quotedMessage && inputRef.current) {
            inputRef.current.focus();
        }
    }, [quotedMessage]);

    return (
        <div className={`${!isKeyboardOut && !isDesktop && " px-4 pb-4"}`}>
            <div
                className={`input-shadow border bg-white  focus-within:border-blue-600  w-full flex flex-col  ${isKeyboardOut ? "rounded-none bottom-0 left-0 w-full" : "rounded-3xl"} `}>
                {shouldShowQuotedMessage && <MessageQuote/>}
                {numberOfUploads > 0 &&
                    <div className="p-1">
                        <AttachmentPreviewList/>
                    </div>
                }
                <div className="min-h-[48px] w-full flex "
                     onFocus={handleOnFocus}
                     onBlur={(e) => {
                         if (!e.currentTarget.contains(e.relatedTarget)) {
                             handleOnBlur();
                         }
                     }}>
                    <div className={`ml-4 self-center h-[18px]`}>
                        <EmojiPicker
                            popperOptions={{placement: "top-start"}}
                            pickerProps={{theme: "light"}} ButtonIconComponent={EmojiIcon}
                        />
                    </div>
                    <div
                        className="self-center w-full overflow-hidden relative text-[15px] leading-[18px] md:text-[16px] md:leading-[19.6px] h-fit ">
                        <textarea
                            ref={inputRef}
                            value={text}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            onInput={(e) => {
                                const lineHeight = isDesktop ? 19.6 : 18;
                                const maxHeight = isDesktop ? 80 : 72;
                                const target = e.target as HTMLTextAreaElement;
                                target.style.height = `${lineHeight}px`;
                                target.style.height = `${Math.min(target.scrollHeight, maxHeight)}px`;
                            }}
                            rows={1}
                            placeholder={"Type message"}
                            className="self-center no-scrollbar w-full border-0 outline-none bg-transparent resize-none px-4 pt-1"/>
                    </div>
                    <div className="self-center mr-4 flex">
                        {text || attachments.length > 0?
                        <div className="flex justify-right self-center">
                             <CustomSendButton sendMessage={handleSubmit}/>
                             <div className="self-center w-fit h-fit p-2 pr-0">
                                <AttachmentUploadButton/>
                            </div>
                        </div>
                            :
                            <AttachmentUploadButton/>
                        }
                    </div>
                </div>
            </div>
        </div>

    );
};


export default CustomMessageInput; 
