import React, {FormEvent, useEffect} from 'react';
import {FormProvider} from 'react-hook-form';
import NumberFormField from "../../common/components/forms/number-form-field";
import DescriptionFormField from "../../common/components/forms/description-form-field";
import CreditsConversionService from "../../purchases/services/credits-conversion.service";
import Tooltip from "../../common/components/tooltip";
import {useFormDigitalProduct} from "../hooks/use-form-digital-product.hook";
import {classNames} from "../../../utils/class-names";
import {extractDomain} from "../../../utils/extract-base-url";
import {environment} from "../../../environments/environment";
import {ReactComponent as LinkIcon} from "@assets/icons/link.svg";
import ProductImageUploader from "./product-image-uploader";
import MultiFileUploader from "./multi-file-uploader";
import {TempProductFile} from "../models/interfaces/product-file.interface";
import useAuth from "../../auth/hooks/use-auth";
import { v4 as uuidv4 } from 'uuid';

interface ProductFormProps {
    classNameForm?: string;
}

const ProductForm = (props: ProductFormProps) => {
    const {classNameForm} = props;
    const {currentUser} = useAuth();
    const {methods, loading, removeFile, addNewFiles, isSubmittingSuccessful} = useFormDigitalProduct();

    const urlToProduct = `${extractDomain(environment.backendUrl, 3000)}/${currentUser?.username}/${methods.getValues('aliasName')}`

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => e.preventDefault();
    const handlePriceChange = (e: any) => methods.setValue('price', parseInt(e.target.value, 10), {shouldDirty: true})
    const handleProductFilesChanges = (files: TempProductFile[]) => addNewFiles(files)
    const aa = methods.watch('productPicture')
    const handleProductPictureChange = async (file: File | null) => {
        /**
         * Creating new file as any type and add extend object by flag 'isNew' for make form control as dirty
         */
        const newFile: any = file ? new File([file], file.name || "", {type: file.type}) : null;
        newFile && (newFile.isNew = newFile.isNew = `new-${uuidv4()}`);
        methods.setValue('productPicture', newFile, {shouldDirty: true, shouldTouch: true, shouldValidate: true});
        await methods.trigger('productPicture');
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit} className={classNames(
                'flex flex-col gap-3',
                classNameForm && classNameForm
            )}>

                <ProductImageUploader
                    isSubmittingSuccessful={isSubmittingSuccessful}
                    isSubmitting={methods.formState.isSubmitting}
                    imageFile={methods.getValues('productPicture')}
                    label="Product cover"
                    onImageChange={handleProductPictureChange}
                />
                <div className='space-y-2'>
                    <DescriptionFormField isLoading={loading} maxLength={100} name="name" label="name"
                                          placeholder="Enter name"/>
                    <span className='flex gap-1 text-special-gray'>
                        <LinkIcon className='w-3 min-w-3 h-3'/>
                        <span className='truncate flex-shrink leading-[0.9rem] text-xs font-medium'>
                             {urlToProduct}
                        </span>
                    </span>
                </div>

                {/*<div className='text-right'>*/}
                {/*    <SelectFormField name='category' label='Category' />*/}
                {/*    <span className='text-special-gray font-semibold text-sm hover:text-primary-darken cursor-pointer'>*/}
                {/*        + Add new category*/}
                {/*    </span>*/}
                {/*</div>*/}

                <DescriptionFormField
                    isLoading={loading}
                    name="description"
                    maxLength={255}
                    label="Description"
                    placeholder="Enter description"/>

                <MultiFileUploader
                    isSubmittingSuccessful={isSubmittingSuccessful}
                    isSubmitting={methods.formState.isSubmitting}
                    errors={methods.formState.errors}
                    label='Files'
                    onRemoveFile={removeFile}
                    initialFiles={methods?.getValues('files') || []}
                    onFilesChange={handleProductFilesChanges}/>

                <div className='space-y-4'>
                    <header className='text-lg font-semibold'>Settings</header>
                    <div>
                        <span className='font-semibold text-[1rem]'>
                            Price
                            <Tooltip classNameIcon='relative top-[0.1rem] text-special-gray text-[0.85rem]'/>
                        </span>
                        <NumberFormField
                            isLoading={loading}
                            name="price"
                            label="Price for all fans"
                            isYcoin={true}
                            min={0}
                            onChange={handlePriceChange}/>

                        <span className="text-gray-500 text-xs text-gray mx-2">
                            Approximately {CreditsConversionService.convertYCreditsToDollarsWithCurrency(methods.watch('price'))}
                        </span>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};
export default ProductForm;
