import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {RedirectProvider} from "./features/auth/contexts/redirect-context";
import {ModalProvider} from "./features/common/contexts/modal-context";
import {DatabaseService} from './features/core/db/indexedDB.service';
import {UnreadChannelsProvider} from "./features/chat/contexts/unread-channels-context";
import {StreamChatProvider} from "./features/chat/contexts/stream-chat-context";
import {AuthProvider} from "./features/auth/contexts/auth-context";
import AppInitDependencies from "./app-init-dependencies";

const router = createBrowserRouter([
    {
        path: '*',
        element:
            <AuthProvider>
                <RedirectProvider>
                    <ModalProvider>
                        <UnreadChannelsProvider>
                            <StreamChatProvider>
                                <AppInitDependencies/>
                            </StreamChatProvider>
                        </UnreadChannelsProvider>
                    </ModalProvider>
                </RedirectProvider>
            </AuthProvider>
    },
]);

function App() {
    const databaseService = DatabaseService;

    return <RouterProvider router={router}/>;
}

export default App;
