import {ComponentType} from "../models/types/component.type";
import {classNames} from "../../../utils/class-names";
import {RefObject} from "react";


interface WrapperCardProps extends React.HTMLAttributes<HTMLDivElement> {
    children: ComponentType,
    className?: string,
    ref?: RefObject<HTMLDivElement>
}

const WrapperCard = (props: WrapperCardProps) => {
    const {children, className,id} = props;

    return (
        <div
            id={id}
            ref={props.ref}
            onClick={props.onClick}
            className={classNames(
            'w-fit h-fit rounded-3xl border border-special-gray p-4',
            className
        )}>
            {children}
        </div>
    )
}

export default WrapperCard
