import ApiService from "../../../axios/api.service";

class ChatApiService extends ApiService {
    public async createChannel(creatorUsername: string): Promise<{ channelId: string }> {
        return await this.post<{ channelId: string }>(`/chat/create/${creatorUsername}`, {}, {
            skipDefault404Handling: true,
            errorMessage: "Error creating channel"
        }).catch(error => {
            throw error;
        });
    }
}

export default new ChatApiService();
