import {useRef, TouchEvent, MouseEvent} from "react";
import {useMediaQuery} from "react-responsive";

interface UsePressActionOptions {
    delay?: number;
    onPress?: (event: TouchEvent<HTMLElement>) => void;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
}

/**
 * Hook `usePressAction`
 *
 * A simple React hook to handle both long press and click actions, designed for use on mobile and desktop devices.
 *
 * ### Behavior:
 * - **On mobile devices (`isMobile`):**
 *   - A long press (`onPress`) is triggered after the specified `delay` (default is 300ms).
 *   - Click actions (`onClick`) are ignored.
 * - **On desktop devices (`!isMobile`):**
 *   - Click actions (`onClick`) are triggered immediately when the mouse button is pressed down.
 *   - Long press actions are not supported.
 *
 * ### Parameters:
 * `UsePressActionOptions` defines the configuration options:
 * - `delay` (optional): The duration (in milliseconds) to wait before triggering the `onPress` action on mobile devices. Default is 300ms.
 * - `onPress` (optional): A callback function executed after a long press on mobile devices.
 * - `onClick` (optional): A callback function executed on a click action on desktop devices.
 *
 * ### Returns:
 * An object containing:
 * - `ref`: A React ref to be attached to the target element.
 * - `onTouchStart`: A handler for the `touchstart` event on mobile devices.
 * - `onTouchEnd`: A handler for the `touchend` event on mobile devices.
 * - `onMouseDown`: A handler for the `mousedown` event on desktop devices.
 * - `onMouseUp`: A handler for the `mouseup` event on desktop devices.
 */
export const usePressAction = (props: UsePressActionOptions) => {
    const {
        delay = 300,
        onPress,
        onClick,
    } = props;
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const elementRef = useRef<HTMLDivElement | null>(null);

    const isMobile = useMediaQuery({query: "(max-width: 768px)"});

    const handleStart = (event: MouseEvent | TouchEvent) => {
        if (isMobile) {
            timerRef.current = setTimeout(() => {
                if (onPress) onPress(event as TouchEvent<HTMLElement>);
            }, delay);
        } else {
            if (onClick) {
                onClick(event as MouseEvent<HTMLElement>);
            }
        }
    };

    const handleEnd = () => {
        if (timerRef.current) clearTimeout(timerRef.current);
    };

    return {
        ref: elementRef,
        onTouchStart: isMobile ? (event: TouchEvent) => handleStart(event) : undefined,
        onTouchEnd: isMobile ? handleEnd : undefined,
        onMouseDown: !isMobile ? (event: MouseEvent) => handleStart(event) : undefined,
        onMouseUp: !isMobile ? handleEnd : undefined,
    };
};
