import {ShoppingBagIcon} from "@heroicons/react/24/outline";
import AppButton from "../../../common/components/app-button";
import {useNavigate} from "react-router-dom";

const PublicDigitalProductsEmpty = () => {
    const navigate = useNavigate();
    const goToProfileCC = () => navigate("../")

    return (
        <section className='w-full flex flex-col items-center justify-center px-6 py-5 md:px-10 md:py-8 gap-5 rounded-3xl
            border border-special-gray border-dashed max-h-full max-w-lg mx-auto'>
            <header className='text-center space-y-2'>
                <h2 className='font-bold text-lg md:text-[1.75rem] leading-[1.35rem] md:leading-[2.1rem]'>
                    This creator has no products yet.
                </h2>
                <p className='text-special-gray text-xs leading-[0.9rem]'>
                    Check back later to see if new products become available.
                </p>
            </header>
            <AppButton onClick={goToProfileCC} label='Go to profile'
                       className='bg-secondary font-semibold text-sm border-0 !py-[10px]'/>
            <div className="w-24 p-6 bg-gray-light rounded-full">
                <ShoppingBagIcon className="w-full"/>
            </div>
        </section>
    )
}

export default PublicDigitalProductsEmpty
