import WrapperCard from "../../common/components/wrapper-card";
import {ReactComponent as WalletIcon} from "@assets/icons/wallet.svg";
import Coin from "../../core/components/ui/coin";
import CreditsConversionService from "../services/credits-conversion.service";
import AppButton from "../../common/components/app-button";
import Tooltip from "../../common/components/tooltip";
import {NavLink} from "react-router-dom";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import useAuth from "../../auth/hooks/use-auth";

interface YourBalanceWithWithdrawCardProps {
    balance: number,
}

const YourBalanceWithWithdrawCard = (props: YourBalanceWithWithdrawCardProps) => {
    const {hasAnyAuthorities} = useAuth();
    const showMoreInfo = hasAnyAuthorities([UserAuthority.CONTENT_CREATOR])
    const {balance} = props;

    return (
        <WrapperCard className='bg-gradient-to-r from-[#FFDE88] via-[#FFDE88] to-[#FFDE88] text-primary-darken
        flex justify-between w-full max-w-[300px] flex-wrap min-w-full md:min-w-fit'>
            <header className='flex flex-col gap-3 leading-none'>
                <div className='flex gap-2 items-center uppercase'>
                    <WalletIcon className='h-4 w-4 min-w-4'/>
                    <span className='font-semibold text-[10px] tracking-[1px]'>your balance</span>
                </div>
                <div>
                    <span className='font-bold text-2xl flex items-center gap-1 leading-7'>
                        {CreditsConversionService.formatNumberWithSpaceSeparator(balance)} <Coin/>
                    </span>
                    {showMoreInfo && (
                        <span className='font-medium text-[14px] leading-4'>
                        {CreditsConversionService.convertYCreditsToDollarsWithCurrency(balance)}
                    </span>
                    )}
                </div>
            </header>
            {showMoreInfo && (balance > 0) && (
                <div className='flex flex-col items-end justify-between gap-3 ml-auto'>
                    <NavLink to='/app/wallet/withdraw'>
                        <AppButton
                            label='Withdraw'
                            className='bg-white text-primary-darken font-semibold text-[14px] border-0 hover:bg-opacity-90'/>
                    </NavLink>
                    <div className='text-xs leading-4 whitespace-nowrap'>
                        <Tooltip classNameIcon='relative top-[2px]'/>
                        <span>Get money within 24h</span>
                    </div>
                </div>
            )}
        </WrapperCard>
    )

}

export default YourBalanceWithWithdrawCard
