import {useContext, useEffect, useMemo, useState} from "react"
import AppHeader from "../../../layout/app-header"
import AppContentWrapper from "../../../layout/components/app-content-wrapper"
import {useNavigate} from "react-router-dom"
import {PublicUserProfile} from "../../core/models/interfaces/public-user-profile.interface"
import {UserProfileContext, UserProfileContextProps} from "../../profile/contexts/user-profile-context"
import {DigitalProductCategories} from "../constants/categories-digital-products"
import {mapCategoriesWithBadges} from "../utils/map-categories-with-badges"
import SubmenuTab from "../../core/components/submenu-tab"
import PreviewDigitalProductToBuy from "../components/preview-digital-product-to-buy"
import AppButton from "../../common/components/app-button"
import {ChevronLeftIcon} from "@heroicons/react/24/solid"
import PurchaseProductModal from "../../purchases/components/purchase-product-modal";
import {classNames} from "../../../utils/class-names";
import PublicDigitalProductsEmpty from "../components/ui/public-digital-products-empty";
import {State, ViewStates} from "../../common/components/viewStates";
import SkeletonPreviewDigitalProductToBuy from "../components/ui/skeleton-preview-digital-product-to-buy";
import {ViewState} from "../../common/models/enums/view-state.enum";

interface PublicProfileAllDigitalProductsViewProps {
    isPreviewMode?: boolean
}

const PublicProfileAllDigitalProductsView = (props: PublicProfileAllDigitalProductsViewProps) => {
    const {isPreviewMode = false} = props
    const navigate = useNavigate();
    const {
        userProfile,
        digitalProducts,
        updateProduct,
        loading,
        error,
    } = useContext(UserProfileContext) as UserProfileContextProps<PublicUserProfile>;
    const [selectedCategory, setSelectedCategory] = useState("All");

    const handleCategoryChange = (category: string) => {
        setSelectedCategory(category);
    };

    const back = () => {
        navigate('../')
    }

    // const searchProducts = async (query: string): Promise<DigitalProduct[]> => {
    //     return fetchProducts().then(() => []);
    // };
    //
    // const handleSearchedProducts = (data: DigitalProduct[]) => {
    //     console.log(data)
    // }

    const categoriesWithCounts = mapCategoriesWithBadges(DigitalProductCategories, digitalProducts);

    return (
        <>
            <div className={classNames(
                "ml-auto mr-auto w-full max-h-full flex flex-col overflow-x-clip",
                isPreviewMode && "pb-24"
            )}>
                <AppHeader customContent={true} className={classNames(
                    isPreviewMode && "!p-0"
                )}>
                    <div className='flex justify-between flex-wrap gap-5 pt-5'>
                        <div className='flex flex-col gap-5 w-full'>
                            <div className='flex items-center w-full'>
                                {/* <div className='mr-3'><AppHamburgerMenu/></div> */}
                                <AppButton
                                    type="button"
                                    label=""
                                    className="bg-[#e1d7fd] text-gray w-10 h-10 !rounded-2xl border-0 mr-3"
                                    onClick={back}
                                >
                                    <ChevronLeftIcon className="w-4 h-4 text-primary-darken"/>
                                </AppButton>
                                <span
                                    className='font-bold text-2xl lg:text-[40px] text-primary-darken whitespace-nowrap'>
                                Digital Products
                            </span>
                            </div>
                            <div className='overflow-y-clip overflow-x-auto flex gap-2 cursor-pointer'>
                                {categoriesWithCounts.map((category) => (
                                    <SubmenuTab
                                        badge={category.badge}
                                        key={category.badge}
                                        text={category.name}
                                        isActive={true}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </AppHeader>
                <AppContentWrapper className='p-4 bg-white rounded-t-[1.5rem] sm:pt-9 h-fit'>
                    <ViewStates loading={loading} data={digitalProducts} error={error}>
                        <State state={ViewState.LOADING}>
                            <div className={classNames(
                                "grid grid-cols-1 sm:grid-cols-2 gap-4",
                                isPreviewMode && "pb-24"
                            )}>
                                {Array.from({length: 4}).map((value,index) => <SkeletonPreviewDigitalProductToBuy key={index}/>)}
                            </div>
                        </State>
                        <State state={ViewState.FETCHED}>
                            <div className='flex flex-col gap-3 pb-6'>
                                {/*<SearchInput onSearch={searchProducts} onResults={handleSearchedProducts}/>*/}

                                {/* <div className="flex items-center gap-2 my-4 border-b-2 border-gray-light">
                                {categoriesWithCounts.map((category) => (
                                    <button
                                        key={category.name}
                                        className={`relative top-[2px] flex items-center gap-2 p-1 text-sm font-semibold transition pb-2
                                    ${
                                            selectedCategory === category.name
                                                ? " text-black border-b-2 border-black"
                                                : " text-special-gray"
                                        }
                                `}
                                        onClick={() => handleCategoryChange(category.name)}
                                    >
                                        <span>{category.name}</span>
                                        {category.badge && (
                                            <span
                                                className={`flex items-center justify-center w-5 h-5 rounded-full text-xs font-semibold ${
                                                    selectedCategory === category.name ? "text-white bg-black" : "bg-gray-light text-special-gray"
                                                }`}
                                            >
                                    {category.badge}
                                </span>
                                        )}
                                    </button>
                                ))}
                            </div> */}

                                <div className={classNames(
                                    "grid grid-cols-1 sm:grid-cols-2 gap-4",
                                    isPreviewMode && "pb-24"
                                )}>
                                    {digitalProducts.map((product, index) => <PreviewDigitalProductToBuy
                                            disableDetails={false}
                                            disableBuy={isPreviewMode}
                                            product={product}
                                            key={index}
                                            creatorUsername={userProfile?.username + ''}
                                            allItemsView={true}
                                            creatorId={userProfile?.id!}
                                        />
                                    )}
                                </div>
                            </div>
                        </State>
                        <State state={ViewState.EMPTY}>
                            <PublicDigitalProductsEmpty/>
                        </State>
                    </ViewStates>
                </AppContentWrapper>
            </div>
            <PurchaseProductModal modalPlace="allProducts" onSuccessBuyProduct={updateProduct}/>
        </>
    )
}

export default PublicProfileAllDigitalProductsView;
