import ApiService, {CustomAxiosRequestConfig} from "../../../axios/api.service";
import {Authentication} from "../models/interfaces/authentication.interface";
import LocalStorageService from "../../common/services/local-storage.service";
import {LocalStorageKeys} from "../../common/models/enums/local-storage-keys.enum";
import {AuthenticationResponse} from "../models/interfaces/authentication-response.interface";
import {UserAuthority} from "../models/enums/user-authority.enum";
import {ResetPasswordBodyRequest} from "../models/interfaces/reset-email-body.interface";
import {AuthenticatedUser} from "../models/interfaces/authenticated-user.interface";
import {ChangePasswordBodyRequest} from "../models/interfaces/change-password-body.interface";
import NotificationService from "../../common/services/notification.service";
import {RegisterBody} from "../models/interfaces/register-body.interface";
import ProfileImageService from "../../core/db/services/profile-image-service";
import ProfileBackgroundImageService from "../../core/db/services/profile-background-image-service";
import {AuthenticatedUserResponse} from "../models/interfaces/authenticated-user-response-interface";
import {firebaseApp, getFirebaseToken, messagingFirebaseApp} from "../../../firebase/firebase";
import {firebaseVapidKey} from "../../../firebase/firebaseConfig";
import {deleteToken} from "firebase/messaging";

class AuthService extends ApiService {

    private authentication: Authentication | null = this.loadAuthenticationFromLocalStorage();
    private cachedProfileImage: string | null = null;
    private cachedBackgroundProfileImage: string | null = null;
    private imageCacheListeners: (() => void)[] = [];
    private isImagesLoading: boolean = false;
    private onLogoutCallback: (() => void) | null = null;

    constructor() {
        super();
        this.loadImages();
    }

    public async authenticate(email: string, password: string): Promise<string> {
        const authHeader: string = 'Basic ' + btoa(`${email}:${password}`);
        const httpOptions: CustomAxiosRequestConfig = {
            headers: {
                'Authorization': authHeader
            },
            params: {
                frontend: 'application',
            },
            errorMessage: "Invalid login or password.",
            skipDefault401Handling: true,
        };

        if (!this.isAuthenticated()) {
            this.authentication = null;
            LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);
        }

        return await this.post<AuthenticationResponse>('/authenticate', {}, httpOptions)
            .then(async (res: AuthenticationResponse): Promise<AuthenticationResponse> => {
                await ProfileImageService.saveImage(res.user.profileImage);
                return res;
            })
            .then(async (res: AuthenticationResponse): Promise<AuthenticationResponse> => {
                await ProfileBackgroundImageService.saveImage(res.user.backgroundProfileImage);
                return res;
            })
            .then((res: AuthenticationResponse): string => {
                this.authentication = this.prepareAuthentication(res);
                LocalStorageService.save(LocalStorageKeys.AUTHENTICATION, JSON.stringify(this.authentication));
                this.loadImages();
                return "Success"
            })
            .catch(err => {
                throw err
            })
    }

    public async getRequestAndSendNotificationToken() {
        let token: string = "";

        const requestPermission = async () => {
            try {
                navigator.serviceWorker.ready.then(async (serviceWorker) => {
                    const permission = await Notification.requestPermission();
                    if (permission === "granted") {
                        token = await getFirebaseToken(messagingFirebaseApp, {
                            serviceWorkerRegistration: serviceWorker,
                            vapidKey: firebaseVapidKey,
                        });
                        await this.subscribeNotifications(token);
                    } else if (permission === "denied") {
                        alert("You denied the notification permission.");
                    }
                });
            } catch (error) {
                console.error("Error getting token or subscribing: ", error);
            }
        };

        await requestPermission();
    }

    public async getAuthenticatedUser(): Promise<AuthenticatedUser> {
        return await this.get<AuthenticatedUser>(`/authenticated-user`, {})
    }

    public async registerAccount(body: RegisterBody): Promise<void> {
        return await this.post<void>(`/users/registration`, body, {})
            .then((res) => {
                NotificationService.success('Account has been successfully registered.')
            })
    }

    public async handleAdminTokenLogin(token: string): Promise<AuthenticatedUserResponse | void> {
        const httpOptions: CustomAxiosRequestConfig = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            successMessage: "You have been logged in as the selected user!",
            errorMessage: "Login as the selected user failed.",
            skipDefault401Handling: true,
        };

        try {
            if (this.isAuthenticated()) {
                this.authentication = null;
                LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);
            }

            const response: AuthenticatedUserResponse = await this.get<AuthenticatedUserResponse>('/authenticated-user', httpOptions)

            if (response) {
                let now: Date = new Date();
                now.setMinutes(now.getMinutes() + 30);
                const validUntil: Date = new Date(now);
                const tokenValidInMinutes: number = Math.round((validUntil.getTime() - Date.now()) / 60000);
                const authentication: AuthenticationResponse = {
                    user: response,
                    token: token,
                    tokenValidInMinutes: tokenValidInMinutes
                };
                this.authentication = this.prepareAuthentication(authentication);
                LocalStorageService.save(LocalStorageKeys.AUTHENTICATION, JSON.stringify(this.authentication));
                await ProfileImageService.saveImage(response.profileImage);
                await ProfileBackgroundImageService.saveImage(response.backgroundProfileImage);
                return response;
            }
        } catch (error: any) {
            throw error;
        }
    }

    public async sendEmailConfirmationToken(email: string): Promise<void> {
        return await this.post<void>('/send-email-confirmation-token', {email}, {
            skipDefault404Handling: true,
            skipDefault401Handling: true,
            skipDefaultErrorHandling: true,
        })
            .catch((err) => {
                NotificationService.error(
                    `Failed to send an activation link to the provided email address (${email}).`,
                    err,
                    "",
                    {duration: 7000}
                );
            });
    }

    public async activateAccount(token: string): Promise<void> {
        return await this.put<void>(`/confirm-email/${token}`, {}, {
            skipDefault404Handling: true,
            successMessage: 'The account has been activated!',
            errorMessage: "Failed to activate the account."
        });
    }

    public async getTokenResetPassword(email: string): Promise<void> {
        return await this.post<void>('/send-password-reset-token', {email}, {
            skipDefault401Handling: true,
            skipDefault404Handling: true,
            isPublic: true,
            errorMessage: "Failed to send a reset code to the provided email address."
        })
            .then((): void => {
                LocalStorageService.remove(LocalStorageKeys.EMAIL_OR_USERNAME_RESET_PASSWORD);
                LocalStorageService.save(LocalStorageKeys.EMAIL_OR_USERNAME_RESET_PASSWORD, email);
            });
    }

    public async sendTokenResetPasswordToVerify(token: string, emailOrUsername: string): Promise<void> {
        return await this.get<void>('/validate-token', {
            params: {
                tokenValue: token,
                emailOrUsername: emailOrUsername
            },
            skipDefault401Handling: true,
            skipDefault404Handling: true,
            isPublic: true,
            errorMessage: "Failed to verify the password reset token. Please check the token and try again.",
        })
            .then((): void => {
                LocalStorageService.remove(LocalStorageKeys.TOKEN_RESET_PASSWORD);
                LocalStorageService.save(LocalStorageKeys.TOKEN_RESET_PASSWORD, token);
            });
    }

    public async resetPassword(body: ResetPasswordBodyRequest): Promise<void> {
        return await this.put<void>('/reset-password', body, {
            isPublic: true,
            skipDefault401Handling: true,
            skipDefault404Handling: true,
            successMessage: "Your password has been reset! You can now log in.",
        })
            .then((): void => {
                LocalStorageService.remove(LocalStorageKeys.EMAIL_OR_USERNAME_RESET_PASSWORD);
                LocalStorageService.remove(LocalStorageKeys.TOKEN_RESET_PASSWORD);
            });
    }

    public async changePassword(body: ChangePasswordBodyRequest): Promise<void> {
        return await this.put<void>('/change-password', body, {
            skipDefault401Handling: true,
            skipDefault404Handling: true,
            successMessage: "Your password has been changed.",
        })
    }

    public get authenticatedUser(): AuthenticatedUser | undefined {
        if (!this.authentication?.user) return undefined;

        return {
            ...this.authentication.user,
            profileImage: this.cachedProfileImage || "",
            backgroundProfileImage: this.cachedBackgroundProfileImage || "",
        };
    }

    public async refreshImages(): Promise<void> {
        await this.loadImages();
    }

    public onImageCacheUpdated(callback: () => void) {
        this.imageCacheListeners.push(callback);
    }

    // Odsubskrybowanie zmian w cache
    public offImageCacheUpdated(callback: () => void) {
        this.imageCacheListeners = this.imageCacheListeners.filter(listener => listener !== callback);
    }

    // Wywołanie subskrybentów po odświeżeniu cache
    private notifyImageCacheUpdated() {
        this.imageCacheListeners.forEach(listener => listener());
    }

    private async loadImages(): Promise<void> {
        if (this.isImagesLoading) return;
        this.isImagesLoading = true;

        try {
            const profileBackgroundImage = await ProfileBackgroundImageService.getImage();
            const profileImage = await ProfileImageService.getImage();

            this.cachedProfileImage = profileImage?.imageValue || "";
            this.cachedBackgroundProfileImage = profileBackgroundImage?.imageValue || "";

            this.notifyImageCacheUpdated();
        } catch (error) {
            console.error("Error loading user images:", error);
        } finally {
            this.isImagesLoading = false;
        }
    }

    public async logout(): Promise<void> {
        try {
            await this.disconnectTokenFirebase();
            await ProfileImageService.deleteImage();
            await ProfileBackgroundImageService.deleteImage();
        } catch (error: any) {
            console.error(error);
        } finally {
            console.log("clear base")
            this.authentication = null;
            this.cachedProfileImage = null;
            this.cachedBackgroundProfileImage = null;
            LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);

            if (this.onLogoutCallback) {
                this.onLogoutCallback();
            }
        }
    }

    public setLogoutCallback(callback: (() => void) | null) {
        this.onLogoutCallback = callback;
    }

    public isAuthenticated(): boolean {
        return !!this.authentication && new Date(this.authentication.token.validUntil).getTime() > new Date().getTime();
    }

    public updateAuthenticationField<K extends keyof Authentication>(
        key: K,
        value: Authentication[K]
    ): void {
        if (this.authentication) {
            this.authentication = {
                ...this.authentication,
                [key]: value
            };
            LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);
            LocalStorageService.save(LocalStorageKeys.AUTHENTICATION, JSON.stringify(this.authentication));
        }
    }

    private loadAuthenticationFromLocalStorage(): Authentication | null {
        const authenticationStringJson: string | null = LocalStorageService.get(LocalStorageKeys.AUTHENTICATION);
        if (authenticationStringJson) {
            return JSON.parse(authenticationStringJson);
        } else {
            LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);
            return null;
        }
    }

    private disconnectTokenFirebase = async (): Promise<void> => {
        try {
            navigator.serviceWorker.ready
                .then(async (serviceWorker) => {
                    try {
                        const firebaseToken = await getFirebaseToken(messagingFirebaseApp, {
                            serviceWorkerRegistration: serviceWorker as any,
                            vapidKey: firebaseVapidKey,
                        });
                        await this.unsubscribeNotifications(firebaseToken);
                        await deleteToken({ app: firebaseApp });
                    } catch (error) {
                        console.error("Error during Firebase operations:", error);
                    }
                })
                .catch((err) => {
                    console.warn("Service Worker is not ready:", err);
                });
        } catch (error) {
            console.error("Error in disconnectTokenFirebase:", error);
        }
    };

    private async subscribeNotifications(token: string): Promise<void> {
        return await this.post<void>('notification/subscribe', {}, {
            params: {token},
            skipDefault404Handling: true,
        })
    }

    private async unsubscribeNotifications(token: string): Promise<void> {
        return await this.post<void>('notification/unsubscribe', {}, {
            params: {token},
            skipDefault404Handling: true,
        })
    }

    private prepareAuthentication(authenticationResponse: AuthenticationResponse): Authentication {
        return {
            user: {
                id: authenticationResponse.user.id,
                email: authenticationResponse.user.email,
                username: authenticationResponse.user.username,
                firstname: authenticationResponse.user.firstname,
                lastname: authenticationResponse.user.lastname,
                bio: authenticationResponse.user.bio,
                authority: authenticationResponse.user.authority as UserAuthority,
                pendingPromotion: authenticationResponse.user.pendingPromotion,
                chatToken: authenticationResponse.user.chatToken,
                profileImage: "",
                backgroundProfileImage: "",
                ccOnBoarding: authenticationResponse.user.ccOnBoarding,
                registrationStep: authenticationResponse.user.registrationStep,
                fanOnBoarding: authenticationResponse.user.fanOnBoarding,
                birthDate: authenticationResponse.user.birthDate,
                genderId: authenticationResponse.user.genderId,
                phoneNumber: authenticationResponse.user.phoneNumber,
                ccRequestSent: authenticationResponse.user.ccRequestSent,
                gotFreeCoinsMobileApp: authenticationResponse.user.gotFreeCoinsMobileApp,
            },
            token: {
                value: authenticationResponse.token,
                validUntil: this.prepareTokenValidUntil(authenticationResponse.tokenValidInMinutes)
            }
        }
    }

    private prepareTokenValidUntil(tokenValidInMinutes: number): Date {
        return new Date(new Date().getTime() + tokenValidInMinutes * 60 * 1000);
    }
}

export default new AuthService();
