import React, {ReactNode} from "react";
import {classNames} from "../../../utils/class-names";

interface BadgeProps {
    children: ReactNode;
    className?: string;
}

const Badge: React.FC<BadgeProps> = ({children, className}) => {
    return (
        <div
            className={classNames(
                "w-6 h-6 max-h-6 max-w-6 min-w-6 min-h-6 flex rounded-full bg-black text-white justify-center items-center leading-[0.9rem] text-xs font-semibold",
                className
            )}
        >
            {children}
        </div>
    );
};

export default Badge;
