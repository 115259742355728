import {DataLayer} from "../models/interfaces/data-layer.interface";
import {Snippets} from "../models/interfaces/snippets.interface";


/**
 * Function to get and set dataLayer
 * @param dataLayer - The dataLayer
 */
export const getDataLayerSnippet = (
    dataLayer: Pick<DataLayer, 'dataLayer'>['dataLayer'],
): Pick<Snippets, 'gtmDataLayer'>['gtmDataLayer'] =>
    `window.dataLayer = window.dataLayer || [];` +
    (dataLayer ? `window.dataLayer.push(${JSON.stringify(dataLayer)})` : '')
