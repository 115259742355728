export enum AnalyticsEventType {
    REGISTER = "register",
    VIEW_ITEM = "view_item",
    VIEW_ITEM_LIST = "view_item_list",
    VIEW_CREATOR = "view_creator",
    VIEW_PROMOTION = "view_promotion",
    SELECT_PROMOTION = "select_promotion",
    BEGIN_CHECKOUT = "begin_checkout",
    ADD_PAYMENT_INFO = "add_payment_info",
    PURCHASE = "purchase",
    NEWSLETTER_SENT = "newsletter_sent",
}
