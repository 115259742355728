import {FormProvider, useForm} from "react-hook-form";
import LogoutButton from "./logout-button";
import TextFormField from "../../common/components/forms/text-form-field";
import Tooltip from "../../common/components/tooltip";
import AppButton from "../../common/components/app-button";
import {yupResolver} from "@hookform/resolvers/yup";
import {schemaVerifyEmail} from "../constants/schema-verify-email";
import useAuth from "../hooks/use-auth";
import useVerifyEmailTokenHandler from "../hooks/use-verify-email-token-handler";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";

interface VerifyEmailFormProps {
    onSuccess?: () => Promise<void>;
}

const VerifyEmailForm = ({onSuccess}: VerifyEmailFormProps) => {
    const {activateAccount, currentUser, loading} = useAuth();
    const {resendCountdown, handleResendToken} = useVerifyEmailTokenHandler();
    const location = useLocation();
    const methods = useForm<{ token: string }>({
        resolver: yupResolver(schemaVerifyEmail),
        mode: "onSubmit",
    });

    const handleVerifyEmail = async () => {
        const isValid = await methods.trigger();
        if (!isValid) return;

        const {token} = methods.getValues();
        try {
            await activateAccount(token);
            onSuccess && (await onSuccess());
        } catch (error) {
            console.error("Email verification failed:", error);
        }
    };

    useEffect(() => {
        const params: URLSearchParams = new URLSearchParams(location.search);
        const codeFromUrl: string | null = params.get("token");

        if (codeFromUrl) {
            methods.setValue("token", codeFromUrl);
            handleVerifyEmail();
        }
    }, [location.search]);

    return (
        <FormProvider {...methods}>
            <form className="flex flex-col gap-y-6 p-4 max-w-md mx-auto h-full min-h-fit">
                <div className='flex-grow md:flex-grow-0 space-y-4'>
                    <div className="flex items-center gap-3 justify-between">
                        <h2 className="text-[1.75rem] md:text-[2.5rem] font-bold">Verify e-mail</h2>
                        <span className='inline-block md:hidden'><LogoutButton/></span>
                    </div>
                    <p className='font-semibold text-lg md:text-[1.375rem] leading-7'>
                        Now you need to check out your e-mail ({currentUser?.email}) and click on the link to verify it
                        or paste a verification code below:
                    </p>

                    <TextFormField disabled={loading} name="token" maxLength={6} label="Verfication code"
                                   placeholder="Write"/>

                    <div className='flex text-special-gray text-xs leading-3 gap-1'>
                        <Tooltip/>
                        <span>
                           Didn’t receive a code?
                            <AppButton
                                onClick={handleResendToken}
                                type='submit'
                                disabled={loading}
                                label=''
                                loaderColor='var(--primary)'
                                className='!p-0 border-none underline ml-1 cursor-pointer transition hover:text-primary focus:text-primary'>
                                Resend a code {!!resendCountdown && `(${resendCountdown})`}
                            </AppButton>
                        </span>
                    </div>
                </div>

                <AppButton
                    type='button'
                    loading={loading}
                    onClick={methods.handleSubmit(handleVerifyEmail)}
                    label='Verify e-mail'
                    className='w-full text-white text-sm py-[9px] h-fit font-semibold bg-primary rounded-2xl'/>
            </form>
        </FormProvider>
    );
}

export default VerifyEmailForm
