import React from "react";
import {useMessageContext} from "stream-chat-react";
import CustomMessageDeliveredProducts from "./custom-message-delivered-products";
import CustomMessageBasic from "./custom-message-basic";
import CustomMessageError from "./custom-message-error";


const CustomMessage: React.FC = () => {
    const {message} = useMessageContext();

    if (!message) return null;

    const isMessageWithDeliveredProduct = message["dp_message"];
    const isErrorMessage = message.type === "error";

    if (isMessageWithDeliveredProduct) {
        return <CustomMessageDeliveredProducts/>
    } else if (isErrorMessage) {
        return <CustomMessageError/>
    }

    return <CustomMessageBasic/>
};

export default CustomMessage;
