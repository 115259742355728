import {UnreadMessagesSeparatorProps} from "stream-chat-react";
import React from "react";

const UnreadMessagesSeparator: React.FC<UnreadMessagesSeparatorProps> = ({unreadCount}) => {
    if (!unreadCount || unreadCount <= 0) return null;

    return (
        <div className="flex items-center justify-center bg-white rounded-3xl p-1 gap-2">
            <span className="text-special-gray leading-[0.9rem] text-xs font-medium">Unread messages</span>
        </div>
    );
};

export default UnreadMessagesSeparator
