import {useRef, useEffect, RefObject} from "react";

interface UseDragToScrollConfig {
    enabled?: boolean;
    orientation?: "x" | "y";
    hideScrollbar?: boolean;
}

export const useDragToScroll = (
    targetRef: RefObject<HTMLElement>,
    config: UseDragToScrollConfig,
) => {
    const isDragging = useRef(false);
    const startPos = useRef(0);
    const scrollStart = useRef(0);

    const {enabled, orientation,hideScrollbar} = config;

    useEffect(() => {
        if (!targetRef.current || !enabled) return;
        const element = targetRef.current;

        element.style.overflow = "auto";
        if (orientation === "x") {
            element.style.overflowX = "auto";
            element.style.overflowY = "hidden";
        } else if (orientation === "y") {
            element.style.overflowY = "auto";
            element.style.overflowX = "hidden";
        }

        if (hideScrollbar) {
            element.classList.add("hide-scrollbar");
        }

        return () => {
            element.style.overflow = "";
            element.style.overflowX = "";
            element.style.overflowY = "";
            if (hideScrollbar) {
                element.classList.remove("hide-scrollbar");
            }
        };
    }, [targetRef, enabled, orientation]);

    const onMouseDown = (e: any) => {
        if (!enabled || !targetRef.current) return;
        isDragging.current = true;

        startPos.current = orientation === "x" ? e.pageX : e.pageY;
        scrollStart.current =
            orientation === "x"
                ? targetRef.current.scrollLeft
                : targetRef.current.scrollTop;

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e: any) => {
        if (!isDragging.current || !targetRef.current || !enabled) return;
        e.preventDefault();

        const currentPos = orientation === "x" ? e.pageX : e.pageY;
        const delta = currentPos - startPos.current;

        if (orientation === "x") {
            targetRef.current.scrollLeft = scrollStart.current - delta;
        } else {
            targetRef.current.scrollTop = scrollStart.current - delta;
        }
    };

    const onMouseUp = () => {
        isDragging.current = false;

        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
    };

    return {
        onMouseDown,
        onMouseMove,
        onMouseUp,
    };
};
