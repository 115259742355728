interface AdditionalInfoUpperMessageProps {
    info: string;
}

const AdditionalInfoUpperMessage = (props: AdditionalInfoUpperMessageProps) => {
    return (
        <span className='flex items-center h-[12px] text-special-gray font-medium text-[0.563rem]'>
            {props.info}
        </span>
    )
}

export default AdditionalInfoUpperMessage
