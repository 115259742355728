import {RefObject, useEffect, useState} from 'react';

interface PositionOptions {
    preferredPositionY?: 'top' | 'bottom';
    preferredPositionX?: 'left' | 'right';
    offset?: number;
    shift?: number;
    lockToPreferred?: boolean;
}

/**
 * `useDynamicPositionByTrigger` Hook
 *
 * Dynamically calculates the position of a target element relative to a trigger element.
 *
 * ### Parameters:
 *
 * - **`shouldUpdatePosition`** (`boolean`):
 *   Enables or disables position updates.
 *
 * - **`triggerRef`** (`React.RefObject<HTMLElement>`):
 *   Reference to the trigger element.
 *
 * - **`targetRef`** (`React.RefObject<HTMLElement>`):
 *   Reference to the element to position.
 *
 * - **`options`** (`object`, optional):
 *   Configuration options for positioning:
 *   - **`preferredPositionY`** (`"top" | "bottom"`, default: `"bottom"`):
 *     Preferred vertical alignment relative to the trigger.
 *   - **`preferredPositionX`** (`"left" | "right"`, default: `"right"`):
 *     Preferred horizontal alignment relative to the trigger.
 *   - **`offset`** (`number`, default: `8`):
 *     Spacing between the trigger and target.
 *   - **`shift`** (`number`, default: `10`):
 *     Horizontal adjustment for fine-tuning the position.
 *   - **`lockToPreferred`** (`boolean`, default: `false`):
 *     Prevents fallback positioning when the preferred position is not available.
 *
 * - **`containerRef`** (`React.RefObject<HTMLElement> | HTMLElement | null`, optional):
 *   For `absolute` positioning, defines the container relative to which the target is positioned.
 *   Defaults to the viewport.
 *
 * ### Returns:
 * - **`position`** (`{ top: number, left: number }`):
 *   The calculated top and left positions for the target element.
 *
 * ### Example:
 * ```tsx
 * const position = useDynamicPositionByTrigger(true, triggerRef, targetRef, {
 *   preferredPositionY: "bottom",
 *   preferredPositionX: "right",
 *   offset: 10,
 *   shift: 5,
 * });
 * ```
 */
export const useDynamicPositionByTrigger = (
    shouldUpdatePosition: boolean,
    triggerRef: RefObject<HTMLElement>,
    targetRef: RefObject<HTMLElement>,
    {
        preferredPositionY = 'bottom',
        preferredPositionX = 'right',
        offset = 8,
        shift = 10,
        lockToPreferred = false,
    }: PositionOptions = {},
    containerRef?: RefObject<HTMLElement> | HTMLElement | null,
) => {
    const [position, setPosition] = useState({top: 0, left: 0});

    const getContainerRect = () => {
        if (!containerRef) {
            return {
                top: 0,
                left: 0,
                bottom: window.innerHeight,
                right: window.innerWidth,
                height: window.innerHeight,
                width: window.innerWidth,
            };
        }

        const container = containerRef instanceof HTMLElement
            ? containerRef
            : containerRef?.current;

        if (container instanceof HTMLElement) {
            const {top, left, bottom, right} = container.getBoundingClientRect();
            return {
                top,
                left,
                bottom,
                right,
                height: bottom - top,
                width: right - left,
            };
        }

        return {
            top: 0,
            left: 0,
            bottom: window.innerHeight,
            right: window.innerWidth,
            height: window.innerHeight,
            width: window.innerWidth
        };
    };

    const calculatePosition = () => {
        if (!triggerRef.current || !targetRef.current) return;

        const triggerRect = triggerRef.current.getBoundingClientRect();
        const targetRect = targetRef.current.getBoundingClientRect();
        const containerRect = getContainerRect();

        let top = 0;
        let left = 0;

        // Obliczenia dla pozycji pionowej
        if (preferredPositionY === 'top') {
            const canDisplayTop = triggerRect.top - containerRect.top > targetRect.height + offset
            if (lockToPreferred) {
                console.log(canDisplayTop, triggerRect.top, containerRect.top, targetRect.height,containerRef)
                top = canDisplayTop
                    ? -offset
                    : containerRect.top - offset;
            } else {
                top = canDisplayTop
                    ? -offset
                    : triggerRect.bottom + offset - targetRect.height
            }
        } else {
            const canDisplayBottom = triggerRect.bottom - containerRect.top + targetRect.height + offset < containerRect.height;
            if (lockToPreferred) {
                top = canDisplayBottom
                    ? triggerRect.bottom - targetRect.height - offset
                    : triggerRect.bottom - triggerRect.height - targetRect.height
            } else {
                top = canDisplayBottom
                    ? triggerRect.bottom - targetRect.height - offset
                    : triggerRect.top - containerRect.top - offset
            }
        }

        // Obliczenia dla pozycji poziomej
        if (preferredPositionX === "left") {
            const canDisplayLeft =
                triggerRect.left - containerRect.left > targetRect.width + shift;
            left = canDisplayLeft
                ? triggerRect.left - containerRect.left - targetRect.width - shift
                : triggerRect.left - containerRect.left + shift;
        } else if (preferredPositionX === "right") {
            const canDisplayRight =
                triggerRect.right + targetRect.width + shift < containerRect.right;
            left = canDisplayRight
                ? triggerRect.right - containerRect.left + shift
                : triggerRect.right - containerRect.left - targetRect.width - shift;
        } else {
            left = 0;
        }

        setPosition({top, left});
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            calculatePosition();
        });

        if (targetRef.current) {
            resizeObserver.observe(targetRef.current);
        }

        return () => {
            if (targetRef.current) {
                resizeObserver.unobserve(targetRef.current);
            }
            resizeObserver.disconnect();
        };
    }, [shouldUpdatePosition, triggerRef, targetRef]);

    useEffect(() => {
        if (shouldUpdatePosition) {
            calculatePosition();

            const containerElement = containerRef instanceof HTMLElement
                ? containerRef
                : containerRef?.current || window;

            containerElement.addEventListener("scroll", calculatePosition);
            window.addEventListener("resize", calculatePosition);

            return () => {
                containerElement.removeEventListener("scroll", calculatePosition);
                window.removeEventListener("resize", calculatePosition);
            };
        }
    }, [shouldUpdatePosition, preferredPositionY, preferredPositionX, offset, shift, containerRef, targetRef]);


    return position;
};
