import { FormProvider, useForm } from "react-hook-form"
import NotificationsCard from "./notifications-fan-card"
import { AppFanFormValues, EmailFanFormValues, NotificationType } from "../views/notifications-view"
import { useEffect } from "react";
import { FanAppNotificationDto, FanEmailNotificationDto } from "../service/notification-dto";
import notificationsService from "../service/notifications-service";
import { ReactComponent as AppIcon } from "@assets/icons/mobile-push.svg";
import { ReactComponent as MessageIcon } from "@assets/icons/mail.svg"
import NotificationsFanAppCard from "./notifications-fan-card";
import NotificationsFanCard from "./notifications-fan-card";


const NotificationsFan = () => {


    const methodsApp = useForm<AppFanFormValues>({
        defaultValues: {
            isNotificationsApp: false,
            isTextMessagesApp: false,
            isVoiceMessagesApp: false,
            isVideoMessagesApp: false,
            isYCreditsApp: false
        },
        mode: "all"
      });
    
      const methodsEmail = useForm<EmailFanFormValues>({
        defaultValues: { 
            isNotificationsEmail: false,
            isTextMessagesEmail: false,
            isVoiceMessagesEmail: false,
            isVideoMessagesEmail: false,
            isYCreditsEmail: false
         },
      });


      const handleToggleApp = async (checked: boolean, name: any) => {
            const oldChecked = methodsApp.getValues(name);
              try {
                    methodsApp.setValue(name, checked);
                    const body : FanAppNotificationDto =  {
                        appNotification: methodsApp.getValues("isNotificationsApp"),
                        newTextMessage: methodsApp.getValues("isTextMessagesApp"),
                        newVoiceMessage: methodsApp.getValues("isVoiceMessagesApp"),
                        newVideoMessage: methodsApp.getValues("isVideoMessagesApp"),
                        smallAmountOfYCredits: methodsApp.getValues("isYCreditsApp")
                    }
                    
                  await notificationsService.updateFanAppNotifications(body).then(e=> {  
                  })
              } catch (error) {
                methodsApp.setValue(name, oldChecked)
                  console.error('Error updating switch:', error);
              }
          };

          const handleToggleEmail = async (checked: boolean, name: any) => {
            const oldChecked = methodsApp.getValues(name);
              try {
                    methodsEmail.setValue(name, checked);
                    const body : FanEmailNotificationDto =  {
                        emailNotification: methodsEmail.getValues("isNotificationsEmail"),
                        newTextMessage: methodsEmail.getValues("isTextMessagesEmail"),
                        newVoiceMessage: methodsEmail.getValues("isVoiceMessagesEmail"),
                        newVideoMessage: methodsEmail.getValues("isVideoMessagesEmail"),
                        smallAmountOfYCredits: methodsEmail.getValues("isYCreditsEmail")
                    }
                    
                  await notificationsService.updateFanEmailNotifications(body).then(e=> {  
                  })
              } catch (error) {
                methodsEmail.setValue(name, oldChecked)
                  console.error('Error updating switch:', error);
              }
          };

          useEffect(() => {
            async function fetchAppData() {
                await notificationsService.getFanAppNotifications().then(e=> {
                    methodsApp.reset(
                        {   
                            isNotificationsApp: e.appNotification,
                            isTextMessagesApp: e.newTextMessage,
                            isVoiceMessagesApp: e.newVoiceMessage,
                            isVideoMessagesApp: e.newVideoMessage,
                            isYCreditsApp: e.smallAmountOfYCredits
                        }
                    )
                })
                }

                async function fetchEmailData() {
                    await notificationsService.getFanEmailNotifications().then(e=> {
                        methodsEmail.reset(
                            {   
                                isNotificationsEmail: e.emailNotification,
                                isTextMessagesEmail: e.newTextMessage,
                                isVoiceMessagesEmail: e.newVoiceMessage,
                                isVideoMessagesEmail: e.newVideoMessage,
                                isYCreditsEmail: e.smallAmountOfYCredits
                            }
                        )
                    })
                    }

                fetchAppData();
                fetchEmailData();
            }, []);

    return (
        <>
            <FormProvider {...methodsApp}>
                <NotificationsFanCard type={"App"} icon={AppIcon} name="App" onToggle={handleToggleApp} methods={methodsApp}/>
            </FormProvider>
            <div className="mb-4 mt-4 border border-gray w-full md:mb-0 md:absolute md:border-0 md:m-0"></div>
            <FormProvider {...methodsEmail}>
                <NotificationsFanCard type={"Email"} icon={MessageIcon} name="Email" onToggle={handleToggleEmail} methods={methodsEmail}/>
            </FormProvider>
        </>
    )
}
export default NotificationsFan;
