import {useContext, useEffect} from "react";
import {StreamChatContext} from "../contexts/stream-chat-context";
import useAuth from "../../auth/hooks/use-auth";

const useInitStreamChat = () => {
    const {isAuthenticated} = useAuth();
    const {connectChat, disconnectChat, isConnected} = useContext(StreamChatContext)!;

    useEffect(() => {
        if (isAuthenticated){
            !isConnected && connectChat();
        }else {
            isConnected && disconnectChat();
        }

        return () => {
            isConnected && disconnectChat();
        };
    }, [isAuthenticated]);

    return null;
};

export default useInitStreamChat;
