import {Navigate, Outlet, RouteProps} from "react-router-dom";
import React, {useEffect} from "react";
import useAuth from "../../auth/hooks/use-auth";
import AppPublicProfilePage from "../../../pages/app-public-profile-page";
import useGuardOnRegistrationSteps from "../hooks/use-guard-registration-steps";

const GuardPublicProfileVerification = (props: RouteProps) => {
    const {currentUser} = useAuth();
    const hasCompletedVerification: boolean = useGuardOnRegistrationSteps(currentUser);
    const {isAuthenticated} = useAuth();

    useEffect(() => {
    }, [hasCompletedVerification]);

    if (!isAuthenticated) {
        return <AppPublicProfilePage/>;
    } 
    if (!hasCompletedVerification) {
        return <Navigate to="/app/verification" replace/>;
    }

    return <AppPublicProfilePage/>;
};

export default GuardPublicProfileVerification;
