import {TransactionSection} from "../models/interfaces/transaction-section.interface";
import {Transaction} from "../../core/models/interfaces/transaction.interface";

export const mapTransactionsToSections = (transactions: Transaction[]): TransactionSection[] => {
    const sortedTransactions = transactions.sort((a, b) => new Date(b.txnDate).getTime() - new Date(a.txnDate).getTime());

    const sections: { [key: string]: Transaction[] } = {};

    sortedTransactions.forEach((transaction) => {
        const transactionDate = new Date(transaction.txnDate);
        const now = new Date();

        const transactionDay = new Date(transactionDate.getFullYear(), transactionDate.getMonth(), transactionDate.getDate());
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

        const diffDays = (today.getTime() - transactionDay.getTime()) / (1000 * 3600 * 24);

        let label = '';
        if (diffDays === 0) {
            label = 'Today';
        } else if (diffDays === 1) {
            label = 'Yesterday';
        } else if (diffDays <= 7) {
            label = `${Math.floor(diffDays)} days ago`;
        } else {
            label = transactionDate.toLocaleDateString('en-GB');
        }

        if (!sections[label]) {
            sections[label] = [];
        }

        sections[label].push(transaction);
    });

    return Object.keys(sections).map((key) => ({
        label: key,
        items: sections[key]
    }));
};

