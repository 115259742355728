import React, {CSSProperties, MutableRefObject, useState} from 'react';
import {sanitizeUrl} from '@braintree/sanitize-url';
import clsx from 'clsx';
import {BaseImage, DefaultStreamChatGenerics, Modal, ModalGallery, useTranslationContext} from "stream-chat-react";
import type {Attachment} from 'stream-chat';
import ReactDOM from "react-dom";

export type GalleryProps<
    StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
> = {
    images: ((
        | {
        image_url?: string | undefined;
        thumb_url?: string | undefined;
    }
        | Attachment<StreamChatGenerics>
        ) & { previewUrl?: string; style?: CSSProperties })[];
    innerRefs?: MutableRefObject<(HTMLElement | null)[]>;
};

const UnMemoizedGallery = <
    StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
    props: GalleryProps<StreamChatGenerics>,
) => {
    const {images, innerRefs} = props;

    const [index, setIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);

    const {t} = useTranslationContext('Gallery');

    const imageFallbackTitle = t('User uploaded content');

    const countImagesDisplayedInPreview = 4;
    const lastImageIndexInPreview = countImagesDisplayedInPreview - 1;

    const toggleModal = (selectedIndex: number) => {
        if (modalOpen) {
            setModalOpen(false);
        } else {
            setIndex(selectedIndex);
            setModalOpen(true);
        }
    };

    const renderImages = images.slice(0, countImagesDisplayedInPreview).map((image, i) =>
        i === lastImageIndexInPreview && images.length > countImagesDisplayedInPreview ? (
            <button
                className='str-chat__gallery-placeholder'
                data-testid='gallery-image-last'
                key={`gallery-image-${i}`}
                onClick={() => toggleModal(i)}
                style={{
                    backgroundImage: `url(${
                        images[lastImageIndexInPreview].previewUrl ||
                        images[lastImageIndexInPreview].image_url ||
                        images[lastImageIndexInPreview].thumb_url
                    })`,
                    ...image.style,
                }}
                {...(innerRefs?.current && {ref: (r) => (innerRefs.current[i] = r)})}
            >
                <p>
                    {t<string>('{{ imageCount }} more', {
                        imageCount: images.length - countImagesDisplayedInPreview,
                    })}
                </p>
            </button>
        ) : (
            <button
                className='str-chat__gallery-image'
                data-testid='gallery-image'
                key={`gallery-image-${i}`}
                onClick={() => toggleModal(i)}
            >
                <BaseImage
                    alt={(image as Attachment<StreamChatGenerics>)?.fallback || imageFallbackTitle}
                    src={sanitizeUrl(image.previewUrl || image.image_url || image.thumb_url)}
                    style={image.style}
                    title={(image as Attachment<StreamChatGenerics>)?.fallback || imageFallbackTitle}
                    {...(innerRefs?.current && {ref: (r) => (innerRefs.current[i] = r)})}
                />
            </button>
        ),
    );

    const className = clsx('str-chat__gallery', {
        'str-chat__gallery--square': images.length > lastImageIndexInPreview,
        'str-chat__gallery-two-rows': images.length > 2,
    });

    return (
        <div className={className}>
            {renderImages}
            {modalOpen &&
                ReactDOM.createPortal(
                    <div className='fixed z-[100] bg-[rgba(0,0,0,0.2)] backdrop-blur-[3px] inset-0'>
                        <Modal
                            className="str-chat__gallery-modal z-[100] !w-full min-w-full"
                            onClose={() => setModalOpen((prev) => !prev)}
                            open={true}
                        >
                            <ModalGallery images={images} index={index}/>
                        </Modal>
                    </div>
                    ,
                    document.body,
                )}
        </div>
    );
};

export const Gallery = React.memo(UnMemoizedGallery) as typeof UnMemoizedGallery;
