import {useEffect, useMemo, useState} from 'react';
import {DeliveredProductMessage} from "../models/interfaces/delivered-product-message.interface";
import {ExtendedMessageByDeliveredProduct} from "../models/interfaces/extended-message-delivered-product.interface";
import {useDigitalProductApi} from "../../core/hooks/use-digital-product-api";

export const useDeliveredProductMessage = (message: ExtendedMessageByDeliveredProduct | null): DeliveredProductMessage => {
    const {getDigitalProductAliasName} = useDigitalProductApi();
    const [alias, setAlias] = useState<string>("");

    useEffect(() => {
        const fetchAlias = async () => {
            if (message?.dp_product_id) {
                try {
                    const aliasName = await getDigitalProductAliasName(message.dp_product_id);
                    setAlias(aliasName ?? "");
                } catch (error) {
                    console.error('Error fetching alias:', error);
                }
            }
        };

        fetchAlias();
    }, [message]);

    return useMemo(() => {
        return {
            price: message?.dp_price || '0',
            aliasName: alias,
            ownerUsername: message?.c_username || 'Unknown User',
            name: message?.dp_name || 'Unnamed Product',
            category: message?.dp_category || 'Category',
            productId: message?.dp_product_id || "",
        };
    }, [message, alias]);
};
