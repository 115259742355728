import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import useAuth from '../../auth/hooks/use-auth';
import {useBecomeCreatorApi} from "../../core/hooks/use-become-creator-api";
import Base64Helper from "../../../utils/base64-helper";
import StepOne from "../containers/step-one";
import StepTwo from "../containers/step-two";
import StepThree from "../containers/step-three";
import StepFour from "../containers/step-four";
import {useNavigate} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/20/solid";
import AppButton from "../../common/components/app-button";
import AddFirstProductsView from "../../digital-products/views/add-first-product-view";
import authService from '../../auth/services/auth.service';
import * as yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import {validateUsername} from "../../core/constants/validate-username";
import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";
import {classNames} from "../../../utils/class-names";
import ProfileImageService from "../../core/db/services/profile-image-service";
import ProfileBackgroundImageService from "../../core/db/services/profile-background-image-service";

export type FormValues = {
    firstName: string;
    lastName: string;
    userName: string;
    bio: string;
    socialHandle: string;
    isTextMessages: boolean;
    textMessagePrice: number;
    isVoiceMessages: boolean;
    voiceMessagePrice: number;
    isVideoMessages: boolean;
    videoMessagePrice: number;
    freeTextMessageBlock: number;
    freeVoiceMessagesMin: number;
    freeVideoMessagesMin: number;
    profileImage: string;
    backgroundProfileImage: string;
    occupation: string;
    completionDate: Date;
    projectDetails: string;
    facebookSocialHandle: string;
    instagramSocialHandle: string;
    linkedinSocialHandle: string;
    twitterSocialHandle: string;
    youtubeSocialHandle: string;
}

export const schemaForm = yup.object({
    firstName: yup
        .string()
        .required('First name is required')
        .min(2, 'First name must be at least 2 characters long')
        .max(50, 'First name cannot exceed 50 characters'),
    lastName: yup
        .string()
        .required('Last name is required')
        .min(2, 'Last name must be at least 2 characters long')
        .max(50, 'Last name cannot exceed 50 characters'),
    userName: validateUsername,
    bio: yup.string().optional().max(255, 'Bio cannot exceed 255 characters'),
    socialHandle: yup.string().optional().max(50, 'Social handle cannot exceed 50 characters'),
    isTextMessages: yup.boolean().optional(),
    textMessagePrice: yup
        .number()
        .optional()
        .min(0, 'Price cannot be negative'),
    isVoiceMessages: yup.boolean().optional(),
    voiceMessagePrice: yup
        .number()
        .optional()
        .min(0, 'Price cannot be negative'),
    isVideoMessages: yup.boolean().optional(),
    videoMessagePrice: yup
        .number()
        .optional()
        .min(0, 'Price cannot be negative'),
    freeTextMessageBlock: yup
        .number()
        .optional()
        .min(0, 'Free blocks cannot be negative'),
    freeVoiceMessagesMin: yup
        .number()
        .optional()
        .min(0, 'Free minutes cannot be negative'),
    freeVideoMessagesMin: yup
        .number()
        .optional()
        .min(0, 'Free minutes cannot be negative'),
    profileImage: yup.string().optional(),
    backgroundProfileImage: yup.string().optional(),
    occupation: yup.string().optional().max(100, 'Occupation cannot exceed 100 characters'),
    completionDate: yup.date().optional(),
    projectDetails: yup.string().optional().max(1000, 'Project details cannot exceed 1000 characters'),
    facebookSocialHandle: yup.string().max(255, 'Too long facebook link'),
    instagramSocialHandle: yup.string().max(255, 'Too long facebook link'),
    linkedinSocialHandle: yup.string().max(255, 'Too long facebook link'),
    twitterSocialHandle: yup.string().max(255, 'Too long facebook link'),
    youtubeSocialHandle: yup.string().max(255, 'Too long facebook link'),
}).test(
    'at-least-one-social-handle',
    'At least one social handle must be provided',
    function (value) {
        return Boolean(
            value.facebookSocialHandle ||
            value.instagramSocialHandle ||
            value.linkedinSocialHandle ||
            value.twitterSocialHandle ||
            value.youtubeSocialHandle
        );
    }
).required();

interface BecomeCreatorFormProps {
    placeForm?: "registration" | "home";
    onEnding?: UniversalClickHandler;
    onSkipAll?: UniversalClickHandler;
}

function BecomeCreatorForm(props: BecomeCreatorFormProps) {
    const [step, setStep] = useState(1);
    const [showProductForm, setShowProductForm] = useState(false);
    const [step1Ready, setStep1Ready] = useState(false);
    const {currentUser, updateAuthenticationField} = useAuth();
    const {sendRequestToBecomeCreator} = useBecomeCreatorApi();
    const navigate = useNavigate();
    const {
        placeForm = "home",
        onEnding,
        onSkipAll
    } = props;


    const methods = useForm<FormValues>({
        defaultValues: {
            profileImage: currentUser?.profileImage || '',
            backgroundProfileImage: currentUser?.backgroundProfileImage || '',
            firstName: currentUser?.firstname || '',
            lastName: currentUser?.lastname || '',
            userName: currentUser?.username || '',
            bio: currentUser?.bio || '',
            socialHandle: '',
            isTextMessages: true,
            textMessagePrice: 2,
            isVoiceMessages: false,
            voiceMessagePrice: 0,
            isVideoMessages: false,
            videoMessagePrice: 0,
            freeTextMessageBlock: 0,
            freeVoiceMessagesMin: 0,
            freeVideoMessagesMin: 0,
            occupation: '',
            completionDate: new Date(),
            projectDetails: '',
        },
        resolver: yupResolver(schemaForm) as any,
        mode: 'onChange',
        reValidateMode: 'onChange'
    });

    const handleChange = () => {
        setStep1Ready(checkStep1())
    }

    const checkIfNotExist = (value: string) => {
        return !(typeof value === 'undefined' || value === null || value.trim().length === 0)
    }
    const checkStep1 = (): boolean => {
        let values = methods.getValues();
        return !(checkSocials() && checkIfNotExist(values.firstName) && checkIfNotExist(values.lastName) && checkIfNotExist(values.userName))
    }

    const checkSocials = (): boolean => {
        let values = methods.getValues();
        return Boolean(
            values.facebookSocialHandle ||
            values.instagramSocialHandle ||
            values.linkedinSocialHandle ||
            values.twitterSocialHandle ||
            values.youtubeSocialHandle)
    }

    const saveRequest = async () => {
        await methods.handleSubmit(async (values) => {
            const requestBody = {
                firstName: values.firstName,
                lastName: values.lastName,
                socialHandle: values.socialHandle,
                bio: values.bio,
                chatPrice: values.isTextMessages ? (values.textMessagePrice ? values.textMessagePrice : 0) : 0,
                callPrice: values.isVoiceMessages ? (values.voiceMessagePrice ? values.voiceMessagePrice : 0) : 0,
                videoCallPrice: values.isVideoMessages ? (values.videoMessagePrice ? values.videoMessagePrice : 0) : 0,
                facebookSocialHandle: values.facebookSocialHandle,
                instagramSocialHandle: values.instagramSocialHandle,
                linkedinSocialHandle: values.linkedinSocialHandle,
                twitterSocialHandle: values.twitterSocialHandle,
                youtubeSocialHandle: values.youtubeSocialHandle,
            };
            const formData = new FormData();
            formData.append('image', Base64Helper.base64ToBlob(values.profileImage)!);
            formData.append('backgroundProfileImage', Base64Helper.base64ToBlob(values.backgroundProfileImage)!)
            formData.append('request', new Blob([JSON.stringify(requestBody)], {type: "application/json"}));
            await sendRequestToBecomeCreator(formData)
            if (values.profileImage) {
                await ProfileImageService.saveImage(Base64Helper.removeBase64Prefix(values.profileImage))
            }
            if (values.backgroundProfileImage) {
                await ProfileBackgroundImageService.saveImage(Base64Helper.removeBase64Prefix(values.backgroundProfileImage))
            }
            updateAuthenticationField("user", {
                ...currentUser!,
                pendingPromotion: true,
                firstname: values?.firstName ? values.firstName : currentUser?.firstname!,
                lastname: values?.lastName ? values.lastName : currentUser?.lastname!,
                username: values?.userName ? values.userName : currentUser?.username!,
                bio: values?.bio ? values.bio : currentUser?.bio!,
                backgroundProfileImage: Base64Helper.removeBase64Prefix(values.backgroundProfileImage),
                profileImage: Base64Helper.removeBase64Prefix(values.profileImage),
            })
            nextStep();
        })();
    };

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);

    const handleShowProductForm = () => {
        setShowProductForm(true);
    }

    useEffect(() => {
        var data = authService.authenticatedUser;
        const isSent = data?.pendingPromotion;
        if (isSent) {
            setStep(4);
        } else {
            handleChange()
        }
    }, []);

    return (
        <FormProvider {...methods}>
            <form onChange={handleChange} onSubmit={(e) => e.preventDefault()}
                  className={classNames(
                      "flex justify-center w-full h-full min-h-full flex-grow",
                      placeForm === "registration" && "p-4",
                      showProductForm && "hidden"
                  )}>
                <div className="w-full">
                    {step === 1 && (
                        <StepOne
                            onSkipAll={onSkipAll}
                            showPrevButton={placeForm === "home"}
                            nextStep={nextStep}
                            prevStep={() => navigate(-1)}
                            step1Ready={step1Ready}/>
                    )}
                    {step === 2 && (
                        <StepTwo
                            onSkipAll={onSkipAll}
                            prevStep={prevStep}
                            nextStep={nextStep}
                            messageDisabled={!methods.watch("isTextMessages")}
                            voiceDisabled={!methods.watch("isVoiceMessages")}
                            videoDisabled={!methods.watch("isVideoMessages")}
                            messagePrice={methods.watch("textMessagePrice")}
                            voicePrice={methods.watch("voiceMessagePrice")}
                            videoPrice={methods.watch("videoMessagePrice")}
                        />
                    )}
                    {step === 3 && !showProductForm && (
                        <StepThree
                            onSkipAll={onSkipAll}
                            onAddProduct={handleShowProductForm}
                            prevStep={prevStep}
                            saveRequest={saveRequest}/>
                    )}
                    {step === 4 && (
                        <StepFour btnText={placeForm === "home" ? "Go to home" : "Next"}
                                  username={methods.getValues('userName')} onEnding={onEnding}/>
                    )}
                </div>
            </form>
            {showProductForm && (
                <div className={classNames(
                    "space-y-3",
                    placeForm === "registration" && "p-4",
                )}>
                    <AppButton
                        type="button"
                        label=""
                        className="bg-gray-light text-special-gray w-10 h-10 !rounded-full"
                        onClick={() => setShowProductForm(false)}
                    >
                        <ChevronLeftIcon className="w-5 min-w-5"/>
                    </AppButton>
                    <AddFirstProductsView onSuccess={() => {
                        saveRequest()
                        setShowProductForm(false)
                    }}/>
                </div>
            )}
        </FormProvider>
    );
}

export default BecomeCreatorForm;
