import ApiService from "../../../axios/api.service";
import NotificationService from "../../common/services/notification.service";
import {UserProfile} from "../models/interfaces/user-profile.interface";
import {PublicUserProfile} from "../models/interfaces/public-user-profile.interface";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";

class ProfileApiService extends ApiService {
    // Pobieranie profilu użytkownika
    public async getUserProfile(): Promise<UserProfile> {
        return await this.get<UserProfile>(`/profile`, {
            skipDefault404Handling: true,
            errorMessage: "Error loading user profile"
        })
            .catch((error) => {
                throw error;
            });
    }

    // Pobieranie publicznego profilu
    public async getPublicCCUserProfile(username: string): Promise<PublicUserProfile> {
        return await this.get<PublicUserProfile>(`/profile/${username}`, {
            errorMessage: "Error loading public profile"
        })
            .catch((error) => {
                throw error;
            });
    }

    // Sprawdzenie typu użytkownika - CC lub FAN
    public async getUserType(username: string): Promise<UserAuthority> {
        return await this.get<UserAuthority>(`/profile/${username}`, {
            skipDefaultErrorHandling: true,
            skipDefault404Handling: true,
        })
            .then(() => UserAuthority.CONTENT_CREATOR)
            .catch((error) => {
                return UserAuthority.FAN;
            });
    }

    // Aktualizacja profilu użytkownika
    public async updateProfile(data: FormData): Promise<void> {
        return await this.put<void>(`/profile/update`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            errorMessage: "Error updating profile",
            successMessage: "Profile updated successfully"
        })
            .catch((error) => {
                throw error;
            });
    }

    // Śledzenie użytkownika
    public async followCreator(creatorId: string,creatorUsername: string): Promise<void> {
        return await this.post<void>(`/profile/follow/${creatorId}`, {}, {
            successMessage: `Started following ${creatorUsername}`,
            errorMessage: "Error following creator"
        })
            .catch((error) => {
                throw error;
            });
    }

    // Odśledzenie użytkownika
    public async unfollowCreator(username: string): Promise<void> {
        return await this.post<void>(`/profile/unfollow/${username}`, {}, {
            successMessage: `Stopped following ${username}`,
            errorMessage: "Error unfollowing creator",
        })
            .catch((error) => {
                throw error;
            });
    }

    // Sprawdzenie, czy użytkownik jest śledzony
    public async isCreatorFollowed(creatorId: string): Promise<boolean> {
        return await this.get<boolean>(`/profile/followed/${creatorId}`, {
            skipDefault404Handling: true,
            errorMessage:"Error checking follow status",
        })
            .catch((error) => {
                throw error;
            });
    }

    public async isContentCreatorOnline(username: string): Promise<boolean> {
        return await this.get<boolean>(`/profile/status/${username}`, {
            skipDefault404Handling: true,
            errorMessage:"Error checking online status",
        })
            .catch((error) => {
                throw error;
            });
    }

    public async visitProfile(username: string): Promise<void> {
        return await this.put<void>(`/profile/visit-counter/${username}`, {}, {
            skipDefault404Handling: true,
            errorMessage:"Error visiting profile",
        })
            .catch((error) => {
                throw error;
            });
    }
}

export default new ProfileApiService()
