import WrapperCard from "../../../common/components/wrapper-card";
import {classNames} from "../../../../utils/class-names";

const SkeletonPreviewDigitalProductToBuy = () => {
    return (
        <WrapperCard className={classNames(
            "w-full flex flex-col gap-3 !p-3 !py-[11px] animate-pulse"
        )}>
            <div className='flex justify-between gap-4'>
                <div className='flex flex-col gap-1 truncate'>
                    <div className="h-3 w-20 bg-gray-light rounded-md"></div>
                    <div className="h-4 w-36 bg-gray-light rounded-md mt-1"></div>
                    <div className="h-3 w-full bg-gray-light rounded-md mt-2"></div>
                    <div className="h-3 w-3/4 bg-gray-light rounded-md mt-1"></div>
                </div>
                <div
                    className='min-w-[72px] w-[72px] h-[72px] border border-special-gray rounded-2xl bg-gray-light flex justify-center items-center'>
                </div>
            </div>
            <div className='flex justify-between items-center'>
                <div className='flex gap-1 h-5 w-10 bg-gray-light rounded-md'></div>
                <div className='flex gap-3'>
                    <div className='h-8 w-20 bg-gray-light rounded-md'></div>
                    <div className='h-8 w-20 bg-gray-light rounded-md'></div>
                </div>
            </div>
        </WrapperCard>
    );
}

export default SkeletonPreviewDigitalProductToBuy
