import {useEffect, useRef} from "react";

/**
 * `useEffectOnce` Hook
 *
 * A custom React hook that ensures a given effect runs only once, even in React's `StrictMode`,
 * where effects are often run twice during development.
 *
 * ### Purpose:
 * Prevents double execution of effects caused by `StrictMode`, ensuring the effect runs only once
 * during the component's lifecycle.
 *
 * ### Parameters:
 * - `effect` (function): A function to execute once after the component mounts.
 *   - It may optionally return a cleanup function that will be called on component unmount.
 *
 * ### Example:
 * ```tsx
 * import React from "react";
 * import useEffectOnce from "./useEffectOnce";
 *
 * const MyComponent = () => {
 *     useEffectOnce(() => {
 *         console.log("Effect executed once!");
 *
 *         // Optional cleanup
 *         return () => console.log("Cleanup on unmount");
 *     });
 *
 *     return <div>Check the console!</div>;
 * };
 *
 * export default MyComponent;
 * ```
 *
 * ### Notes:
 * - This hook is particularly useful when you want initialization logic to run only once,
 *   regardless of `StrictMode` behavior.
 * - Unlike `useEffect`, it does not accept a dependency array.
 * - Cleanup is still executed on component unmount.
 */
const useEffectOnce = (effect: () => void | (() => void)) => {
    const hasRun = useRef(false);

    useEffect(() => {
        if (hasRun.current) return;
        hasRun.current = true;

        return effect();
    }, []);
};

export default useEffectOnce;
