import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Define meta tag types
interface MetaTags {
    title: string;
    description: string;
  }
  
  // Define meta data for routes
  const metaTags: Record<string, MetaTags> = {
    '/': {
      title: 'Yurs',
      description: 'Unlock the full potential of your influence with yurs – the ultimate platform for creators to monetize their passion. Your content, your rules, your revenue. Get started today!',
    },
    '/auth/login': {
      title: 'Sign in',
      description: 'Join yurs and monetize your influence.',
    },
  };
  
  const MetaUpdater = () => {
    const location = useLocation();
  
    useEffect(() => {
      const currentMeta = metaTags[location.pathname] || {
        title: 'Yurs',
        description: 'Unlock the full potential of your influence with yurs – the ultimate platform for creators to monetize their passion. Your content, your rules, your revenue. Get started today!',
      }
  
      const metaDescription = document.querySelector('meta[name="description"]');

      if (metaDescription) {
        metaDescription.setAttribute('content', currentMeta.description);
      } else {
        const newMetaDescription = document.createElement('meta');
        newMetaDescription.name = 'description';
        newMetaDescription.content = currentMeta.description;
        document.head.appendChild(newMetaDescription);
      }
    }, [location]);
  
    return null; // This component doesn't render anything
  };
export default MetaUpdater;
