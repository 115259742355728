import {AuthenticatedUser} from "../../auth/models/interfaces/authenticated-user.interface";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";

const useGuardOnBoarding = (currentUser: AuthenticatedUser | undefined): boolean => {
    return (
        !!currentUser?.ccOnBoarding &&
        currentUser.authority === UserAuthority.CONTENT_CREATOR
    ) || (
        !!currentUser?.fanOnBoarding &&
        currentUser.authority === UserAuthority.FAN
    )
};

export default useGuardOnBoarding;
