import React from 'react';
import useAuth from "../hooks/use-auth";
import {Navigate, Outlet, RouteProps, useParams} from "react-router-dom"
import {useRedirect} from "../hooks/use-redirect";

const GuardAuthRoutes = (props: RouteProps) => {
    const {isAuthenticated} = useAuth();
    const {intendedRoute} = useRedirect();
    const {token} = useParams();

    //Token is from login by Admin
    if (isAuthenticated && !token) {
        const redirectTo = intendedRoute || '/app';
        return <Navigate to={redirectTo}/>;
    }

    return <Outlet/>;
};
export default GuardAuthRoutes;
