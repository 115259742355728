import * as yup from 'yup';
import {validateUsername} from "../../core/constants/validate-username";

export const schemaProfileForm = yup.object({
    firstname: yup.string()
    .max(50, "First name cannot exceed 50 characters")
    .optional(),
    lastname: yup.string()
    .max(50, "Last name cannot exceed 50 characters")
    .optional(),
    username: validateUsername,
    bio: yup.string().optional(),
    profileImage: yup.string().optional(),
    backgroundProfileImage: yup.string().optional()
}).required();
