import Tooltip from "../../../common/components/tooltip";
import React from "react";
import {ReactComponent as ShieldIcon} from "@assets/icons/shield.svg";
import {ReactComponent as CardIcon} from "@assets/icons/card.svg";
import {classNames} from "../../../../utils/class-names";

interface PaymentSecurityInfoProps {
    applyFullWidth?: boolean,
}

const PaymentSecurityInfo = (props: PaymentSecurityInfoProps) => {
    const {applyFullWidth = false} = props;

    return (
        <div className='flex flex-col sm:flex-row gap-x-5 gap-y-3 flex-wrap text-sm text-special-gray'>
            <span className={classNames(
                'flex items-start justify-between gap-1',
                applyFullWidth && "w-full"
            )}>
                <div className='flex gap-2'>
                    <CardIcon className='w-[1.125rem] min-w-[1.125rem] h-fit relative top-[2px]'/>
                    <div className='flex flex-col sm:flex-row sm:flex-wrap gap-x-1'>
                        <span className='whitespace-nowrap'>We use Stripe: </span>
                        <span className='whitespace-nowrap'>worldwide payment system</span>
                    </div>
                </div>
                {/* <Tooltip classNameIcon='relative top-[2px]'/> */}
            </span>
            <span className={classNames(
                'flex items-start justify-between gap-1',
                applyFullWidth && "w-full"
            )}>
                <div className='flex gap-2'>
                    <ShieldIcon className='w-fit h-[1.125rem]'/>
                    <span>Secure payment guarantee</span>
                </div>
                {/* <Tooltip classNameIcon='relative top-[2px]'/> */}
            </span>
        </div>
    )
}

export default PaymentSecurityInfo
