import {ChannelFilters, ChannelOptions, ChannelSort, GetUnreadCountAPIResponse, StreamChat} from 'stream-chat';
import {AuthenticatedUser} from '../../auth/models/interfaces/authenticated-user.interface';
import {ChannelSearchFunctionParams} from "stream-chat-react";
import {SetStateAction} from "react";
import {customSearchFunction} from "../helpers/custom-search-function";
import {GetChannelsParameters} from "../models/interfaces/get-channels-parameters.interface";

class StreamChatService {
    private static instance: StreamChatService;
    private readonly _client: StreamChat;
    private _clientReady: boolean = false;
    private clientReadyCallbacks: (() => void)[] = [];
    private _isConnected: boolean = false;

    private constructor() {
        this._client = StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY!);
    }

    public static getInstance(): StreamChatService {
        if (!StreamChatService.instance) {
            StreamChatService.instance = new StreamChatService();
        }
        return StreamChatService.instance;
    }

    public get isConnected(): boolean {
        return this._isConnected;
    }

    private set isConnected(value: boolean) {
        this._isConnected = value;
    }

    public connectUser = async (user: AuthenticatedUser) => {
        if (this._isConnected) {
            console.log("User is already connected.");
            return;
        }

        const getStreamUser = {
            id: user.id,
            name: user.username || `${user.firstname} ${user.lastname}`,
        };

        try {
            if (!user.chatToken) {
                throw new Error("User token is not set.");
            }
            await this._client.connectUser(getStreamUser, user.chatToken);
            this.setClientReady(true);
            this.isConnected = true;
            return this._client;
        } catch (error) {
            console.error("Failed to connect user:", error);
            this.setClientReady(false);
            this.isConnected = false;
            throw error;
        }
    };

    public disconnectUser = async () => {
        if (!this._isConnected) {
            console.log("User is not connected.");
            return;
        }

        if (this._client.userID) {
            try {
                await this._client.disconnectUser();
                this.setClientReady(false);
                this.isConnected = false;
            } catch (error) {
                console.error("Failed to disconnect user:", error);
            }
        }
    };

    public onClientReady(callback: () => void) {
        if (this._clientReady) {
            callback();
        } else {
            this.clientReadyCallbacks.push(callback);
        }
    }

    public getChannels = async (config: GetChannelsParameters) => {
        if (!this._clientReady) {
            await new Promise<void>((resolve) => this.onClientReady(resolve));
        }
        return await this.client.queryChannels(config.filters, config.sort, config.options);
    };

    public async getUnreadChannels(userId: string): Promise<GetUnreadCountAPIResponse> {
        try {
            return await this._client.getUnreadCount(userId);
        } catch (error) {
            console.error("Error fetching unread channels:", error);
            throw error;
        }
    }

    public searchNonGroupChannels = async (
        props: ChannelSearchFunctionParams,
        event: { target: { value: SetStateAction<string> } }
    ) => {
        await customSearchFunction(props, event, this.client);
    }

    public get client() {
        return this._client;
    }

    private setClientReady(isReady: boolean) {
        this._clientReady = isReady;
        if (isReady) {
            this.clientReadyCallbacks.forEach(callback => callback());
            this.clientReadyCallbacks = [];
        }
    }
}

export default StreamChatService.getInstance();
