import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {PwaInstallProvider} from "./features/common/contexts/pwa-install-context";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <PwaInstallProvider>
          <App />
      </PwaInstallProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register({
    onUpdate: (registration) => {
        console.log('New updateEntity available');
        if (window.confirm('A new version of the application is available. Would you like to refresh?')) {
            if (registration.waiting) {
                registration.waiting.postMessage({type: 'SKIP_WAITING'});
            }
        }
    },
    enableAutoCheck: true,
    intervalCheckingUpdates: 300000
});

// navigator.serviceWorker.getRegistration().then((registration) => {
//     if (registration) {
//         console.log('Checking for Service Worker updates...');
//         registration.update().then(() => {
//             console.log('Checked for updates.');
//         });
//     }
// });

navigator.serviceWorker.addEventListener('controllerchange', () => {
    console.log('Service Worker has been updated. Reloading the page...');
    window.location.reload();
});
