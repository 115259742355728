import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import NumberFormField from "../../common/components/forms/number-form-field";
import React, { BaseSyntheticEvent, useCallback, useContext, useEffect, useRef } from "react";
import Tooltip from "../../common/components/tooltip";
import TitleContactType from "./title-section";
import { ReactComponent as ChatIcon } from "@assets/icons/chat.svg";
import { ReactComponent as AudioIcon } from "@assets/icons/audio.svg";
import { ReactComponent as VideoIcon } from "@assets/icons/video.svg";
import CreditsConversionService from "../../purchases/services/credits-conversion.service";
import { useDigitalProductApi } from "../../core/hooks/use-digital-product-api";
import { SessionCreatorToolsContext } from "../contexts/session-creator-tools-context";
import { ContactType } from "../../core/models/enums/communications-type.enum";

const schema = yup.object({
    textMessagePrice: yup.string().required('Price is required').optional().nullable(),
    voiceMessagePrice: yup.string().required('Price is required').optional().nullable(),
    videoMessagePrice: yup.string().required('Price is required').optional().nullable(),
    textMessageEnabled: yup.boolean(),
    voiceMessageEnabled: yup.boolean(),
    videoMessageEnabled: yup.boolean(),
    availabilityVoiceMessage: yup.string().required('Availability is required'),
    availabilityVideoMessage: yup.string().required('Availability is required')
}).required();

type FormValues = yup.InferType<typeof schema>;

const ContactPricesForm = () => {
    const { changeContactPrice } = useDigitalProductApi();
    const { contactPrices, setContactPrices, initLoading } = useContext(SessionCreatorToolsContext)!;
    const methods = useForm({
        defaultValues: {
            videoMessageEnabled: true,
            voiceMessageEnabled: true,
            textMessageEnabled: true,
            videoMessagePrice: null,
            voiceMessagePrice: null,
            textMessagePrice: null
        },
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });
    const values = methods.watch();

    const lastValidValues = useRef<{ [key in keyof FormValues]?: string | null }>({
        videoMessagePrice: contactPrices?.videoCallPrice?.toString() ?? null,
        voiceMessagePrice: contactPrices?.callPrice?.toString() ?? null,
        textMessagePrice: contactPrices?.chatPrice?.toString() ?? null,
    });

    const handleChangePricing = async (typeContact: ContactType, controlName: keyof FormValues, event: BaseSyntheticEvent) => {
        let value = event.target.value ?? "0";

        try {
            methods.setValue(controlName, value);
            await changeContactPrice(parseFloat(value), typeContact);
            lastValidValues.current[controlName] = value;
            setContactPrices({
                chatPrice: parseFloat(lastValidValues.current['textMessagePrice']!),
                callPrice: parseFloat(lastValidValues.current['voiceMessagePrice']!),
                videoCallPrice: parseFloat(lastValidValues.current['videoMessagePrice']!)
            });

        } catch (error) {
            methods.setValue(controlName, lastValidValues.current[controlName] || "0");
        }
    };

    const calculateEarningsPerBlock = useCallback((controlName: keyof FormValues) => {
        const valueYCredits = parseFloat(methods.getValues(controlName) + '') || 0;
        return CreditsConversionService.convertYCreditsToDollarsWithCurrency(valueYCredits)
    }, [values]);

    useEffect(() => {
        methods.reset({
            ...methods.getValues(),
            videoMessagePrice: contactPrices?.videoCallPrice?.toString() ?? null,
            voiceMessagePrice: contactPrices?.callPrice?.toString() ?? null,
            textMessagePrice: contactPrices?.chatPrice?.toString() ?? null
        });

        lastValidValues.current = {
            videoMessagePrice: contactPrices?.videoCallPrice?.toString() ?? null,
            voiceMessagePrice: contactPrices?.callPrice?.toString() ?? null,
            textMessagePrice: contactPrices?.chatPrice?.toString() ?? null,
        };
    }, [contactPrices]);

    return (
        <FormProvider {...methods}>
            <form className="flex flex-col gap-y-3 mx-auto">
                <div>
                    <div className="flex items-center justify-between">
                        <TitleContactType Icon={ChatIcon} title='Text messages'/>
                    </div>
                    {values.textMessageEnabled && (
                        <div className='mt-4'>
                            <NumberFormField
                                min={0}
                                label="One message block price"
                                placeholder="Enter price"
                                name='textMessagePrice'
                                isYcoin={true}
                                isLoading={initLoading}
                                onBlur={(event) => handleChangePricing(ContactType.CHAT, "textMessagePrice", event)}
                            />
                            <span className="text-special-gray text-xs leading-[0.9rem]">
                                <Tooltip classNameIcon='relative top-[2px]'/>
                                {`You'll earn ${calculateEarningsPerBlock('textMessagePrice')} for every message block`}
                            </span>
                            {/*<div className='w-full my-4 border-b border-special-gray'></div>*/}
                        </div>
                    )}
                </div>

                {/*<div>*/}
                {/*    <div className="flex items-center justify-between">*/}
                {/*        <TitleContactType Icon={AudioIcon} title='Voice messages'/>*/}
                {/*    </div>*/}
                {/*    {values.voiceMessageEnabled && (*/}
                {/*        <div className='mt-4'>*/}
                {/*            <NumberFormField*/}
                {/*                min={0}*/}
                {/*                label="One minute price"*/}
                {/*                placeholder="Enter price"*/}
                {/*                name='voiceMessagePrice'*/}
                {/*                isYcoin={true}*/}
                {/*                isLoading={initLoading}*/}
                {/*                onBlur={(event) => handleChangePricing(ContactType.CALL, "voiceMessagePrice", event)}*/}
                {/*            />*/}
                {/*            <span className="text-special-gray text-xs leading-[0.9rem]">*/}
                {/*                <Tooltip classNameIcon='relative top-[2px]'/>*/}
                {/*                {`You'll earn ${calculateEarningsPerBlock('voiceMessagePrice')} for every minute`}*/}
                {/*            </span>*/}
                {/*            <div className='w-full mb-4 my-6 border-b border-special-gray'></div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</div>*/}

                {/*<div>*/}
                {/*    <div className="flex items-center justify-between">*/}
                {/*        <TitleContactType Icon={VideoIcon} title='Video messages'/>*/}
                {/*    </div>*/}
                {/*    {values.videoMessageEnabled && (*/}
                {/*        <div className='mt-4'>*/}
                {/*            <NumberFormField*/}
                {/*                min={0}*/}
                {/*                label="One minute price"*/}
                {/*                placeholder="Enter price"*/}
                {/*                name='videoMessagePrice'*/}
                {/*                isYcoin={true}*/}
                {/*                isLoading={initLoading}*/}
                {/*                onBlur={(event) => handleChangePricing(ContactType.VIDEO_CALL, "videoMessagePrice", event)}*/}
                {/*            />*/}
                {/*            <span className="text-special-gray text-xs leading-[0.9rem]">*/}
                {/*                <Tooltip classNameIcon='relative top-[2px]'/>*/}
                {/*                {`You'll earn ${calculateEarningsPerBlock('videoMessagePrice')} for every minute`}*/}
                {/*            </span>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</div>*/}
            </form>
        </FormProvider>
    );
};

export default ContactPricesForm;
