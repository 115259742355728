import MessageWrapper from "./containers/message-wrapper";
import {Attachment, useMessageContext} from "stream-chat-react";
import MessageText from "./ui/message-text";
import {MessageTimestamp} from "./ui/message-time-stamp";
import React from "react";
import MessageErrorText from "./ui/message-error-text";
import MessageErrorIcon from "./ui/message-error-icon";

const CustomMessageError = () => {
    const {message, isMyMessage} = useMessageContext();

    return (
        <div className='flex max-w-full'>
            <MessageWrapper>
                <div className='str-chat__message-bubble'>
                    {message.attachments?.length && !message.quoted_message ? (
                        <Attachment
                            attachments={message.attachments}/>
                    ) : null}
                    <div>
                        <MessageErrorText/>
                        <MessageText message={message} byOwner={isMyMessage()}/>
                        <MessageErrorIcon className="absolute -left-[8px] top-[16px]"/>
                    </div>
                </div>

                <div className="flex justify-end gap-2 items-center mt-1">
                    <MessageTimestamp timestamp={message.created_at!}/>
                </div>
            </MessageWrapper>
        </div>
    );
}

export default CustomMessageError
