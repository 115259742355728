import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {Channel, GetUnreadCountAPIResponse, StreamChat} from "stream-chat";
import useAuth from "../../auth/hooks/use-auth";
import StreamChatService from "../services/stream-chat.service";
import {UnreadChannelsContext} from "./unread-channels-context";
import {GetChannelsParameters} from "../models/interfaces/get-channels-parameters.interface";
import {configLoadChannels} from "../config/config-load-channels";
import {debounce} from "../../../utils/debounce";

interface StreamChatContextValue {
    client: StreamChat | undefined;
    unreadChannels: GetUnreadCountAPIResponse | undefined;
    channels: Channel[];
    connectChat: () => void;
    disconnectChat: () => void;
    fetchChannels: () => Promise<void>;
    isConnected: boolean;
    setActiveChannelID: (channelID: string | null) => void;
    updateUnreadChannels: (callback?: () => void) => Promise<void>;
}

export const StreamChatContext = createContext<StreamChatContextValue | null>(null);

export const StreamChatProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const {currentUser, isAuthenticated} = useAuth();
    const [channels, setChannels] = useState<Channel[]>([]);
    const [activeChannelID, setActiveChannelID] = useState<string | null>(null);
    const {setUnreadChannels,unreadChannels} = useContext(UnreadChannelsContext)!;
    const [isConnected, setIsConnected] = useState(StreamChatService.isConnected);

    const connectChat = async () => {
        if (!isConnected && currentUser) {
            try {
                console.log("Connect client")

                await StreamChatService.connectUser(currentUser);
                await updateUnreadChannels();
                listenClientEvents(StreamChatService.client);
                setIsConnected(true);
            } catch (error: any) {
                console.error("Failed to connect user:", error);
            }
        }
    };

    const disconnectChat = async () => {
        if (isConnected && StreamChatService.client) {
            try {
                await StreamChatService.disconnectUser();
                setIsConnected(false);
            } catch (error) {
                console.error("Failed to disconnect user:", error);
            }
        }
    };

    const fetchChannels = async () => {
        if (StreamChatService.client && currentUser) {
            try {
                const config: GetChannelsParameters = configLoadChannels(currentUser.id)
                const channels = await StreamChatService.getChannels(config);
                setChannels(channels);
            } catch (error) {
                console.error("Error fetching channels:", error);
            }
        }
    };

    const listenClientEvents = (streamClient: StreamChat) => {
        const debouncedUpdateUnreadChannels = debounce(updateUnreadChannels, 300);

        streamClient?.on(async (event) => {
            switch (event.type) {
                case "message.new":
                case "notification.message_new":
                case "notification.mark_read":
                    event.channel?.id !== activeChannelID && await debouncedUpdateUnreadChannels();
                    break;
            }
        });
    };

    const updateUnreadChannels = useCallback(async () => {
        if (currentUser?.id) {
            try {
                const unreadChannels: GetUnreadCountAPIResponse = await StreamChatService.getUnreadChannels(currentUser.id);

                const filteredChannels = unreadChannels.channels.filter(
                    (c) => c['channel_id'] !== activeChannelID
                );

                setUnreadChannels({...unreadChannels, channels: filteredChannels});

            } catch (error) {
                console.error("Error updating unread channels:", error);
            }
        }
    }, [currentUser,activeChannelID]);

    return (
        <StreamChatContext.Provider value={{
            client: StreamChatService.client,
            unreadChannels,
            channels,
            isConnected,
            connectChat,
            disconnectChat,
            fetchChannels,
            setActiveChannelID,
            updateUnreadChannels,
        }}>
            {children}
        </StreamChatContext.Provider>
    );
};
