import {useEffect, useMemo, useState} from "react";
import {useChatContext} from "stream-chat-react";
import {Channel, FormatMessageResponse} from "stream-chat";

export const useMessageState = (message: FormatMessageResponse | null, channel: Channel | null) => {
    const {client} = useChatContext();
    const [refreshKey, setRefreshKey] = useState(0);

    const isReadByPartner = useMemo(() => {
        if (!message || !channel) return false;

        const partner = Object.values(channel.state.members).find(
            (member) => member.user?.id !== client.userID
        );

        if (!partner) return false;

        const partnerReadState = channel.state.read[partner.user!.id];
        return (
            partnerReadState?.last_read &&
            (new Date(partnerReadState.last_read) >= new Date(message.created_at!) ||
                message.id === partnerReadState.last_read_message_id)
        );
    }, [message, refreshKey]);

    const isReadByAll = useMemo(() => {
        if (!message || !channel) return false;

        return Object.values(channel.state.read)
            .filter((readState) => readState.user.id !== message.user?.id)
            .every((readState) => {
                return (
                    readState.last_read &&
                    new Date(readState.last_read) >= new Date(message.created_at!)
                );
            });
    }, [message, refreshKey]);

    useEffect(() => {
        if (!channel) return;
        const handleReadEvent = async () => setRefreshKey(prevState => prevState + 1)
        channel.on("message.read", handleReadEvent);
        return () => channel.off("message.read", handleReadEvent);
    }, [channel]);

    return {
        isReadByPartner,
        isReadByAll,
    };
};
