import {ReactionOptions} from "stream-chat-react";
import React from "react";

const customReactionOptions: ReactionOptions = [
    {type: 'thumbs_up', Component: () => <>👍</>, name: 'Thumbs Up'},
    {type: 'ok_hand', Component: () => <>👌</>, name: 'OK Hand'},
    {type: 'heart', Component: () => <>❤️</>, name: 'Heart'},
    {type: 'wink', Component: () => <>😉</>, name: 'Wink'},
    {type: 'laugh', Component: () => <>😂</>, name: 'Laugh'},
    {type: 'thumbs_down', Component: () => <>👎</>, name: 'Thumbs Down'},
    {type: 'eyes', Component: () => <>👀</>, name: 'Eyes'},
    {type: 'sunglasses', Component: () => <>😎</>, name: 'Cool'},
    {type: 'fire', Component: () => <>🔥</>, name: 'Fire'},
    {type: 'clap', Component: () => <>👏</>, name: 'Clap'},
    {type: 'rocket', Component: () => <>🚀</>, name: 'Rocket'},
    {type: 'star', Component: () => <>⭐</>, name: 'Star'},
];

export default customReactionOptions
