import {useCallback} from 'react';
import {useModal} from "./use-modal";
import {ModalIds} from "../models/enums/modal-ids.enum";

const useModalWithData = <T, >(modalId: ModalIds) => {
    const {openModal, closeModal, getModalData, setCloseCallback, isOpenModal, registerModals} = useModal();

    const showModal = useCallback((data: T) => {
        openModal(modalId, data);
    }, [openModal, modalId]);

    const hideModal = useCallback(() => {
        closeModal(modalId);
    }, [closeModal, modalId]);

    const isOpenModalHandler = useCallback(() => {
        return isOpenModal(modalId);
    }, [isOpenModal, modalId])

    if (!registerModals) {
        console.warn("ModalContext is not initialized.");
        return {
            data: null,
            hideModal: () => {},
            showModal: () => {},
            isOpenModal: () => {},
            setCloseCallback: () => {},
        };
    }

    const data = getModalData(modalId) as T | null;

    return {data, showModal, hideModal, setCloseCallback, isOpenModal: isOpenModalHandler};
};

export default useModalWithData;
