import {ReactComponent as TrashIcon} from "@assets/icons/trash.svg";
import React, {BaseSyntheticEvent} from "react";

interface DeleteActionProps {
    onClick: (e: BaseSyntheticEvent) => void;
}

const DeleteAction = ({onClick}: DeleteActionProps) => {

    return (
        <div onClick={onClick}
             className='flex items-center gap-4 cursor-pointer rounded-2xl p-3 hover:bg-gray-light focus:bg-gray-light'>
            <TrashIcon className="w-4 max-w-4 h-fit"/>
            <span className="font-semibold leading-[1.125rem] text-[0.9375rem] text-black">Delete</span>
        </div>
    )
}

export default DeleteAction
